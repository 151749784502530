@import "UIKit/_colorVars.scss";
.titleYears{
    display: flex;
    align-items: baseline;
    width: 200px;
    justify-content: space-between;
}
.yearsOpenedList{
   width: 94px;
}
.headerWrapper {
  display: flex;
  justify-content: space-between;
}

.container {
  padding: 24px 20px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .dashboardWrapper {
    display: flex;
    flex-wrap: nowrap;
  }

  .title {
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 20px;
  }

  .filterBar {
    margin-bottom: 20px;
  }

  .wrapper {
    background: $white;
    box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
    0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11),
    0px 0.5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 24px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .wrap {
      display: flex;
      overflow-x: auto;

      .divider {
        width: 1px;
        border: 1px dashed #c8c8c8;
        margin: 20px 16px;
      }
    }
  }
}
