.selectedTaskDetails {
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  h3 {
    font-weight: 500;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 24px;
    white-space: nowrap;
  }
}