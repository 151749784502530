$grey: #c8c8c8;
$textLigtht: rgba(0, 0, 0, 0.7);
$textDark: rgba(0, 0, 0, 0.9);
$lightBlue: #EAF4FF;
$biege: #FCFAF8;
$blue: #2F78CD;
$darkBlue: #005A9E;
$title: #333D66;
$white: #ffffff;

$button: #005A9E;
$buttonHover: #116CB0;
$buttonActive: #004F8B;
$buttonLight: #ffffff;
$buttonLightHover:  #F7FAFF;
$buttonLightActive: #EEF6FF;

$input: #F5F8FA;
$inputHover: #F5F8FA;
$inputFocus: #F8FAFC;

$modalShadow:  1px 4px 12px rgba(0, 0, 0, 0.15);
$shadow:  7px 10px 24px rgba(0, 0, 0, 0.07), -12px -12px 24px rgba(0, 0, 0, 0.06);