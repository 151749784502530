.wrap {
  width: 100%;
  overflow-y: auto;
  padding: 24px 20px 0 20px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
}
.inlineWrap{
     transform: rotateX(180deg);
     display: flex;
     flex-direction: column;
     padding-bottom: 4px;
     max-height: calc(100vh - 150px);
     overflow-y: scroll;
}
.title {
  font-weight: 500;
  font-size: 21px;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 12px;
}

.information{
  margin-bottom: 20px;
}