@import "../../landingUi/landingMain.scss";

.cookiePage {
  margin-top: 64px;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 1339px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.landingContainer {
  max-width: 760px;
}

.title {
  color: $textDark;
  font-weight: bold;
  font-size: 36px;
  line-height: 110%;
  margin-bottom: 40px;
  @media (max-width: 1767px) {
    font-size: 24px;
    margin-bottom: 50px;
  }
}

.text {
  font-size: 18px;
  line-height: 150%;
  color: $textDark;
  margin-bottom: 34px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.subtitle {
  margin-bottom: 34px;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  color: $textDark;
  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.link {
  color: $blue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
