.conditionForm {
  padding-bottom: 32px;
  margin-top: 32px;
  border-bottom: 1px solid #C6C6C6;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    h3 {
      margin-bottom: 0;
    }

    .addConditionBtn {
      background: none;
      border: none;
      cursor: pointer;

      > svg path {
        fill: #757575;
      }
    }
  }

  .conditionGroupWrap {
    display: flex;
    flex-direction: column;

    & > div:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}