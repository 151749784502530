@import "../../../UIKit/index";

$width: 200px;

.container {
  padding: 24px 20px 95px;
  width: 100%;
  overflow-y: auto;

  .title {
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 20px;
  }
  .detail {
    div {
      div:nth-child(1) {
        width: $width !important;
      }
      div:nth-child(2) {
        width: calc(100% - #{$width}) !important;
      }
    }
  }
  .content {
    margin-top: 40px;
    .row {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
      .name {
        font-weight: 500;
        margin-bottom: 12px;
      }
    }
  }
  .filter {
    margin-top: 40px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 95px;
  }
  .savingsChartWrap{
    position: relative;
    .years{
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 2;
      & > div > div:first-child{
        background: transparent;
      }
      & > div >div:last-child{
        top: 40px;
      }
    }
  }
  .pieChartWidget,
  .savingsChart {
    margin-bottom: 24px;
    margin-left: 10px;
    width: 100%;
  }
  .pieChartLoader {
    margin-bottom: 24px;
  }
}
