.info {

  .title {
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.55);
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 4px;
  }

  &.flex {
    display: flex;
    gap: 10px;
    margin-bottom: 12px;
    align-items: center;
    word-break: break-all;

    .title {
      margin-bottom: 0;
    }
  }

  * > {
    font-weight: 400;
    font-size: 14px;
  }
}