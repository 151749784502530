.typeName {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #005a9e;

  .icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 50%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-right: 10px;
    color: #FFFFFF;
  }
}
.inlineWrap{
     transform: rotateX(180deg);
     display: flex;
     flex-direction: column;
     padding-bottom: 4px;
     max-height: calc(100vh - 235px);
     overflow-y: scroll;
}