.dropdownMenuItem {
  min-height: 32px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 18px;
  &.withSubItems{
    padding-left: 0;
  }
  .arrow{
    margin-right: 6px;
    cursor: pointer;
    transform: rotate(-90deg);
    &.opened{
      transform: rotate(0deg);
    }
    path{
      fill: black
    }
  }
}
.checkbox {
  cursor: pointer;
  min-height: 32px;
}
.subList{
  width: 100%;
  padding-left: 18px;
}
