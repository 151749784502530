@import '../../../../../UIKit/colorVars';

.detailWrap {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02),
  0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
  padding: 16px 16px 22px;
  position: relative;

  .inline {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .col {
    min-width: 260px;
    max-width: 280px;
    margin: 0;
    padding: 0;
    list-style: none;

    &:first-child {
      margin-right: 10px;
    }

    &:nth-child(2) {
      min-width: 280px;
      max-width: 300px;
    }

    &.bottom {
      width: 100%;
      max-width: 540px;
    }
  }

  .item {
    margin-bottom: 14px;

    .label {
      display: block;
      color: rgba(0, 0, 0, 0.55);
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 4px;
    }

    .value,
    .value span {
      color: rgba(0, 0, 0, 0.9);
      font-size: 14px;
      word-wrap: break-word;

      .noData {
        color: rgba(0, 0, 0, 0.55);
        font-size: 14px;
      }
    }
  }

  .attachments {
    display: flex;
    flex-wrap: wrap;
    color: #005a9e;
    font-size: 14px;

    .attachment {
      color: #005a9e;
      word-break: break-word;
      text-decoration: none;
      padding-right: 15px;
    }
  }

  .clients {
    margin: 0;
    padding: 0;
    list-style: none;

    .client {
      display: flex;
      align-items: center;
      line-height: 18px;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      svg {
        margin-left: 16px;
      }
    }
  }

  .showBtn {
    color: #2f78cd;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      path {
        fill: $button;
        fill-opacity: 1;
      }
    }

    &.opened {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .btns {
    position: absolute;
    top: 16px;
    right: 16px;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  .btn {
    &:first-child {
      margin-right: 8px;
      width: 74px;
    }

    &:last-child {
      width: 90px;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}