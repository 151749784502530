.chartQuarter {
  width: 148px;

  .total,
  .title {
    display: block;
    text-align: center;
    color: rgba(0, 0, 0, 0.55);
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
  }

  .total {
    margin-bottom: 5px;
  }

  .title {
    margin-top: 9px;
    text-transform: capitalize;
  }

  .quarter {
    .item {
      &:first-child {
        border-radius: 2px 2px 0 0;
      }

      &:last-child {
        border-radius: 0 0 2px 2px;
      }
    }
  }

  .item {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-width: 1px;
    border-style: solid;

    .progress {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 0;
      display: block;
    }
  }

  .itemContainer {
    position: relative;

    .value {
      position: absolute;
      top: 0;
      width: 100%;
      line-height: 30px;
      text-align: center;
      display: block;
      font-size: 12px;
      color: white;
      z-index: 0;
      pointer-events: none;
    }

    .over {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      background: transparent;
    }
  }
}
