.stepsWrapper {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02),
  0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .stepsList {
    width: fit-content;
    display: flex;
    justify-content: space-evenly;

    .arrow {
      display: flex;
      align-items: center;
    }

    .stepWrapper {
      display: flex;
      align-items: center;
      margin: 0 15px;

      &.active {

        .step {
          .num {
            background: #2F78CD;
          }

          .name {
            color: #2F78CD;
          }
        }
      }

      .step {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;

        &:not(:last-child) {
          margin-right: 15px;
        }

        .num {
          width: 22px;
          height: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background: #C8C8C8;
          font-size: 14px;
          color: #FFFFFF;
          margin-bottom: 4px;
        }

        .name {
          font-size: 14px;
          color: #C8C8C8;
        }
      }
    }
  }
}

.dots {
  display: flex;
  align-items: end;
  font-size: 25px;
  color: #C8C8C8;
}