.emptyContent {
  background: #FFFFFF;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.01);
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  padding: 35px 10px;
}

.tabs {
  display: flex;
  position: relative;

  .menu {
    position: fixed;
    z-index: 9;
    flex-direction: column;
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    background: #FFFFFF;

    li {
      height: 32px;
      width: 170px;
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 0 12px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);

      &:hover {
        background: #F3F3F3;
      }
    }
  }

  .tabsWrapper {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .tab {
    height: 28px;
    display: flex;
    align-items: center;
    background: #F3F3F3;
    border: 1px solid #EAEAEA;
    border-radius: 2px 2px 0 0;
    padding: 0 8px;
    cursor: pointer;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    white-space: nowrap;

    > svg {
      transition: 0.2s all ease;
    }

    &.opened {
      > svg {
        transition: 0.2s all ease;
        transform: rotate(-180deg);
      }
    }

    &.btn {
      padding: 2.5px 14px;
    }

    &.active {
      background: #FFFFFF;
      border-bottom: none;
    }

    &:hover {
      background: #F8F8F8;
    }
  }

  .input {
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 2px 2px 0 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    outline: none;
    padding: 0 10px;
    width: 150px;
  }
}