@import "../../../UIKit/colorVars";

.categoriesContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: auto;
}

.sidebar {
  background-color: $white;
  min-width: 32px;
  max-width: 380px;
  box-shadow: $cardShadow;
  min-height: calc(100vh - 48px);
  height: auto;
  height: 100%;
}
