.chartContainer {
  background: #fff;
  filter: drop-shadow(0px -0.5px 4px rgba(0, 0, 0, 0.05)) drop-shadow(0px -1px 10px rgba(0, 0, 0, 0.02)) drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.11)) drop-shadow(0px 0.5px 1px rgba(0, 0, 0, 0.1));

  .chartWrap {
    width: 100%;
    overflow: auto;
    max-height: 700px;
    height: auto;

    .chartInlineWrap {
      background: #FFFFFF;
      border-radius: 4px;
      padding-bottom: 23px;
      display: inline-block;
      position: relative;
      min-width: 100%;
    }

    .header {
      height: 44px;
      display: flex;
      align-items: center;
      padding-left: 22px;
      width: 100%;

      .headerInline {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .label {
          padding-left: 28px;
        }
      }

      .label {
        height: 44px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        width: 150px;
        min-width: 100px;
        font-weight: 500;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
      }

      & > span.label {
        flex: 1 0 auto;
        width: 192px;
        min-width: 192px;
        border-right: 1px solid #ECF0F4;
      }
    }

    .user {
      height: 24px;
      display: flex;
      align-items: center;
      padding: 0 23px;
      font-weight: 500;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.9);
      width: 100%;
      background: #E9E9E9;
      line-height: 18px;
      position: relative;
    }

    .projectWrap {
      display: flex;
      height: 55px;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #EAEAEA;

      &:hover {
        background-color: #F3F3F3;
      }
    }

    .projectDetails {
      width: calc(192px + 23px);
      max-width: calc(192px + 23px);
      flex: 1 0 auto;
      border-right: 1px solid #ECF0F4;
      padding-left: 23px;
      padding-right: 7px;
      height: 55px;
      padding-top: 8px;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .name {
        display: block;
        font-weight: 500;
        font-size: 12px;
        color: #005A9E;
        opacity: 0.87;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 130px;
        white-space: nowrap;
        cursor: pointer;
      }

      .currency {
        font-weight: normal;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100px;
        white-space: nowrap;
      }

      .category {
        display: flex;
        align-items: center;
        margin-top: 4px;
        flex-wrap: nowrap;

        span {
          display: block;
          margin-left: 4px;
          color: rgba(0, 0, 0, 0.55);
          font-size: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 130px;
          white-space: nowrap;
        }
      }
    }

    .chart {
      position: relative;
      z-index: 2;
    }

    .projectLineWrap {
      position: relative;
      height: 24px;
      width: calc(100% - 192px);


      .timeline {
        position: sticky;
        left: 0;
        margin-top: calc(25px + 14px);
        z-index: 100;
        background: #FFFFFF;
        padding: 10px 8px;
        border: 1px solid #EAEAEA;
        border-left-color: transparent;

        .lineWrap {
          position: relative;
          border-radius: 24px;
          margin-left: 15px;
          width: calc(100% - 30px);
          display: flex;
          justify-content: space-between;
          height: 16px;
          background: #EAEAEA;
        }

        .statusBarWrap {
          &:not(:first-child) {
            display: flex;
            justify-content: flex-end;
            flex: 1 0 auto;
            border-radius: 0 24px 24px 0;
            margin-left: -8px;

            &.active {
              background: #5E9ADF;
            }
          }

          &:first-child {
            width: 16px;
            min-width: 16px;
          }

          &.active {
            .statusBar {
              background: #005A9E;

              svg {
                display: block;
              }
            }
          }
        }

        .statusBar {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #C8C8C8;
          position: relative;
          z-index: 3;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            display: none;
          }

        }

        .statuses {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;

          .col {
            text-align: center;
            width: 110px;
            max-width: 110px;

            &:first-child {
              text-align: left;
            }

            &:last-child {
              text-align: right;
            }

            .status {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              font-size: 12px;
              color: rgba(0, 0, 0, 0.55);
              display: block;
            }
          }
        }
      }
    }

    .lines {
      position: absolute;
      top: 44px;
      left: calc(192px + 22px);
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      z-index: 1;

      .line {
        border-left: 1px dashed #ECF0F4;
        display: block;
        height: 100%;
        width: 150px;
        min-width: 100px;

        &:first-child {
          border-left: 1px solid transparent;
        }
      }
    }

    .dateCursorWrap {
      position: absolute;
      left: 214px;
      top: 0;
      right: 0;
      bottom: 0;
    }

    .dateCursor {
      position: absolute;
      top: 69px;
      bottom: 0;
      border-left: 2px solid #2F78CD;
      width: 2px;
      z-index: 19;

      .date {
        width: 92px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #5E9ADF;
        border: 1px solid #005A9E;
        font-size: 12px;
        color: white;
        margin-left: -47.5px;
        margin-top: -23px;
        border-radius: 1px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 100%;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 3px 4px 0 4px;
          border-color: #5e9adf transparent transparent transparent;
          left: calc(50% - 4px);
          z-index: 2;
        }

        &::after {
          content: "";
          position: absolute;
          top: 100%;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4px 5px 0 5px;
          border-color: #005A9E transparent transparent transparent;
          left: calc(50% - 5px);
          z-index: 1;
        }
      }
    }
  }

  .additionalActions {
    padding: 12px;
    border-top: 1px solid #EAEAEA;

    .showFullAction {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn {
        padding: 6px 25px;
        background: #E5E5E5;
        border-radius: 2px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        cursor: pointer;
        border: none;
      }

      .notification {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.9);
        margin-left: 10px;
      }
    }

  }
}

