.groupWrap {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 8px;

    &.opened {
      margin-bottom: 0;
    }
  }

  .headerGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 0;
    background: #E9E9E9;
    cursor: pointer;

    & > p {
      font-weight: 500;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.9);
      text-transform: capitalize;
    }

    & > .arrow {
      transition: 0.2s all ease;
      margin-left: 10px;

      &.opened {
        transition: 0.2s all ease;
        transform: rotate(180deg);
      }
    }
  }

  .content {
    display: flex;
  }
}