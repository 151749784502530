.wrap {
  width: 100%;
  overflow-y: auto;
  padding: 24px;
  iframe {
    border: none;
  }
}
.settings {
	display: flex;
	flex-direction: row;
    height: 48px;
	&>div {
		margin-left: 12px;
		&:first-of-type {
			margin-left: 0px;
		}
	}
	input {
		line-height: 26px;
	}
	svg {
		width: 32px;
		height: 32px;
	}
}
.fit {
	width: fit-content;
}
.periodContainer {
    display: flex;
    flex-direction: row;
    &>div {
        width: fit-content;
        &>div {
            width: fit-content;
            &>div {
                justify-content: flex-start;
            }
        }
    }
    &>div:nth-of-type(2) {
        margin-left: 12px;
    }
}