.goalsContainer {
  padding: 3px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;

  .title {
    margin-bottom: 56px;
  }

  .footer {
    display: flex;
    justify-content: center;
    width: 100%;

    .item:first-child {
      margin-right: 20px;
    }
  }
}