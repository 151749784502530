.supplierGeneralTable {
  margin-top: 20px;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;

  & > h3 {
    margin-bottom: 0 !important;
    height: fit-content;
  }
}

.inputWrap {
  margin: 16px 0;
  position: relative;
}

.inputService{
  margin-top: 24px;
}
.popUpAddService{
    width: 640px;
    p{
    font-size: 14px !important;
    }

}