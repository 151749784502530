@import "../../../UIKit/colorVars";

.wrapDropListTrigger {
  position: relative;
  display: flex;
  align-items: center;
  opacity: 1;
}

.trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  border-radius: 2px;
  padding: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: 0.3s all ease;
  background-color: transparent;

  &:hover {
    background-color: $white3;
  }

  &.notice {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  &.active {
    background-color: #e6e6e6;
  }

  .menu {
    display: block;
    width: 20px;
    height: auto;

    &.notice {
      position: relative;

      & > span {
        position: absolute;
        width: 18px;
        height: 18px;
        background: #005A9E;
        border: 2px solid #FFFFFF;
        font-size: 11px;
        color: #FFF;
        border-radius: 50%;
        top: -6px;
        right: -7px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.dropList {
  position: absolute;
  background-color: $white;
  padding: 4px 0;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  min-width: 194px;
  z-index: 99;
  left: -170px;
  top: 100%;
  list-style: none;
  margin: 0;

  &.overflow {
    top: auto;
    bottom: 100%;
  }

  &.scroll{
    max-height: 150px;
    overflow-y: overlay;

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: #C4C4C4;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}

.dropListItem {
  height: 32px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: black;
  cursor: pointer;
  padding: 0 8px;
  font-family: "Roboto", sans-serif;

  &:hover {
    background-color: $white2;
    opacity: 1;
    z-index: 0;
  }
}
