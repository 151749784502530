@import "../../../UIKit/colorVars";

.checkboxWrap {
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;
  }

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: -1;
    width: 0;
    height: 0;
    overflow: hidden;
    left: 0;
  }

  .checkbox {
    vertical-align: middle;
    position: relative;
    top: 2px;

    .check {
      position: relative;
      display: inline-block;
      width: 14px;
      height: 14px;
      border: 1px solid rgba(0, 0, 0, 0.9);
      border-radius: 3px;
      overflow: hidden;
      z-index: 1;
      cursor: pointer;
      box-sizing: border-box;
    }
  }

  .errorMessage {
    color: #a4262c;
    font-size: 12px;
    margin-top: 5px;
    margin-right: auto;
  }

  &.typeMinus {
    input[type="checkbox"] + .checkbox .check {
      background: url("../../../assets/icons/checkbox_grey.svg") no-repeat center center / cover;
      border: none;
      background-color: #fff;

      &:hover {
        background-color: #fff;
      }
    }
  }

  input[type="checkbox"]:focus + .checkbox .check:after {
    opacity: 0.2;
  }

  input[type="checkbox"]:checked + .checkbox .check {
    background: url("../../../assets/icons/checkbox.svg") no-repeat center center / cover;
    border: none;
    background-color: $checkbox;

    &:hover {
      background-color: $checkboxHover;
    }
  }
}

.labelGroup {
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  .label {
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
  }

  .subLabel {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.55);
  }
}
