.navButton {
  border-bottom: 1px solid #eeeeee;
  padding: 24px 8px;
  cursor: pointer;
  position: relative;
  .iconWrap {
    position: relative;
    svg {
      display: block;
      width: 16px;
      height: auto;
      path {
        fill: #c8c8c8;
      }
    }
    .count {
      position: absolute;
      left: 10px;
      bottom: 8px;
      z-index: 3;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background: #ff0000;
      border: 1px solid white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 9px;
      line-height: 1;
      color: white;
    }
    &.active {
      svg path {
        fill: #2f78cd;
      }
    }
  }
  .hint {
    position: absolute;
    top: 9px;
    right: 27px;
    z-index: 99991;
    padding: 7px 12px;
    background: #333333;
    border-radius: 2px;
    font-size: 14px;
    color: white;
    display: none;
    white-space: nowrap;
  }
  .message {
    position: absolute;
    z-index: 99992;
    right: 41px;
    top: 20px;
    display: flex;
    align-items: center;
    background-color: white;
    width: 303px;
    min-height: 74px;
    padding: 16px 40px 16px 21px;
    box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
      0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11),
      0px 0.5px 1px rgba(0, 0, 0, 0.1);
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3px 0 3px 9px;
      border-color: transparent transparent transparent #fff;
      top: 1px;
      left: 100%;
    }
    .closeBtn {
      position: absolute;
      cursor: pointer;
      top: 12px;
      right: 12px;
      z-index: 3;
      display: block;
      width: 14px;
      height: auto;
    }
    .text {
      display: block;
      margin-left: 12px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
    }
    svg {
      display: block;
      width: 24px;
      height: auto;
    }
  }
  &:hover {
    .hint {
      display: inline;
    }
  }
}
