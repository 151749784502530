.successPopup {
  width: 390px;
}
.wrap {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 152px;
  margin-top: 22px;
  .message {
    margin-top: 24px;
    font-weight: 500;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.9);
    max-width: 320px;
    display: block;
  }
}
