.singleNote {
  padding: 10px 24px;

  &:hover {
    background: #F3F3F3;
  }

  header {
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dataHeader {
      display: flex;
      gap: 8px;
      align-items: center;

      > div {
        p {
          &:first-child {
            font-weight: 500;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.9);
          }

          &:last-child {
            font-weight: 400;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.55);
          }
        }
      }
    }

  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 4px;
    word-break: break-all;
    white-space: break-spaces;
  }

  .attachments {
    display: flex;
    cursor: pointer;

    > li {
      margin-top: 0 !important;
    }
  }
}