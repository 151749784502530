.gradient {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;

  &.default {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }

  &.large {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }

  &.medium {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}