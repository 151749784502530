.userCard {
  display: flex;
  align-items: center;
  gap: 10px;
  height: fit-content;

  .gradient {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 500;
  }
}