.notesWrapper {
  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;

  .singleHistory {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    header {
      margin-bottom: 8px;

      p {
        display: inline;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.55);

        &:first-child {
          margin-right: 8px;
        }
      }
    }

    main {
      margin-bottom: 8px;

      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        opacity: 0.87;
        margin-bottom: 8px;
        white-space: break-spaces;
      }

      .attachment {
        color: #005a9e;
        word-break: break-word;
        text-decoration: none;
        padding-right: 15px;
      }
    }
  }
}