@import './colorVars';
@import './typography';
@import './slider';

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body,
html {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

.landingContainer {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.popupBtnsWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 64px;
}

.popupBtn {
  width: 105px;
  margin-left: 8px;
}

.loaderWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &.alignStart {
    align-items: flex-start;
  }
}

.loader {
  display: block !important;
  margin: 40px auto 0;
}

.skeletLoaderWrap {
  background: white;
  border-radius: 2px;
  padding: 20px;
  width: 100%;
}

body .MuiSkeleton-root {
  background-color: #f3f3f3;

  &.title {
    background: white;
  }
}

textarea {
  max-width: 100%;
}

::-webkit-scrollbar-button {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: $white;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 7px;
  border-radius: 7px;
  background-color: $gray2;
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $buttonSecondary;
}

::-webkit-resizer {
  background-repeat: no-repeat;
}

::-webkit-scrollbar {
  width: 8px;
}

#google-visualization-errors-all-1 {
  display: none !important;
}

.google-visualization-tooltip {
  background: #fff !important;
  border-radius: 1px !important;
  width: 185px !important;
  border: 1px solid #c8c8c8 !important;
  z-index: 9999999 !important;
  height: 44px !important;
  // bottom: calc(100% - 8px) !important;
  // top: auto !important;
  // left: -6px !important;
  ul {
    margin: 3px 0 !important;
  }

  li {
    margin: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    width: 100% !important;
    text-align: center !important;
    color: rgba(0, 0, 0, 0.9) !important;
    height: 16px !important;

    &:first-child span {
      font-size: 14px !important;
    }

    &:last-child {
      margin-top: 3px !important;

      span {
        font-size: 12px !important;
      }
    }

    span {
      font-weight: normal !important;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 5px 0 5px;
    border-color: #ffffff transparent transparent transparent;
    left: calc(50% - 5px);
    top: 100%;
  }

  // triangle border
  &::after {
    content: '';
    position: absolute;
    left: calc(50% - 6px);
    top: 100%;
    border-style: solid;
    border-width: 5px 6px 0 6px;
    border-color: #c8c8c8 transparent transparent transparent;
    z-index: -1;
  }
}

// google chart toltip
.google-visualization-tooltip {
  white-space: nowrap;
  width: fit-content !important;
}

.dateBlock {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .wrapperdatePicker {
    width: 100%;
  }

  .fullPicker {
    width: 100%;
  }

  .fullWrap {
    width: 100%;
  }

  svg {
    cursor: pointer;
  }

  .customInput {
    width: 256px;
    height: 32px;
    border: 1px solid #dadada;
    border-radius: 2px;
    padding-left: 11px;

    &:focus {
      border: 1px solid #4f78e0;
      outline: none;
    }

    &.fullInput {
      width: calc(100% - 7px);
    }
  }
}

.tagsWrap {
  position: relative;

  .tag {
    cursor: default;
  }
}

.tagsWrapNoIteractions {
  display: flex;
  flex-wrap: wrap;

  .tag {
    margin-right: 4px;
    margin-bottom: 4px;
  }
}

// react-trello IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  [class*='react-trello-lane'] {
    > div {
      flex-basis: auto !important;
    }
  }
}

// steps-widget
.react-flow__node {
  z-index: 0 !important;
  position: absolute !important;
}

body .subtitle {
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
  margin-bottom: 12px;
  display: block;
}

.dateBlock .wrapperdatePicker {
  width: calc(100% - 32px);
}

.MuiTableCell-stickyHeader {
  left: unset !important;
  right: unset !important;
}