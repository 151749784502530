@import "../../../../../UIKit/colorVars";

.filterDropdownWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &.filterListOpened {
    .nameBlock {
      background: #cccccc;

      p {
        color: black;
      }

      .arrowIcon {
        path {
          fill: black;
        }
      }
    }

    &:hover {
      .nameBlock {
        background: #cccccc;

        p {
          color: black;
        }

        .arrowIcon {
          path {
            fill: black;
          }
        }
      }
    }
  }

  .nameBlock {
    display: flex;
    cursor: pointer;
    height: 34px;
    align-items: center;
    border-radius: 3px;
    padding: 6px 11px;

    &:hover {
      background: #f3f3f3;

      p {
        color: #8a8886;
      }

      .arrowIcon {
        path {
          fill: #8a8886;
        }
      }
    }

    p {
      font-weight: 300;
      color: $textLight;
      margin-right: 12px;
      display: flex;
      align-items: center;

      &.selectedOption {
        color: black;
      }
    }

    .arrowIcon {
      transition: all 0.4s ease;
      display: flex;

      path {
        fill: $textLight;
      }

      &.listOpen {
        transform: rotate(180deg);
      }
    }
  }
}

.openedList {
  position: absolute;
  top: 51px;
  width: 256px;
  right: 0px;
  background: white;
  box-shadow: $modalShadow;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;

  .inputSearch {
    margin-bottom: 12px;
    width: 100%;

    & > input:focus {
      box-shadow: 0 0 0 3px rgba(211, 225, 244, 1);
    }
  }

  .optionsList {
    padding: 13px 12px 0 12px;
    overflow-y: scroll;
    max-height: 300px;
    scroll-behavior: smooth;
    overscroll-behavior: none;
    overflow-anchor: none;

    &.singleFilter {
      padding: 0;
    }

    .item {
      height: 40px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: rgba(#f3f3f3, .5);
      }

      &.active {
        background: #f3f3f3;
      }
    }
  }

  .dropDownWrap {
    display: flex;
  }

  .btnsWrapper {
    padding: 12px;
    display: flex;
    justify-content: space-between;

    .clear {
      width: 35%;
    }

    .apply {
      width: 60%;
      word-break: keep-all;
    }
  }
}

.clearBtn {
  display: flex;
  align-items: center;
  width: 58px;
}

