@import './landingColors.scss';

.landingContainer {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  // font-family: "Helvetica", sans-serif;

  @media (max-width: 1339px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.buttonLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 40px;
  font-size: 16px;
  color: #f5f5f5;
  background-color: $button;
  border-radius: 2px;
  // font-family: "Helvetica", sans-serif;
  transition: all 0.3s ease;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  &:hover {
    color: #f5f5f5;
    background-color:$buttonHover;
  }
  &:active {
    background-color:$buttonActive;
  }

  
}
