.wrap {
  padding: 25px 20px;
  width: 100%;
  overflow-y: auto;

  .title {
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 21px;
  }

  .details {
    margin-bottom: 40px;
  }

  .widgetsWrap {
    h2 {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 12px;
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .widgets {
    display: flex;
    gap: 22px;

    .widget {
      width: 100%;
      background: #FFFFFF;
      padding: 20px;
      box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
      0px -1px 10px rgba(0, 0, 0, 0.02),
      0px 3px 5px rgba(0, 0, 0, 0.11),
      0px 0.5px 1px rgba(0, 0, 0, 0.1);
      border-radius: 4px;

      h4 {
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.9);
      }

      > div > div {
        min-height: unset;
      }
    }
  }
}
