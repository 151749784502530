@import "UIKit/_colorVars.scss";

.container {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

.title {
  font-weight: 500;
  color: $textDark;
  margin-bottom: 20px;
  margin-left: 20px;
}

.chartWrapper {
  width: 100%;

  svg {
    > g > g:last-child {
      g {
        &:nth-child(3n+13),
        &:nth-child(3n+14),
        &:nth-child(3n+15) {
          display: none;
        }
      }
    }
  }
}

.container {
  min-width: 60%;
  width: auto;
}

.legendWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  .legend {
    display: flex;
    align-items: center;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    span {
      margin-right: 8px;
      width: 12px;
      height: 12px;
      background-color: red;
    }

    p {
      font-size: 12px;
      color: $textDark;
    }
  }
}
