.alert {
  position: fixed;
  left: 38%;
  bottom: 32px;
  z-index: 9999;
  align-items: center;
  height: 64px;
  padding-left: 34px !important;
  padding-right: 20px !important;
}
.success {
  background-color: #caeeca !important;
}
.btn {
  height: 25px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  margin-left: 21px;
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  padding: 2px 20px;
  cursor: pointer;
  outline: none;
}
.wrap {
  display: flex;
  align-items: center;
}

[class="MuiAlert-message"] {
  padding-left: 4px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.9);
}
