.field {
  display: flex;
  padding: 15px 20px;
  border-bottom: 1px solid #E9E9E9;
  background: #fff;
  cursor: move;

  .link {
    font-size: 14px;
    color: #005A9E;
    text-decoration: none;
    width: 100%;
  }

  & > div {
    width: calc(100% / 3);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;

    &:first-child > svg {
      transform: rotate(-90deg);
      margin-right: 10px;
    }

    &:last-child {
      justify-content: space-between;
    }
  }
}