.header {
  margin-bottom: 25px;
}

.infoWrap {
  display: flex;
  justify-content: space-between;

  .infoBlock {
    .title {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
      color: rgba(0, 0, 0, 0.55);
    }

    .value {
      font-weight: bold;
      font-size: 22px;
      color: rgba(0, 0, 0, 0.9);
    }

    &:last-child .value {
      text-align: right;
    }
  }
}