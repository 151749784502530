.stageWrap {
  padding: 8px;

  &:not(:last-child) {
    border-right: 1px solid #E9E9E9;
  }

  .addTaskBtn {
    color: rgba(0, 0, 0, 0.55);
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;
    padding: 0 6px;
  }
}

