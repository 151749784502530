@import "../../../UIKit/colorVars";

.btn {
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  height: 33px;
  font-weight: 500;
  font-size: 14px;
  padding: 0 16px;
  font-family: "Roboto", sans-serif;

  &.primary {
    color: $white4;
    background-color: $button;

    &:hover {
      background-color: $buttonHover;
    }

    &:active {
      background-color: $buttonActive;
    }
  }

  &.secondary {
    color: rgba(0, 0, 0, 0.9);
    background-color: $buttonSecondary;

    &:hover {
      background-color: $buttonSecondaryHover;
      color: rgba(0, 0, 0, 0.9);
    }

    &:active {
      background-color: $buttonSecondaryActive;
      color: rgba(0, 0, 0, 0.9);
    }
  }

  &.addElement {
    font-weight: 400;
    min-width: 124px !important;
    width: max-content;
    color: $white4;
    background: $button url("../../../assets/icons/btn-plus.svg") no-repeat left 13px center / 16px auto;
    padding-left: 40px;
    padding-right: 15px;
    position: relative;

    &:hover {
      background-color: $buttonHover;
    }

    &:active {
      background-color: $buttonActive;
    }
  }

  &.textButton {
    width: max-content;
    height: max-content;
    background-color: transparent;
    color: #9e9e9e;
  }

  &.disabled {
    background-color: $layoutBg;
    color: rgba(0, 0, 0, 0.55);
    pointer-events: none;

    &:hover {
      background-color: inherit;
    }

    &:active {
      background-color: inherit;
    }
  }

  &:focus {
    border: none;
    outline: none;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .addElement {
    width: auto !important;
  }
}
