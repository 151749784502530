.newRequestWrap {
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .title {
      margin-bottom: 0;
    }

    .newRequestBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #2F78CD;
      border-radius: 2px;
      color: #F5F5F5;
      font-weight: normal;
      font-size: 14px;

      > svg {
        margin-right: 10px;
      }
    }
  }

  .emptyBlock {
    height: 100%;
  }

  .description {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 12px;
  }

  .requestList {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow-x: auto;
    justify-content: space-between;
    margin-bottom: 10px;

    &::-webkit-scrollbar {
      width: 0;
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: none;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    .singleRequestType {
      width: 48.5%;
      display: flex;
      justify-content: space-between;
      background: #FFFFFF;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid #C8C8C8;
      padding: 12px;
      cursor: pointer;
      margin-bottom: 15px;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      label {
        height: fit-content;
        margin: 0;

        span {
          padding: 0;
          height: fit-content;
        }
      }

      &.selected {
        border: 1px solid #2F78CD;

        label svg {
          fill: #2F78CD;
        }
      }
    }
  }

  .changeModeBtn {
    font-size: 14px;
    color: #2F78CD;
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    transition: 0.2s all ease;

    &.active svg {
      transition: 0.2s all ease;
      transform: rotate(180deg);
    }

    > svg {
      transition: 0.2s all ease;

      path {
        fill: #2F78CD;
        fill-opacity: 1;
      }
    }
  }
}