.goalsWrapper {
  padding: 17px 0 0 0;
  height: 100%;

  .title {
    padding: 0 16px;

    & > span {
      color: rgba(0, 0, 0, 0.55);
      font-size: 14px;
      font-weight: 400;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .cartsWrapper {
    display: flex;
  }

  .chart {
    width: 65%;
    height: 250px;
    border-right: 1px dashed rgba(0, 0, 0, 0.55);
  }

  .radialDiagram {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .googleChart svg {
    & > g > g > g > rect[fill="#333333"] {
      fill: #ECF0F4;
    }

    & > g > g > g > rect[fill="#cccccc"] {
      stroke: #ECF0F4;
      fill: #fff;
      stroke-width: 1;
      stroke-dasharray: 10, 15;
    }

    & > g > g > g > rect[fill="#ebebeb"] {
      stroke: #fff;
      fill: none;
    }

    & > g > g {
      & > path {
        stroke: #fff;
      }
    }
  }
}
