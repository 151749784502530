.tablesContainer {
  background: #fff;
  padding: 20px;

  .title {
    margin-bottom: 56px;
  }

  .content {
    display: flex;
    justify-content: space-between;
  }

  .item:not(:last-child) {
    margin-bottom: 24px;
  }
}