.historyTable {
	box-shadow: none;
	border: 1px solid #eaeaea;
	margin-top: -1px;
	z-index: 0;
	max-height: calc(105px + 56px * 3);
	overflow-y: auto;
	border-radius: 0px 4px 4px 4px;
	tr {
		td:first-child {
			width: 48% !important;
			padding-right: 0 !important;
		}
		td:nth-child(2) {
			width: 15% !important;
			padding-right: 0 !important;
		}
		td:nth-child(3) {
			width: 30% !important;
		}
	}
}
