.timeSpentWrap {
  display: flex;
  flex-direction: column;

  h4 {
    font-weight: 500;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 20px;
  }
}

.projects {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  height: 200px;
  overflow-x: scroll;
  overflow-y: hidden;
  align-items: end;

  &::-webkit-scrollbar {
    display: none;
  }

  .project {
    min-width: 125px;
    background: rgba(91, 121, 180, 0.15);
    display: flex;
    align-items: center;
    border: 1px solid #5B79B4;
    padding: 5px;
    position: relative;

    .projectWrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      &.hide {
        display: none;
      }

      span {
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        color: #5B79B4;
      }
    }
  }
}

.totalTime {
  background: #5B79B4;
  border: 1px solid #5B79B4;
  width: 100%;
  padding: 5px 0;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}