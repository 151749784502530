.categoryTabWrapper {
  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
}

.categoryOverview {
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 500;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.9);
  }

  .infoBlock {
    width: calc(92% / 6);
    display: flex;
    flex-direction: column;
    box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
    0px -1px 10px rgba(0, 0, 0, 0.02),
    0px 3px 5px rgba(0, 0, 0, 0.11),
    0px 0.5px 1px rgba(0, 0, 0, 0.1);
    padding: 17px;
    background: #FFFFFF;
    border-radius: 4px;

    .name {
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.55);
      margin-bottom: 5px;
      display: flex;
      align-items: center;

      > svg {
        margin-right: 5px;
      }
    }

    .value {
      font-size: 21px;
      color: rgba(0, 0, 0, 0.9);

      &.turquoise {
        color: #63A5A1;

        span {
          color: #63A5A1 !important;
          opacity: 1 !important;
        }
      }
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

.link {
  text-decoration: none;
  font-size: 14px;
  color: #005A9E;
  opacity: 0.87;
}