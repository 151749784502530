.wrapper {
  width: 440px;
  height: 260px;
  border-radius: 8px;

  h1 {
    margin-left: 25px;
  }
  svg {
    position: absolute;
    top: 21px;
    width: 22px;
    height: 19px;
  }
}
