
.logoCompany{
    display: flex;
    padding: 20px;
    align-items: center;
    user-select: none;
}
.logo{
    height: 35px;
    width: 40px;
}
.company{
    margin-left:7px;
    font-weight: 500;
}