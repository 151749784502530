.goalsWidget {
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow-x: scroll;
  padding-top: 25px;

  &::-webkit-scrollbar {
    display: none;
  }

  .list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: relative;

    &:not(:last-of-type) {
      margin-right: 38px;
    }

    .goalCard {
      flex: 1 0 auto;
      position: relative;

      &:not(:first-child) {
        margin-top: 24px;
      }

      &.beforeLine::before, &.afterLine::after {
        content: '';
        position: absolute;
        width: 19px;
        height: 1px;
        background-color: #C8C8C8;
      }

      &.beforeLine::before {
        right: calc(100% + 1px);
        bottom: 35px;
      }

      &.afterLine::after {
        left: calc(100% + 1px);
        bottom: 35px;
        height: 0;
      }

      &.first {
        &.beforeLine::before, &.afterLine::after {
          top: 35px;
          bottom: auto;
        }
      }
    }
  }

  .line {
    display: block;
    position: absolute;
    width: 1px;
    background-color: #C8C8C8;
    top: 36px;
    bottom: 36px;
    left: -19px;

    &.last {
      left: calc(500px + 38px + 19px);
      top: 61px;
    }
  }
}