.attachmentsWrap {
  display: flex;
  justify-content: flex-end;
  position: relative;

  .icon {
    cursor: pointer;

    &.disabled {
      cursor: default;

      path {
        fill: #C8C8C8
      }
    }
  }

  .attachments {
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 99;
    background: #FFFFFF;
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    padding: 4px 0;
    margin: 0;
    list-style: none;
    min-width: 240px;
    max-height: 168px;
    overflow-y: auto;
  }

  .attachment {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 8px;

    svg {
      display: block;
      width: 24px;
      height: auto;
    }

    .link {
      display: block;
      margin-left: 8px;
      font-size: 14px;
      color: #005A9E;
      white-space: nowrap;
      text-decoration: none;
    }
  }
}

.statusWrapper {
  display: flex;
  align-items: center;

  .status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    background: #DE4935;

    &.pending {
      background: #F2CD7B;
    }

    &.created {
      background: #83A755;
    }
  }
}
