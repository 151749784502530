.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  .index {
    background: #63A5A1;
    color: #FFFFFF;
    border-radius: 6px;
    padding: 2px 6px;
  }
}

.infoWrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  & > div {
    display: flex;
    &:first-child {
      margin-bottom: 16px;
    }
    .infoBlock {
      width: 50%;
      .title {
        font-weight: 500;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
        margin-bottom: 4px;
      }

      .value {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
}