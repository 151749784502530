.container {
  padding: 24px 20px 0;
  width: 100%;
  overflow-y: auto;
}

.title {
  font-weight: 500;
  font-size: 21px;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 20px;

  &.widget {
    margin-bottom: 12px;
  }
}

.detailBlock {
  margin-bottom: 40px;
}