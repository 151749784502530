.preview {
  width: 120px !important;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: normal;
  background: #e5e5e5;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    margin-right: 5px;
  }
}
