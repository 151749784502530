.stagesWrapper {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;

  .timeline {
    width: 100%;
    margin-bottom: 35px;

    .lineWrap {
      position: relative;
      border-radius: 24px;
      margin-left: 15px;
      width: calc(100% - 30px);
      display: flex;
      justify-content: space-between;
      height: 16px;
      background: #EAEAEA;
    }

    .statusBarWrap {
      &:not(:first-child) {
        display: flex;
        justify-content: flex-end;
        flex: 1 0 auto;
        border-radius: 0 24px 24px 0;
        margin-left: -8px;

        &.active {
          background: #5E9ADF;
        }

        &.rejected {
          background: rgba(222, 73, 53, .4);;
        }
      }

      &:first-child {
        width: 16px;
        min-width: 16px;
      }

      &.active {
        .statusBar {
          background: #005A9E;

          svg {
            display: block;
          }
        }
      }

      &.rejected {
        .statusBar {
          background: #DE4935;

          svg {
            display: block;
          }
        }
      }
    }

    .statusBar {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #C8C8C8;
      position: relative;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;

      .stageName {
        position: absolute;
        white-space: pre;
        text-align: center;
        top: 20px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
      }

      .stageData {
        position: absolute;
        white-space: pre;
        text-align: center;
        top: 37px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
      }

      svg {
        display: none;
      }

    }

    .statuses {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .col {
        text-align: center;
        width: 110px;
        max-width: 110px;

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }

        .status {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.55);
          display: block;
        }
      }
    }
  }
}