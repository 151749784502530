.notificationsContainer {
  padding: 3px 20px;

  .title {
    margin-bottom: 56px;
  }

  .item:not(:last-child) {
    margin-bottom: 24px;
  }
}