@import "../../../UIKit/colorVars";

.milestoneContainer {
  padding: 24px 20px 95px;
  width: 100%;
  overflow-y: auto;
  .title {
    font-weight: 500;
    font-size: 21px;
    margin-bottom: 20px;
  }
}
