@import "../../../UIKit/colorVars";

.selectCheckDropdown {
  .label {
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
    margin-bottom: 4px;
  }
  .dropdownWrap {
    position: relative;
    & > .arrow {
      position: absolute;
      right: 5px;
      top: 4px;
      &.opened {
        transform: rotate(180deg);
      }
    }
  }
  .dropdownHeader,
  .field {
    height: 32px;
    border: 1px solid #dadada;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px;
    & > input {
      padding-right: 35px;
    }
    cursor: pointer;
    .title {
      color: rgba(0, 0, 0, 0.9);
      font-size: 14px;
      &.placeholder {
        color: rgba(0, 0, 0, 0.55);
      }
    }
    .arrow {
      &.opened {
        transform: rotate(180deg);
      }
    }
  }
  .field {
    border: none;
    padding: 0;
  }
}

.errorMessage {
  color: #a4262c;
  font-size: 12px;
  margin-top: 5px;
  margin-right: auto;
}