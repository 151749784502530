@import "../../../UIKit/index";

.listOfUsersContainer {
  padding: 24px 20px 0;
  width: 100%;
  overflow-y: auto;
  height: 100%;

  .filterBar {
    width: 100%;
    margin-bottom: 20px;
  }
}

.title {
  font-weight: 500;
  font-size: 21px;
}

.tableBlock {
  width: 100%;

  & > div {
    height: 100%;

    & > div {
      height: 100%;
    }
  }
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.button {
  width: auto !important;
  padding: 0 12px;
  font-weight: normal !important;
}
