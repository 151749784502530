.arrow {
  cursor: pointer;
  position: absolute;
  height: 30px !important;
  width: 30px !important;
  top: 10px;
  right: 10px;
  transform: rotate(180deg);

  &.opened {
    transform: rotate(360deg);
  }
}
