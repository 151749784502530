@import "../../../UIKit/index";

.emptyBlock {
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: center;
  box-shadow: $cardShadow;
  background-color: white;
  .icon {
      display: block;
      margin: 0 auto;
      padding-bottom: 20px;
    }
  .title {
    width: 100%;
    text-align: left;
    padding-left: 20px;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
    font-weight: 500;

    &.message {
      text-align: center;
      font-weight: normal;
      margin-bottom: 0;
    }
  }
  .subtitle {
    font-size: 14px;
    margin-bottom: 24px;
  }
}
