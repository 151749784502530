.content {
  height: 300px;
  display: flex;
  margin-top: 20px;

  .labels {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 260px;
    position: absolute;
    top: 85px;
    left: 42px;

    & > div {
      padding: 5px 0;
      display: flex;
      align-items: center;
      .statusIcon {
        width: 8px;
        height: 8px;
        margin-right: 4px;
        border-radius: 50%;
        display: block;
      }

      &:nth-child(1) {
        .statusIcon {
          background: #DF0505;
        }
      }

      &:nth-child(2) {
        .statusIcon {
          background: #F29239;
        }
      }

      &:nth-child(3) {
        .statusIcon {
          background: #F2CD7B;
        }
      }

      &:nth-child(4) {
        .statusIcon {
          background: #83A755;
        }
      }
    }
  }

  .dragEndDropWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 260px;
    width: 100%;
  }

  .statusItemWrapper {
    display: flex;
    align-items: center;
    width: 100%;

    & > svg {
      transform: rotate(90deg);
      margin-right: 10px;
    }

    &:nth-child(1) {
      .statusItem {
        background: rgba(222, 73, 53, 0.05);
      }
    }

    &:nth-child(2) {
      .statusItem {
        background: rgba(242, 146, 57, 0.05);
      }
    }

    &:nth-child(3) {
      .statusItem {
        background: rgba(242, 205, 123, 0.05);
      }
    }

    &:nth-child(4) {
      .statusItem {
        background: rgba(131, 167, 85, 0.05);
      }
    }

    .statusItem {
      border: 1px solid #DADADA;
      box-sizing: border-box;
      border-radius: 2px;
      padding: 5px 11px;
      width: 100%;
      text-transform: capitalize;
      cursor: move;
    }
  }
}

.buttonsWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.saveButton {
  margin-left: 4px;
}