.gaugeChartWrap {
  width: 200px;
  .title,
  .percent {
    color: rgba(0, 0, 0, 0.9);
    font-weight: 500;
    display: block;
  }
  .gaugeChart {
    svg {
      height: 108px;
    }
  }
  .title {
    font-size: 15px;
    margin-bottom: 20px;
    display: block;
  }
  .percent {
    text-align: center;
    font-size: 21px;
  }
}
