.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .col {
    padding: 8px;

    &:not(:first-child) {
      border-left: 1px solid #e9e9e9;
    }
  }

  .tasks {
    margin-bottom: 14px;

    .task {
      padding: 8px 12px;
      background: #ffffff;
      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.07),
      -1px -0.5px 1px rgba(0, 0, 0, 0.07);
      border-radius: 2px;
      color: rgba(0, 0, 0, 0.9);
      font-size: 12px;
      position: relative;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 8px;
      }

      .line {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
      }

      .taskHeadWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
      }

      .taskContent {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.55);
        }

        .completed {
          width: 16px;
          height: 16px;
          border: 1px solid #3DB79F;
          box-sizing: border-box;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .addTaskBtn {
    color: rgba(0, 0, 0, 0.55);
    font-size: 14px;
    font-weight: 500;
    background: transparent;
    padding: 0 6px;
  }
}

p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
