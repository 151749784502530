.taskWrap {
  background: #FFFFFF;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.07), -1px -0.5px 1px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;

  p {
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .taskContent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.55);
    }

    .completed {
      width: 16px;
      height: 16px;
      border: 1px solid #3DB79F;
      box-sizing: border-box;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}