.rightSidebarOpener {
  position: relative;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: white;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
    0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11),
    0px 0.5px 1px rgba(0, 0, 0, 0.1);
  .closeBtn {
    position: absolute;
    top: 22px;
    left: -14px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
    cursor: pointer;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    background: white;
    border: none;
    svg {
      transform: rotate(90deg);
      display: block;
      width: 24px;
      height: auto;
      path {
        fill: #605e5c;
      }
    }
  }
}
