.content {
  overflow-y: scroll;
  position: relative;
  max-height: 700px;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C4C4C4;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.messagesWrapper {
  padding: 17px 0 0 0;

  .title {
    padding: 0 16px;
    margin-bottom: 0;
  }

  .content {
    padding: 0 16px;
    margin-top: 14px;
  }

  .message {
    &:not(:last-child) {
      margin-bottom: 25px;
    }

    text-decoration: none;

    .owner {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .avatarWrapper {
        width: 32px;
        height: 32px;
        min-width: 32px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font: {
          size: 22px;
          weight: bold;
        };
      }

      .info {
        margin-left: 8px;

        p {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
          white-space: break-spaces;
        }

        .date {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.55);
        }
      }
    }

    .files {
      margin-bottom: 25px;

      li > svg {
        width: 25px;
        height: 25px;
      }
    }

    .messageContent {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      word-break: break-word;
      margin-bottom: 4px;
    }
  }
}

