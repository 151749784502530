.emptyMargins {
  margin-top: 30px;

  .questionName {
    display: flex;
    flex-direction: column;

    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.MuiTableCell-root {
  padding: 0px !important;
}

.subRow {
  td {
    background-color: #d5e3f1 !important;
  }
}
