.container {
  padding: 10px 20px 0;
  width: 100%;
  overflow-y: auto;
  .title {
    margin-bottom: 10px;
  }
}
.filterBar {
  margin-bottom: 20px;
}

.inlineWrap{
     transform: rotateX(180deg);
     display: flex;
     flex-direction: column;
     padding-bottom: 4px;
     max-height: calc(100vh - 235px);
     overflow-y: scroll;
}