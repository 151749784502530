.wrap {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  width: calc(100% + 24px);
  .task {
    width: 250px;
    height: 183px;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:last-child {
      margin-right: 0;
    }
  }
}
