.paginationWrapper {
  width: 302px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .pagesInfo {
    color: rgba(0, 0, 0, 0.55);
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
  }

  .arrows {
    width: 179px;
    display: flex;
    justify-content: space-between;

    .arrowBlock {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      cursor: pointer;

      &.disabled {
        pointer-events: none;
        svg, path {
          pointer-events: none;
          fill: #c8c8c8;
        }
      }

      &.firstPageIcon {
        transform: rotate(180deg);
      }

      &.nextPage {
        transform: rotate(180deg);
      }

      &:hover {
        background: #f3f3f3;
      }
    }
  }
}
