.dateWrap > div > div {
  width: 100%;

  > p {
    margin-bottom: 4px;
  }

  > div {
    width: 100%;

    input {
      width: 100%!important;
    }
  }
}