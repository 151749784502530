.subtasksWrapper {
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 4px;
  padding-top: 16px;
  padding-bottom: 3px;

  header {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 21px;

      .titleWrap {
        display: flex;
        gap: 5px;

        h3 {
          font-weight: 500;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
        }

        span {
          width: 21px;
          height: 21px;
          background: #F0F0F0;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          font-size: 11px;
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }

    .header {
      display: flex;

      .subTitle {
        color: rgba(0, 0, 0, 0.55);
        padding: 12px 21px;
        font-weight: 500;
        font-size: 12px;

        &:first-child {
          min-width: 45%;
          max-width: 45%;
        }

        &:last-child {
          min-width: 55%;
          max-width: 55%;
        }
      }
    }
  }

  .subtasks {
    padding: 5px 0;

    .subtask {
      background: #FFFFFF;
      padding: 17.5px 24px;
      color: #005A9E;
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;


      > div {
        &:nth-child(1) {
          min-width: 45%;
          max-width: 45%;
        }

        &:nth-child(2) {
          min-width: 50%;
          max-width: 50%;
        }

        &:nth-child(3) {
          min-width: 10px;
          max-width: 10px;
        }
      }


      &:hover {
        background: #F3F3F3;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #EAEAEA;
      }
    }
  }
}