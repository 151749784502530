.detail {
  margin-bottom: 40px;
}

.columns {
  display: flex;

  .col {
    &:first-child {
      margin-right: 75px;
    }

    .title {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.55);
      margin-bottom: 4px;

    }

    .value {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      display: flex;
      align-items: center;
    }
  }
}