.table {
  tbody tr td {
    &:first-child {
      width: 38%;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: 18%;
    }
  }
  .subSupplier {
    display: flex;
    justify-content: start;
    p:first-child {
      color: #005a9e !important;
    }
    p:last-child {
      color: #858585 !important;
      margin-left: 8px;
    }
  }
}
.link {
  color: #005a9e;
  text-decoration: none;
}
.logoSubSupplier{
    display: flex;
    align-items: center;
}