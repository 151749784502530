@import "../../../../landingUi/landingMain.scss";

.scheduleADemo {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #EAF4FF;
}

.title {
  text-align: center;
  color: $title;
  font-weight: bold;
  font-size: 36px;
  line-height: 150%;
  margin-bottom: 8px;
}

.text {
  display: block;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  font-size: 20px;
  line-height: 150%;
  max-width: 525px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

.buttonLink {
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 767px) {
    width: 100%;
    height: 48px;
  }
}
