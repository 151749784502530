@import "../../landingUi/landingMain.scss";

.footer {
  width: 100%;
  background-color: $title;
  padding-top: 64px;
  padding-bottom: 49px;
}

.topBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
  margin-bottom: 40px;
}

.linkIn {
  color: $white;
  display: flex;
}

.linkText {
  width: 109px;
  font-size: 18px;
  line-height: 150%;
  @media (max-width: 899px) {
    display: none;
  }
}

.bottomBlock {
  display: flex;
  justify-content: space-between;
  color: #C8C8C8;
  @media (max-width: 899px) {
    flex-direction: column;
    align-items: center;
  }
}

.policies {
  @media (max-width: 899px) {
    margin-bottom: 8px;
  }
}

.policyLink {
  font-size: 16px;
  color: $white;
  &:hover {
    color: $white;
  }
  margin-right: 18px;
  &:last-of-type {
    margin-right: 0;
  }
}

.copyright {
  font-size: 16px;
  @media (max-width: 899px) {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
