@import "../../../../../UIKit/index";

.navigation {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 48px);
  height: 100%;
}
.title {
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.9);
  padding: 7px 24px 16px 24px;
  display: block;
}

.list {
  width: 100%;
  min-width: 0;
  transition: all 0.3s ease;
  padding-top: 20px;
  height: 100%;
  overflow-y: auto;
  &.openedList {
    width: 100%;
    min-width: 380px;
  }
}

.inputSearch {
  margin: 0 20px;
}

.categoriesNavigation {
  margin: 0;
  padding: 24px;
}
.noResults {
  margin-top: 24px;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: rgba(0, 0, 0, 0.55);
}
