@import "../../../../landingUi/landingMain.scss";

.ppmSection {
  margin-top: 64px;
  padding-top: 100px;
  padding-bottom: 80px;
  @media (max-width: 1199px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.title {
  text-align: center;
  color: $title;
  font-weight: bold;
  font-size: 36px;
  line-height: 110%;
  margin-bottom: 64px;
  @media (max-width: 1199px) {
    margin-bottom: 48px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1199px) {
    flex-direction: column;
  }
}

.content {
  max-width: 519px;
  @media (max-width: 1199px) {
    align-self: start;
    margin-bottom: 24px;
  }
  @media (max-width: 767px) {
    margin-bottom: 33px;
  }
}

.subTitle {
  color: $title;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 16px;
}

.text {
  max-width: 519px;
  font-size: 18px;
  line-height: 150%;
  color: $textLigtht;
  margin-bottom: 23px;
}

.checkedLine {
  margin-bottom: 12px;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: 1199px) {
    margin-bottom: 9px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.imgBlock {
  box-shadow: $shadow;
  width: 628px;
  height: 366px;
  background-image: url("../../../../assets/img/modules/PPMimg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 1199px) {
    max-width: 720px;
    width: 100%;
    height: 420px;
    background-image: url("../../../../assets/img/modules/PPMimgTablet.jpg");
    align-self: start;
  }

  @media (max-width: 767px) {
    height: 192px;
    max-width: 328px;
    background-image: url("../../../../assets/img/modules/PPMimgMobile.jpg");
  }
}

// .img {
//   width: 100%;
//   height: auto;
//   max-width: 626px;
//   @media (max-width: 1199px) {
//     align-self: start;
//     max-width: 100%;
//   }
// }
