.tabsWrapper {
  display: flex;
  justify-content: space-between;

  .tabs {
    &.primary {
      margin-bottom: 20px;
    }

    &.secondary {
      position: relative;
      z-index: 1;
    }

    .tab {
      &.secondary {
        border-left: none;

        &:first-child {
          border-left: 1px solid #eaeaea;
        }
      }
    }
  }
}
