@import "../../../landingUi/landingMain.scss";

.topNav {
  display: flex;
  align-items: center;
}

.topNavLink {
  font-size: 16px;
  color: $textLigtht;
  font-weight: normal;
  text-decoration: none;
  margin-right: 24px;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  &:last-of-type {
    margin-right: 0;
  }
  &::before {
    display: none;
  }
  &:hover {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #2F78CD;
      animation-name: hover-line;
      animation-duration: 0.45s;
      animation-fill-mode: forwards;
    }
  }
}

.active {
  border-bottom: 1px solid #2F78CD;
  color: rgba(0, 0, 0, 0.9);
  display: inline-block;
  &:hover {
    &::before {
      display: none;
    }
  }
  @media (max-width: 899px) {
    font-weight: bold;
  }
}

.smallView {
  display: none;
  @media (max-width: 899px) {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .logInLink {
    border: 1px solid #005a9e;
    box-sizing: border-box;
    border-radius: 2px;
    margin-bottom: 16px;
    
  }

  
}

@keyframes hover-line {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
