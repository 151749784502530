@import "../../../UIKit/index";

.container {
  padding: 24px 20px 0;
  width: 100%;
  overflow-y: auto;

  .title {
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 20px;
  }

  .sectionTitle {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 500;
  }

  .blocksWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &.three {
      > div {
        width: 33.333%;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    &.two {
      > div {
        width: 50%;

        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}

.GanttChart{
    margin-bottom: 20px;
}
