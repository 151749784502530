.recentRequestWrap {
  display: flex;
  flex-direction: column;
  padding: 17px 0;

  .loadingWrap {
    padding: 0 17px;
    height: 100%;
  }

  header {
    display: flex;
    padding: 0 17px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .title {
      margin-bottom: 0;
    }

    .redirectBtn {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      font-weight: normal;
      background: #E5E5E5;
      border-radius: 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }
  }

  .emptyBlock {
    height: 100%;
  }

  .content {
    height: 100%;
    overflow-y: overlay;

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: #C4C4C4;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  .requestName {
    display: flex;
    flex-direction: column;

    a {
      color: #005A9E;
      font-size: 14px;
    }

    span {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.55);
    }
  }

  .procurementName {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      margin-right: 5px;
    }

    .icon {
      width: 24px;
      height: 24px;
      min-width: 24px;
      border-radius: 50%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      margin-right: 5px;
      color: #FFFFFF;
    }
  }
}