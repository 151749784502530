.wrap {
  padding: 25px 20px 24px;
  width: 100%;
  overflow-y: auto;

  .title {
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.titleSplit {
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 20px;
}

.splitBlock {
  display: flex;
  background: white;
  width: 372px;
  height: 210px;
  padding: 7px;
  flex-direction: column;
  overflow: hidden;
}

.splitBlockElem {
  padding: 20px;
  height: 210px;
  width: 372px;
  background: #FFFFFF;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02),
  0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
}

.wrapFinancialBenefits {
  background: #FFFFFF;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02),
  0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
}

.details {
  width: 100%;
  padding: 0;
  border: 0 solid #eaeaea;
}

.splitSavings {
  background: #F3F3F3;
  padding: 20px;
  margin-bottom: 13px;
}

.headerNewBenefit {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.dateBlockInput {
  width: 98%;
  margin-bottom: 0;
}

.dateBlock {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;

  .wrapperdatePicker {
    width: 49%;
  }

  .fullPicker {
    width: 49%;
  }

  svg {
    cursor: pointer;
  }

  .customInput {
    width: 100%;
    height: 32px;
    border: 1px solid #dadada;
    border-radius: 2px;

    &:focus {
      border: 1px solid #4f78e0;
      outline: none;
    }
  }
}

.inputWrap {
  margin-bottom: 30px;
  position: relative;
  width: 49%;
}

.wrapperdatePickeDate {
  width: 93%;
}

.noteFinBen {
  height: 44px;
}

.noteFinBenBlock {
  width: 49%;
  margin-bottom: 28px;
}

.noteAttachmentFinBen {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 15px;
}

.attachmentFinBen {
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-bottom: 16px;
}

.titleSpliSavings {
  margin-top: 20px;
  margin-bottom: 13px;
}

.addBtn {
  display: block;
  margin-top: 12px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.55);
  cursor: pointer;
}

.departmentHasProc {
  display: flex;
}

.hasOr {
  margin-left: 17px;
  margin-right: 17px;
  margin-top: 32px;
}

.SplitWrapperdatePicker {
  margin-top: 25px;
}

.googleChart svg {
  & > g > g > g > rect[fill='#333333'] {
    fill: #ecf0f4;
  }

  & > g > g > g > rect[fill='#cccccc'] {
    stroke: #ecf0f4;
    fill: #fff;
    stroke-width: 1;
    stroke-dasharray: 10, 15;
  }

  & > g > g > g > rect[fill='#ebebeb'] {
    stroke: #fff;
    fill: none;
  }

  & > g > g {
    & > path {
      stroke: #fff;
    }
  }
}

.cartsWrapper {
  display: flex;
}

.chart {
  display: flex;
  width: 100%;
}

.SplitBenefitsBlockItem {
  display: flex;
  justify-content: space-between;
}

.pieChartWrap {
  z-index: 0;
  margin-top: -20px;
}

.titleSplitItem {
  padding-left: 11px;
  padding-top: 10px;
}