@import "../../../../landingUi/landingMain.scss";

.features {
  padding-top: 100px;
  padding-bottom: 80px;
  @media (max-width: 899px) {
    padding-top: 80px;
  }
}

.title {
  text-align: center;
  color: $title;
  font-weight: bold;
  font-size: 36px;
  line-height: 110%;
  margin-bottom: 64px;
  @media (max-width: 899px) {
    margin-bottom: 40px;
  }
}

.cardsBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 64px;
  @media (max-width: 899px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
}

.bigTitle {
  max-width: 100%;
}

.buttonLink {
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 767px) {
    width: 100%;
  }
}