.form {
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: white;
  padding: 16px 20px 32px;

  .col {
    padding-bottom: 24px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    .field {
      margin-bottom: 16px;

      p {
        margin-bottom: 0;
      }

      & > span {
        margin-bottom: 0;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #c6c6c6;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:first-of-type {
      margin-top: 24px;

      .field:first-child,
      .field:nth-child(2) {
        margin-bottom: 16px;
      }
    }
  }

  .field {
    width: calc(50% - 12px);
    max-width: unset;
  }

  .clients {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;

    .client {
      width: 100%;
      display: flex;
      align-items: baseline;
      justify-content: flex-start;

      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }

    .checkbox {
      margin-left: 24px;
    }

    .deleteBtn {
      margin-left: auto;
      cursor: pointer;
    }
  }

  .addClientBtn {
    display: block;
    margin-top: 12px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.55);
    cursor: pointer;
  }

  .datesWrap {
    width: 100%;

    & > div {
      width: calc(50% - 12px);

      > div {
        width: 100%;

        input {
          width: 100%;
        }
      }
    }

    .datePickerWrap {
      width: 100%;

      & > div {
        justify-content: space-between;

        & > div.wrapperdatePicker {
          width: calc(100% - 32px);
          flex: 1 0 auto;
          max-width: calc(100% - 32px);
        }
      }
    }
  }
}

.clientsWrapper {
  display: flex;
  flex-direction: column;
}

.error {
  font-size: 12px;
  line-height: 16px;
  color: #A4262C;
  margin-top: 5px;
}

.subLabel {
  color: #737373;
  font-style: oblique;
}