@import "../../../../../landingUi/landingColors.scss";

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.img {
  margin-bottom: 16px;
}

.title {
  color: $title;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 4px;
}

.text {
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 40px;
}
