.clarificationHistoryWrap {
  background: #FFFFFF;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05), 0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11), 0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 17px 20px;

  h2 {
    font-weight: 500;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 20px;
  }

  .historyList {
    max-height: 255px;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
}