.goalsCard {
  background: white;
  border: 1px solid #c8c8c8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  padding: 8px 10px;
  position: relative;
  line-height: 1.5;
  cursor: pointer;
  height: 94.5px;

  header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 4px;
    border-bottom: 1px solid #EAEAEA;
    margin-bottom: 5px;

    p {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.9);

      span {
        color: rgba(0, 0, 0, 0.55);
      }
    }
  }

  &:hover {
    .editBtn {
      display: block;
    }
  }

  &.loading {
    box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
    0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11),
    0px 0.5px 1px rgba(0, 0, 0, 0.1);
    border: none;
    min-height: 66px;

    &.active {
      height: 232px;
      border: none;
      border-radius: 4px;
    }
  }

  &.active {
    border: 1px solid #005a9e;
    height: 258px;

    &::after {
      height: 1px !important;
    }

    .titleWrap {
      margin-bottom: 12px;
    }

    .name {
      color: #005a9e;
    }

    .quarters {
      display: block;
    }
  }

  .titleWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
    position: relative;
    text-align: center;

    .goalsCard {
      background: white;
      border: 1px solid #c8c8c8;
      border-radius: 4px;
      padding: 8px 10px;
      position: relative;
      line-height: 1.5;
      cursor: pointer;

      header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 4px;
        border-bottom: 1px solid #EAEAEA;
        margin-bottom: 5px;

        p {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.9);

          span {
            color: rgba(0, 0, 0, 0.55);
          }
        }
      }

      &:hover {
        .editBtn {
          display: block;
        }
      }

      &.loading {
        box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
        0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11),
        0px 0.5px 1px rgba(0, 0, 0, 0.1);
        border: none;
        min-height: 66px;

        &.active {
          height: 232px;
          border: none;
          border-radius: 4px;
        }
      }

      &.active {
        border: 1px solid #005a9e;

        &::after {
          height: 1px !important;
        }

        .titleWrap {
          margin-bottom: 12px;
        }

        .name {
          color: #005a9e;
        }

        .quarters {
          display: block;
        }
      }

      .titleWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 6px;
        position: relative;
        text-align: center;

        .title {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        span {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.55);
          display: block;
          margin-left: 4px;
          white-space: nowrap;
        }
      }

      .name {
        color: rgba(0, 0, 0, 0.9);
        font-size: 15px;
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 162px;
      }

      .quarters {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: none;
      }

      .quarter {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgba(0, 0, 0, 0.55);
        font-weight: 500;
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 16px;
        }

        .label {
          font-size: 12px;
        }

        .currency {
          font-size: 14px;
          display: flex;
          gap: 4px;
          font-weight: 400;

          span:first-child {
            color: rgba(0, 0, 0, 0.75);
          }

          span:last-child {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.55);
          }
        }
      }

      .annualGoal {
        display: flex;
        gap: 4px;

        span {
          &:nth-child(2) {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.55);
          }

          &:last-child {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.55);
          }
        }
      }

      .subData {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        color: #005a9e;

        &:not(:last-child) {
          margin-bottom: 6px;
        }
      }

      .editBtn {
        display: none;
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 2;
        cursor: pointer;
        background: white;
        padding: 2px;
      }
    }

    .cardWrapper {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      .cardType {
        width: 26px;
        height: 26px;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        border-radius: 50%;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .titleName {
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
      }
    }

    span {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.55);
      display: block;
      margin-left: 4px;
      white-space: nowrap;
    }
  }

  .name {
    color: rgba(0, 0, 0, 0.9);
    font-size: 15px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 162px;
  }

  .quarters {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: none;
  }

  .quarter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0, 0, 0, 0.55);
    font-weight: 500;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 16px;
    }

    .label {
      font-size: 12px;
    }

    .currency {
      font-size: 14px;
      display: flex;
      gap: 4px;
      font-weight: 400;

      span:first-child {
        color: rgba(0, 0, 0, 0.75);
      }

      span:last-child {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
      }
    }
  }

  .annualGoal {
    display: flex;
    gap: 4px;

    span {
      &:nth-child(2) {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.55);
      }

      &:last-child {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
      }
    }
  }

  .subData {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    color: #005a9e;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  .editBtn {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
    cursor: pointer;
    background: white;
    padding: 2px;
  }
}
