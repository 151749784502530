@import "../../../landingUi/landingColors.scss";

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.numberWrap {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #eaf4ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.number {
  color: #333d66;
  font-weight: bold;
  font-size: 24px;
}

.line {
  width: 2px;
  height: 154px;
  background-color: #333d66;
  // animation-name: speed-line;
  // animation-duration: 0.45s;
  // animation-fill-mode: backwards;
}

@keyframes speed-line {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}


