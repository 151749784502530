.header {
  display: flex;
  justify-content: start;
  align-items: start;
  margin-bottom: 20px;

}
.formContent {
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05), 0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11), 0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #FFFFFF;
  padding: 19px;

  .field {
    display: flex;
    justify-content: space-between;
    height: 65px;
  }
  .fieldInputName{
    width:55%
  }
  .fieldInputSource{
    width:44%
  }
  .fieldInput{
    display: flex;
    justify-content: space-between;
    width: 90%;
  }
  .options {
    display: flex;
    flex-direction: column;
    margin: 14px 0;
    width: 50%;

    > li {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      .input {
        width: 100%;
      }
    }
  }

  .addOption {
    display: block;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.55);
    cursor: pointer;
    width: fit-content;
  }

  .error {
    font-size: 12px;
    line-height: 16px;
    color: #A4262C;
    margin-top: 5px;
  }

  .optionWrap {
    display: flex;
    justify-content: space-between;

    .deleteBtn {
      margin-left: 15px;
      margin-top: 3px;
      cursor: pointer;
    }
  }
  .col {
  height:200px;
  width:200px;
      padding-bottom: 24px;
      margin-bottom: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      .field {
        margin-bottom: 16px;

        p {
          margin-bottom: 0;
        }

        & > span {
          margin-bottom: 0;
        }
      }
  }
}