@import "../../../../../../UIKit/colorVars";

.statusContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  .textWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .statusName {
      font-size: 14px;
    }
    .statusPercent {
      font-size: 12px;
      font-weight: 500;
    }
  }
  .statusBarContainer {
    margin-top: 4px;
    position: relative;
    height: 4px;
    width: 100%;
    background: $menuItem;
    border-radius: 2px;
    &:last-child {
      margin-bottom: 0;
    }
    .statusBar {
      position: absolute;
      top: 0;
      left: 0;
      height: 4px;
      border-radius: 2px;
    }
  }
}
