@import "../../../../landingUi/landingMain.scss";

.increasingProcurement {
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 1439px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.title {
  margin-bottom: 16px;
  font-size: 36px;
  line-height: 110%;
  font-weight: bold;
  color: $title;
  text-align: center;
}

.text {
  margin-bottom: 64px;
  font-size: 20px;
  line-height: 150%;
  color: $textLigtht;
  text-align: center;
  max-width: 761px;
  margin-left: auto;
  margin-right: auto;
}

.cards {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1439px) {
    flex-direction: column;
  }
}
