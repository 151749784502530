@import "../../../UIKit/colorVars";

.autoInput::placeholder {
  color: rgba(0, 0, 0, 0.9);
  font-weight: normal;
  font-size: 14px;
}

.wrapAutoInput {
  position: relative;
}

.arrow {
  position: absolute;
  right: 16px;
  display: block;
  width: 24px;
  height: auto;
  top: 29px;
  cursor: pointer;
  transition: 0.3s transform ease;
  &.opened {
    transform: rotate(180deg);
  }
  &.noClickable {
    cursor: default;
  }
}

.autoContainer {
  position: absolute;
  width: 100%;
  background-color: $white;
  box-shadow: $modalShadow;
  border-radius: 2px;
  z-index: 10;
  max-height: 176px;
  overflow-y: auto;
  padding: 4px 0;

  &.withError {
    margin-top: -22px;
  }
}

.option {
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 13px;
  transition: all 0.3s ease;
  &:hover {
    background-color: $white3;
  }
}

.emptyMessage {
  color: rgba(0, 0, 0, 0.55);
  text-align: center;

  span {
    color: #2f78cd;
    cursor: pointer;
  }
}
