@import "../../../UIKit/colorVars";

.authenticationLayout {
  width: 100%;
  height: 100vh;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-image: url(../../../assets/images/AuthBg.png);
  // background-repeat: no-repeat;
  // background-position: 3.96% 100%;
  font-family: "Roboto", sans-serif;
}
