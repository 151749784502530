.status {
  font-size: 12px;
  color: #fff;
  padding: 2px 5px;
  border-radius: 6px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 999;
  white-space: nowrap;
  width: fit-content;

  &.autoIndex {
    z-index: auto;
  }

  .statusList {
    position: absolute;
    z-index: 999;
    background: #FFFFFF;
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    padding: 4px 0;
    top: 120%;
    left: 0;

    .statusWrap {
      background: #fff;
      padding: 5px 12px;

      &:hover {
        background: #F3F3F3;
      }
    }
  }

  > .arrow {
    transition: 0.2s all ease;
    margin-left: 2px;
    width: 20px;
    height: 20px;

    path {
      fill: #fff;
      fill-opacity: 1;
    }

    &.opened {
      transition: 0.2s all ease;
      transform: rotate(180deg);
    }
  }
}