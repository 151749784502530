.inputWrap {
  .icon {
    position: absolute;
    right: 12px;
    width: 24px;
    height: 24px;
    bottom: 50%;
    transform: translateY(100%);
  }
}
