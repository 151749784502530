$height: 56px;
$visHeight: $height - 32px;
$openedTagsWidth: 325px;

.tagsWrap {
  width: 100%;
  min-width: 240px;
  height: $height;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  .tagsInlineWrap {
    cursor: default;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: calc(#{$visHeight});
    margin-top: calc((#{$height} - #{$visHeight}) / 2);
    overflow-y: hidden;
    &.opened {
      overflow-y: visible;
      background: white;
      border: 1px solid #005a9e;
      height: auto;
      min-height: 56px;
      z-index: 999;
      padding-left: 19px;
      padding-right: 9px;
      right: 0;
      margin-top: 0;
      padding-top: calc((#{$height} - #{$visHeight}) / 2);
      width: $openedTagsWidth;
      padding-bottom: 9px;
    }
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
  }
  .addForm {
    margin-bottom: 4px;
  }
  .addField {
    input {
      font-size: 14px;
      font-family: inherit;
      border: none;
      outline: none;
      line-height: 1;
      max-width: calc(#{$openedTagsWidth} - 38px);
      min-width: 100px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.48);
      }
    }
  }
  .searchedTagsWrap {
    position: absolute;
    width: $openedTagsWidth;
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    background: white;
    z-index: 999;
    padding: 12px 22px;
    cursor: default;
    ul {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;
      .searchTag {
        margin-right: 0;
        margin-bottom: 12px;
      }
      li:last-child {
        .searchTag {
          margin-bottom: 0;
        }
      }
    }
  }
  .emptySearch {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: rgba(0, 0, 0, 0.55);
    font-size: 14px;
    .createTag {
      color: rgba(#005a9e, 0.87);
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 300px;
    }
  }
  .colorPicker {
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    z-index: 999;
    background: white;
    padding: 12px 24px;
    position: absolute;
    right: 0;
    width: $openedTagsWidth;
    display: flex;
    span {
      border-radius: 2px;
      width: 16px;
      height: 16px;
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.detailState {
    height: 22px;
    .tag {
      margin-bottom: 2px;
    }

    .tagsInlineWrap {
      min-height: 22px;
      margin-top: 0;

      &.opened {
        margin-top: -6px;
        left: 0;
        padding: 8px;
        padding-bottom: 0;
        min-height: 39px;
        width: 100%;
        .tag {
          margin-bottom: 8px;
        }
      }
    }
    .searchedTagsWrap,
    .colorPicker {
      right: auto;
      left: 0;
    }
    .colorPicker {
      right: 0;
      width: 100%;
    }
  }
}
