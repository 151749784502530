@import "../../../../../UIKit/colorVars";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .container {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    word-wrap: break-word;
    padding: 40px 24px;
    background-color: $white;
    width: 500px;
    height: 271px;
    .img {
      margin-bottom: 40px;
    }
    .text {
      font-size: 18px;
      line-height: 150%;
      color: $textHeaders;
      width: 100%;
    }
  }
}
