@import "../../../../../landingUi/landingColors.scss";

.card {
  text-align: center;
  width: 519px;
  height: 432px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;
  &:nth-of-type(3),
  &:nth-of-type(4) {
    margin-bottom: 0;
  }
  @media (max-width: 899px) {
    margin-bottom: 40px;
    &:nth-of-type(3) {
      margin-bottom: 40px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    min-height: 472px;
    height: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.img {
  width: 240px;
  height: 240px;
  display: block;
  border-radius: 50%;
  margin-bottom: 24px;
}

.name {
  color: $title;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 10px;
  text-align: center;
}
.office {
  font-size: 20px;
  line-height: 150%;
  color: $textDark;
  margin-bottom: 10px;
  font-weight: 400;
}

.text {
  font-size: 18px;
  line-height: 150%;
  color: $textLigtht;
  margin-bottom: 10px;
  white-space: break-spaces;
}

.socials {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  margin-left: 5px;
  margin-right: 5px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .img {
    margin-left: auto;
    margin-right: auto;
  }
  .text {
    width: 100%;
  }
}
