.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  p {
    font-weight: 500;
    font-size: 21px;
  }

  .btnGroup {
    button:first-child {
      margin-right: 10px;
    }
  }
}