.globalContextTranslateMenu {
  position: fixed;
  padding: 4px 0;
  background: #FFFFFF;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  z-index: 999;

  .change {
    padding: 6px 12px;
    background: #FFFFFF;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    cursor: pointer;

    &:hover {
      background: #F3F3F3;
    }
  }
}