.savingsChartWrap {
  position: relative;
  margin-bottom: 20px;

  > div:first-child {
    margin-top: 0;
  }

  .years {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;

    & > div > div:first-child {
      background: transparent;
    }

    & > div > div:last-child {
      top: 40px;
    }
  }
}

.pieChartLoader {
  margin-bottom: 12px;
}