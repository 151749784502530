.content {;
  height: fit-content;
  max-height: 1700px;
  overflow: scroll;

  & > div ul {
    display: flex;
    justify-content: space-between;
  }
}

.activityInfo {
  margin-top: 18px;

  & > div {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 11px;
    }

    span {
      display: block;
      width: 50%;

      &:first-child {
        font-weight: 500;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
      }

      &:last-child {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
}