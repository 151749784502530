.approvalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  padding: 24px 20px 95px 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
}
