@import "../../../../../UIKit/colorVars";

.dropList {
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
}

.deleteBtn {
  position: absolute;
  top: 4px;
  right: 4px;
  display: none;
  cursor: pointer;
}

.listItem {
  text-align: left;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  height: 56px;
  padding: 18px 36px 18px 12px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.listItem:hover {
  background-color: $white2;
  .deleteBtn {
    display: block;
  }
}

.linkItem {
  margin-top: auto;
  text-align: left;
  padding: 6px 12px;
}

.notReaded {
  font-weight: 500;
}
.data {
  color: inherit;
  text-decoration: none;
}
