.table tbody tr {
  td {
    &:first-child {
      width: 20%;
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      width: 14%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    
  }
}
.logoSubSupplier{
    display: flex;
    align-items: center;
}
