@import "../../../UIKit/colorVars";

.overlay {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 99999;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow-y: auto;
}

.popup {
  background: $white;
  box-shadow: $tableShadow;
  border-radius: 8px;
  width: 640px;
  min-height: 260px;
  padding: 16px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.dialogPopup {
  width: 480px;
}

.errorPopup {
  width: 388px;
  min-height: unset;
}

.header {
  display: flex;
  justify-content: space-between;
}

.titleWrap {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &.dialogTitle {
    margin-bottom: 24px;
  }

  > svg {
    margin-right: 5px;
  }
}

.title {
  font-weight: 500;
  font-size: 21px;
}

.text {
  font-size: 14px;
  margin-bottom: 24px;

  &.deletePopup {
    font-size: 18px;
    white-space: break-spaces;
    text-overflow: clip;
  }

  &.fullText {
    word-break: break-word;
    white-space: break-spaces;
  }
}

.dialogText {
  margin-bottom: 0;
}

.buttonsBlock {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .buttonsBlock {
    margin-top: 20px;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}

.button {
  min-width: 75px !important;
  margin-left: 8px;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 34px;
  font-weight: 300;

  path {
    fill: $darkGrey;
  }

  &:hover {
    path {
      fill: #a19f9d;
    }
  }
}

.wrapChildren {
  width: 100%;
}
