.formCont {
  .answCont {
    display: grid;
    grid-template-columns: 0.3fr 0.25fr 0.25fr 0.15fr 0.1fr;
    grid-column-gap: 20px;
    margin-bottom: 10px;
    .firstDiv {
      margin-left: 10px;
    }
  }
  .addNew {
    cursor: pointer;
    margin-left: 10px;
  }
}
