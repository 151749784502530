.rows {
  margin: 0;
  margin-top: 50px;
  list-style: none;
  padding-bottom: 32px;
  li {
    display: flex;
    justify-content: space-between;
    &:not(:first-child) {
      margin-top: 24px;
    }
    &:first-child .text {
      width: 56%;
    }
    &:nth-child(2) .text {
      width: 52%;
    }
    &:nth-child(3) .text {
      width: 48%;
    }
    &:nth-child(4) .text {
      width: 44%;
    }
  }
  &.large {
    li .text {
      width: 100%;
    }
  }
}
