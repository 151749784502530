@import "../../../UIKit/index";


*,
*::before,
*::after {
  
  font-family: 'Roboto', sans-serif;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 16px;
  margin-top: 0;
}

.labelInputEmail {
  margin-top: 0;
  margin-bottom: 16px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inputWrap {
  margin-bottom: 16px;
}

.buttonWrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 48px;
}

.cancelButton {
  margin-right: 4px;
}

.cancelLink {
  color: rgba(0, 0, 0, 0.9);
    background-color: $buttonSecondary;
    &:hover {
      background-color: $buttonSecondaryHover;
      color: rgba(0, 0, 0, 0.9) !important;
    }
    &:active {
      background-color: $buttonSecondaryActive;
      color: rgba(0, 0, 0, 0.9) !important;
    }
}