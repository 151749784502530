@import "../../landingUi/landingMain.scss";

.pricingPage {
  margin-top: 64px;
  padding-bottom: 100px;
  @media (max-width: 1199px) {
    padding-bottom: 80px;
  }
}

.topBlock {
  background-color: #fcfaf8;
  padding-top: 100px;
  padding-bottom: 56px;
  @media (max-width: 1199px) {
    padding-top: 80px;
  }
}

.title {
  color: $title;
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  line-height: 110%;
  margin-bottom: 16px;
}

.text {
  font-size: 20px;
  line-height: 150%;
  color: $textLigtht;
  margin-bottom: 64px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  @media (max-width: 1199px) {
    margin-bottom: 48px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 1199px) {
    flex-direction: column;
  }
}

.content {
  max-width: 519px;
  @media (max-width: 1199px) {
    // align-self: start;
    max-width: 100%;
    margin-bottom: 24px;
    text-align: center;
  }
  @media (max-width: 767px) {
    margin-bottom: 33px;
  }
}

.contentTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  color: $title;
  margin-bottom: 24px;
}

.contentText {
  font-size: 18px;
  line-height: 150%;
  color: $textLigtht;
  max-width: 481px;
  @media (max-width: 1199px) {
    max-width: 100%;
    text-align: center;
  }
}

.imgBlock {
  box-shadow: $shadow;
  width: 628px;
  height: 366px;
  background-image: url("../../assets/img/modules/PPMimg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 1199px) {
    max-width: 720px;
    width: 100%;
    height: 420px;
    background-image: url("../../assets/img/modules/PPMimgTablet.jpg");
    align-self: start;
  }

  @media (max-width: 767px) {
    height: 192px;
    max-width: 328px;
    background-image: url("../../assets/img/modules/PPMimgMobile.jpg");
  }
}

.subTitle {
  color: $title;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  margin-top: 100px;
  margin-bottom: 16px;
  @media (max-width: 1199px) {
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    margin-top: 0;
  }
}

.contactBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.7);
}
.link {
  text-decoration: underline;
  color: #2f78cd;
  margin-right: 4px;
  font-size: 20px;
  line-height: 150%;
}
