.wrap {
  width: 100%;
  overflow-y: auto;
  padding: 24px 20px 95px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: 500;
      margin-bottom: 20px;
    }

    .btnGroup {
      .defaultBtn {
        border-radius: 2px;
        padding: 6px 25px;
        font-size: 14px;
        text-transform: capitalize;

        &.cancel {
          background: #E5E5E5;
          color: rgba(0, 0, 0, 0.9);
          margin-right: 8px;
        }

        &.blue {
          color: #F5F5F5;
          background: #2F78CD;
        }
      }
    }
  }

  .tableWrap {
    width: 100%;
    margin-top: 40px;
  }
}

.content {
  margin-top: 24px;
}