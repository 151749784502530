.wrap{
    margin: 17px;
}
.wrapTitle{
    margin-bottom: 23px;
}

.wrapTextAttach{
    background-color: #F8F8F8;
    padding: 15px;
}
.actualSavingDiscription{
    white-space: break-spaces;
}

.popupBtnsWrap{
    margin-top: 30px;
    text-align: end;
}

.notesOwner{
    color:rgba(0, 0, 0, 0.55);
    margin-bottom: 8px;
}
.actualSavingAttachment{
    margin-bottom: 34px;
}
hr{
    border: none;
    background-color: rgba(198, 198, 198, 1);
    color: rgba(198, 198, 198, 1);
    height: 1px;
}
.attach{
    display: flex;
    cursor: pointer;
    li {
        margin: 8px
    }
}