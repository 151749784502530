.groupedBySelector {
  position: relative;

  .selector {
    background: #F3F3F3;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 7px;

    &:hover {
      background: #EAEAEA;
    }

    span {
      &:nth-child(2) {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.55);
      }
    }
  }

  .selectorList {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    padding: 2px 0;
    z-index: 9;
    overflow-y: scroll;
    max-height: 200px;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      padding: 5px 12px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      width: 100%;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        background: #F3F3F3;
      }
    }
  }
}