.detailPanel {
  padding: 20px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #EAEAEA;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .editBtn {
    cursor: pointer;
  }
}