.wrap {
  display: flex;
  justify-content: space-between;
  .bars {
    display: flex;
  }
  .bar:not(:first-child) {
    margin-left: 20px;
  }
}
