@import "../../../../landingUi/landingColors.scss";

.blogCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: 1260px) {
    flex-direction: column;
    margin-bottom: 80px;
  }
}

.imgBlock {
  @media (max-width: 1260px) {
    width: 100%;
    margin-bottom: 24px;
    img {
      width: 100%;
    }
  }
}

.contentBlock {
  display: flex;
  flex-direction: column;
  max-width: 543px;
  @media (max-width: 1260px) {
    max-width: 100%;
  }
}

.authorBlock {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.pic {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 16px;
}

.name {
  font-size: 14px;
  line-height: 150%;
  color: $textDark;
}

.date {
  font-size: 14px;
  line-height: 150%;
  color: $textLigtht;
  font-weight: 500;
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  color: $title;
  margin-bottom: 16px;
}

.descr {
  font-size: 18px;
  line-height: 150%;
  color: $textLigtht;
  /*for ...*/
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  max-height: 135px;
  white-space: break-spaces;
}




