@import "../../../UIKit/colorVars";
.wrapper {
  position: relative;
  display: flex;
  font-weight: 500;
  font-size: 21px;
  line-height: 1.5;
  color: $textDark;
  margin-bottom: 13px;
  &:first-child {
    margin-left: -8px;
  }
  & > .container {
    display: flex;
    overflow-x: hidden;
    & > .item {
      padding: 3px 8px;
      height: 32px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:not(:last-child) {
        cursor: pointer;
        border-radius: 2px;
        color: $textLight;
        &:hover {
          background: #e5e5e5;
        }
      }
    }

    & > span {
      margin: 0 8px;
      display: flex;
      align-items: center;
      color: $textLight;
    }
  }
  .tooltip {
    position: absolute;
    padding: 7px 12px;
    background: #333333;
    border-radius: 2px;
    height: 35px;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: $white;
  }
}
