@import "UIKit/_colorVars.scss";

.openedList {
  min-height: 245px;
  max-height: 271px;
  height: auto !important;
  .clearBtn {
    color: $textDark;
    font-weight: 600;
    display: flex;
    svg {
      margin-right: 4px;
      path {
        fill: $textDark;
      }
    }
    &.disabled {
      color: #9e9e9e;
      cursor: default;
      svg path {
        fill: #9e9e9e;
      }
    }
  }
}
.expiresInWrap {
  min-height: 185px;
  max-height: 214px;
  height: auto;
  overflow-y: auto;
  padding-bottom: 14px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  .item {
    height: 32px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: rgba(#f3f3f3, .5);
    }
    &.active {
      background: #f3f3f3;
    }
  }
}

.clearBlock {
  padding: 10px 12px;

  & > button {
    padding: 0;
  }
}