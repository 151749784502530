.timeSpentWrap {
  background: #FFFFFF;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02),
  0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  display: flex;

  > div {
    &:first-child {
      width: 73.3%;
      border-right: 1px dashed rgba(0, 0, 0, 0.55);
      padding-right: 20px;
    }

    &:last-child {
      width: 26.7%;
      padding-left: 20px;
    }
  }
}