@import "../../../../../UIKit/colorVars";

.container {
  padding: 5px 11px 9px 11px;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: $textDark;
  max-width: 100%;

  p {
    max-width: 100%;
    width: 100%;
    word-break: break-word;
    word-wrap: break-word;
  }

  // &:hover {
  //   background: $white3;
  // }
}
