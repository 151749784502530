.wrapper {
  margin-top: 40px;
  .table {
    padding-top: 0px !important;
    overflow-x: auto !important;
    * {
      white-space: nowrap;
    }
    thead:first-child {

      display: table-caption !important;

      span {
        display: none;
      }
    }
  }
}
.tableContainer{
     padding:0;
     box-shadow: 0 0px 0px;
}
