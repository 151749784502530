@import "../../../../../landingUi/landingColors.scss";

.slide {
	width: 100%;
	background-color: #ffffff;
	border-radius: 4px;
	height: 344px;
	padding-left: 140px;
	display: flex;
	@media (max-width: 1199px) {
		padding-left: 16px;
		padding-right: 16px;
	}
	@media (max-width: 767px) {
		flex-direction: column;
		align-items: center;
		min-height: 660px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

.wrapImg {
	@media (max-width: 1199px) {
		width: 180px;
		height: 182px;
		margin: auto;
		img {
			width: 100%;
			height: auto;
		}
	}
	@media (max-width: 767px) {
		margin: 0;
		margin-bottom: 16px;
	}
}

.cardContent {
	margin-left: 140px;
	padding-top: 40px;
	padding-bottom: 40px;
	@media (max-width: 1199px) {
		padding-top: 32px;
		padding-bottom: 32px;
		margin-left: 24px;
	}
}

.list {
	list-style: none;
}

.title {
	color: $title;
	font-weight: bold;
	font-size: 24px;
	line-height: 120%;
	margin-bottom: 26px;
	@media (max-width: 767px) {
		font-size: 20px;
		margin-bottom: 24px;
	}
}

.item {
	margin-bottom: 12px;
	&:last-of-type {
		margin-bottom: 0;
	}
}
