.milestone {
  height: 56px;
  border-right: none;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  position: relative;
  margin-left: 2px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  &.withEmpty, &.first {
    margin-left: 0;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    max-width: 100%;
    color: #FFFFFF;
    font-size: 12px;
    display: block;
  }

  .timeSpend {
    display: flex;
    align-items: center;
    color: #fff;
    .count {
      font-size: 12px;
      margin-right: 4.5px;
    }
  }

  .threeDots {
    display: none;
  }

  &.small {
    cursor: pointer;

    .threeDots {
      display: block;
      text-align: center;
      width: 100%;
    }

    .content {
      position: absolute;
      left: 75%;
      top: -30%;
      z-index: 10;
      background: #333333;
      border-radius: 2px;
      padding: 7px 12px;
      text-align: center;
      color: white;
      width: auto;
      display: none;

      .name {
        font-size: 14px;
        display: inline-block;
        width: auto;
      }

      .timeSpend {
        font-size: 14px;

        .count {
          background: transparent;
          font-size: 14px;
          color: white;
          border: none;
        }

        &::before {
          content: "(";
        }

        &::after {
          content: ")";
        }
      }
    }

    &:hover {
      .content {
        display: flex;
      }
    }

    .divider:hover {
      & + .content {
        display: none;
      }
    }
  }

  .divider {
    position: absolute;
    bottom: -6px;
    top: -6px;
    z-index: 5;
    border-right: 2px solid #2F78CD;
    height: 68px;
    cursor: col-resize;

    &.left {
      left: -2px
    }

    &.right {
      right: -2px;

    }

    &::before, &::after {
      content: "";
      position: absolute;
      background: #2F78CD;
      height: 2px;
      width: 10px;
      left: -4px;
    }

    &::before {
      top: -2px;
    }

    &::after {
      bottom: -2px;
    }

    .tooltip {
      position: absolute;
      z-index: 99;
      bottom: calc(100% + 6px);
      background-color: white;
      padding: 3px 8px;
      border: 1px solid #C8C8C8;
      border-radius: 1px;
      width: 92px;
      left: -45px;
      display: none;

      &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: calc(50% - 4px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 3px 4px 0 4px;
        border-color: #ffffff transparent transparent transparent;
        z-index: 2;
      }

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: calc(50% - 5px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 5px 0 5px;
        border-color: #C8C8C8 transparent transparent transparent;
      }
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }
}

.emptyBlock {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EAEAEA;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;

  &.small {
    .content {
      position: absolute;
      left: 75%;
      top: -30%;
      z-index: 10;
      background: #333333;
      border-radius: 2px;
      padding: 7px 12px;
      text-align: center;
      color: white;
      width: 100px;
      display: none;

      .label {
        font-size: 14px;
        display: inline-block;
        width: auto;
        color: white;
      }

      .daysCount {
        font-size: 14px;
        background: transparent;
        color: white;
        border: none;
        display: block;
      }
    }

    &:hover {
      .content {
        display: block;
      }
    }
  }

  span {
    display: block;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    font-size: 12px;
  }
}