.labelOptional {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);

  span:last-child {
    color: rgba(0, 0, 0, 0.55);
  }
}

.label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}