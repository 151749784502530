.itemWrap {
  padding: 5px 12px;
  cursor: pointer;

  &:hover {
    background: #F3F3F3;
  }
}

.priority {
  border-radius: 4px;
  width: fit-content;
  padding: 2px 8px;
  font-size: 12px;
  color: #FFFFFF;
}