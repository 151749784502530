.container {
  padding: 24px 20px 95px;
  width: 100%;
  overflow-y: auto;
  .wrapper {
    margin-bottom: 20px;
    .title {
      font-weight: 500;
      font-size: 21px;
    }
  }
}
