@import "../../../UIKit/colorVars";

.projectContainer {
  padding: 24px 20px 0;
  width: 100%;
  overflow-y: auto;
}

.projectControl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title {
  font-weight: 500;
  font-size: 21px;
  .titleID{
     color: rgba(0, 0, 0, 0.55);
  }
}
