@import "../../../UIKit/colorVars";

.savingsChart {
  margin-top: 24px;
  background: $white;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 20px;
  width: 100%;

  .typeNamesWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 23px;

    .typeName {
      display: flex;
      align-items: center;
      z-index: 9;
      height: 30px;
      color: rgba(0, 0, 0, 0.55);
      font-size: 12px;
    }
  }

  .titleWrap {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .subtitle {
    font-weight: 500;
  }

  .viewDetails {
    font-weight: normal;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.55);
    margin-left: 7px;
    cursor: pointer;
  }

  .inlineWrap {
    display: flex;
    width: 100%;
  }

  .quarter {
    width: 20%;
    display: flex;
    justify-content: center;
  }

  .indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    list-style-type: none;
  }

  .indicator {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-left: 16px;
    }

    .circle {
      display: block;
      width: 10px;
      height: 10px;
      margin-right: 8px;
      border-radius: 50%;
    }

    .title {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .hint {
    min-width: 185px;
    width: auto;
    padding: 3px 8px;
    border: 1px solid #e5e5e5;
    border-radius: 1px;
    display: inline-block;
    position: fixed;
    z-index: 999;
    background: white;
    transform: translate(-50%, calc(-100% - 8px));

    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: calc(50% - 5px);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #ffffff transparent transparent transparent;
      z-index: 2;
    }

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: calc(50% - 6px);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: #e5e5e5 transparent transparent transparent;
      z-index: 1;
    }

    span {
      display: block;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
      line-height: 1.5;
    }

    .title {
      font-size: 14px;
    }

    .value {
      font-size: 12px;
      font-weight: 500;
    }
  }
}