@import "../../../UIKit/colorVars";
.selectionField,
.savingTypesDropdown {
  margin-bottom: 16px;
}
.error {
  color: #a4262c;
  font-size: 12px;
  display: block;
  margin-bottom: 16px;
  margin-top: -12px;
  margin-right: auto;
}
.inputWrap {
  margin: 16px 0;
  position: relative;
}
.countField {
  margin-bottom: -2px;
  &.countWrap {
    margin-bottom: 16px;
  }
  textarea{
    height: 56px;
  }
}
