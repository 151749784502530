.widgetList {

  & > li {
    list-style-type: none;
    padding: 3.5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      text-transform: capitalize;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
    }

    .value {
      border-radius: 6px;
      padding: 2px 8px;
      font-size: 12px;
      color: #fff;

      &.green {
        background: #63A5A1;
      }

      &.yellow {
        background: #F2CD7B;
      }
    }
  }
}
