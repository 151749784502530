@import "../../landingUi/landingMain.scss";

.privacyPage {
	margin-top: 64px;
	padding-top: 100px;
	padding-bottom: 100px;
	@media (max-width: 1339px) {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	.landingContainer {
		max-width: 760px;
	}

	.title {
		color: $textDark;
		font-weight: bold;
		font-size: 36px;
		line-height: 110%;
		margin-bottom: 40px;
		@media (max-width: 1767px) {
			font-size: 24px;
			margin-bottom: 50px;
		}
	}

	.subtext {
		font-size: 16px;
		line-height: 150%;
		margin-bottom: 34px;
	}

	.text {
		font-size: 18px;
		line-height: 150%;
		color: $textDark;
		margin-bottom: 34px;
		&:last-of-type {
			margin-bottom: 0;
		}
		span {
			font-weight: 600;
			font-style: normal;
		}
	}

	.subtitle {
		margin-bottom: 34px;
		font-weight: bold;
		font-size: 24px;
		line-height: 120%;
		color: $textDark;
		@media (max-width: 767px) {
			font-size: 20px;
		}
	}

	.link {
		color: $blue;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}

	.content {
		font-weight: normal;
		font-size: 18px;
		line-height: 150%;
		color: $textDark;
		margin-bottom: 48px;
	}

	.contentTitle {
		margin-bottom: 40px;
	}

	.contentList {
		margin: 0;
		padding: 0;
		padding-left: 18px;
		li a {
			text-decoration: none;
			color: inherit;
		}
	}

	.pointBlock {
		margin-bottom: 34px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	table {
		margin-top: 48px;
		width: 100%;
		border: 1px solid grey;
		border-collapse: collapse;
		font-size: 16px;
		line-height: 150%;
		color: rgba(0, 0, 0, 0.9);
	}

	th {
		border: 1px solid rgba(0, 0, 0, 0.9);
		text-align: left;
		padding: 8px;
	}

	td {
		border: 1px solid rgba(0, 0, 0, 0.9);
		text-align: left;
		padding: 8px;
		padding-bottom: 16px;
		vertical-align: top;
		&.firstCol {
			width: 190px;
		}
		&.lastCol {
			font-weight: 600;
		}
	}
}
