.chart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 99999;
  background: white;
  .text {
    align-self: flex-start;
  }
  .circle {
    margin-bottom: 20px;
  }
}
