@import "../../../UIKit/index";

.container {
  padding: 12px 20px 0;
  width: 100%;
  overflow-y: auto;

  .title {
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 9px;
  }

  & > div {
    & > div {
      & > div {
        height: 100%;
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 95px;
  }
}
