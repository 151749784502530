.columnWidget {
  position: relative;
  display: flex;
  min-height: 180px;
  align-items: flex-end;
  justify-content: flex-start;
  .columns {
    display: flex;
    align-items: flex-end;
    z-index: 2;
    position: relative;
    padding-left: 32px;
    padding-right: 16px;
  }
  .column {
    width: 77px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .col {
      width: 27px;
      display: block;
    }
    .label {
      display: block;
      height: 18px;
      text-align: center;
      color: rgba(0, 0, 0, 0.55);
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 72px;
      margin-top: 8px;
      text-transform: capitalize;
    }
  }
  .percents {
    position: absolute;
    left: 0;
    //top: 0;
    right: 0;
    bottom: calc(18px / 2 + 8px);
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .percentWrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 18px;
      &:not(:first-child) {
        margin-top: 9px;
      }
    }
    .line {
      height: 2px;
      width: calc(100% - 20px);
      border-bottom: 1px dashed #ecf0f4;
      margin-top: -1px;
    }
    .percent {
      color: rgba(0, 0, 0, 0.55);
      font-size: 12px;
      text-align: right;
      width: 32px;
    }
  }
}
