.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 20px 20px;
  overflow-y: auto;

  h2 {
    font-weight: 500;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 20px;
  }
}