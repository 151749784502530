.status {
  padding: 2px 8px;
  border-radius: 6px;
  font-size: 12px;
  color: #FFFFFF;
  position: relative;

  .tooltip {
    top: -60px;
  }

  &.in_progress {
    background: #5B79B4;
    cursor: pointer;
  }

  &.pending {
    background: #F2CD7B;
  }

  &.completed {
    background: #83A755;
  }

  &.rejected {
    background: #DE4935;
  }
}

.note {
  cursor: pointer;

  &.disabled {
    cursor: default;

    path {
      fill: #C8C8C8
    }
  }
}

.progressBar {
  width: 140px;
  background: #EAEAEA;
  border-radius: 3px;
  height: 6px;
  cursor: pointer;
  position: relative;

  .done {
    background: #83A755;
    height: 100%;
    border-radius: 3px;
  }
}

.tooltip {
  position: absolute;
  padding: 7px;
  background: #333333;
  border-radius: 2px;
  font-size: 14px;
  color: #FFFFFF;
  top: -40px;
  left: 0;
}

.noData {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.55);
  opacity: 0.87;
}

.request {
  display: flex;
  flex-direction: column;

  .requestType {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.55);
  }
}

.daysPending {
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
    width: 15px;
    height: 15px;
  }
}

.inlineWrap{
     transform: rotateX(180deg);
     display: flex;
     flex-direction: column;
     padding-bottom: 4px;
     max-height: calc(100vh - 245px);
     overflow-y: scroll;
}