$white: #ffffff;
$white2: #f5f4f6;
$white3: #f3f3f3;
$white4: #f5f5f5;
$bgColor: #fbfbfb;
$grey: #f0f0f0;
$grey1: #e9e9e9;
$gray2: #c4c4c4;
$darkGrey: #c8c8c8;
$layoutBg: #f8f8f8;
$menuItem: #eaeaea;
$textLight: #767676;
$textDark: #212121;
$dark2: #201f1e;
$textHeaders: #333333;
$button: #2f78cd;
$buttonHover: #2867af;
$buttonActive: #215d96;
$buttonSecondary: #e5e5e5;
$buttonSecondaryHover: #dfdfdf;
$buttonSecondaryActive: #d3d3d3;
$checkbox: #4f78e0;
$checkboxHover: #4062ba;
$link: #005a9e;
$linkHover: #005798;
$darkBlue: #607d8b;

$devider: #eeeeee;
$tableShadow: 0px 4px 5px rgba(0, 0, 0, 0.12);
$modalShadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
$cardShadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.11), 0px -1px 10px rgba(0, 0, 0, 0.02),
	0px -0.5px 4px rgba(0, 0, 0, 0.05);
