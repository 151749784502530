@import "../../../UIKit/colorVars";

.addAttachmentForm {
  .dropzone {
    background: $layoutBg;
    border: 1px dashed $darkGrey;
    height: 215px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    .title {
      display: block;
      margin-top: 24px;
      color: rgba(0, 0, 0, 0.9);
      font-size: 14px;
      text-align: center;
      .bold {
        font-weight: 500;
      }
      .docTypes {
        display: block;
      }
    }
    &.error {
      background: #fff4f5;
      border: 1px dashed #a4262c;
      .message {
        color: rgba(0, 0, 0, 0.55);
        font-size: 14px;
        margin-top: 16px;
        text-align: center;
        margin-bottom: 38px;
        span {
          display: block;
        }
      }
      .link {
        color: rgba(0, 0, 0, 0.9);
        font-size: 14px;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
  .fileNameField {
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .uploadedFile {
    display: flex;
    align-items: center;
    .fileName {
      color: rgba(0, 0, 0, 0.9);
      font-size: 14px;
      margin-left: 10px;
      margin-right: 5px;
      display: block;
    }
    .deleteFile {
      cursor: pointer;
    }
  }
}
