@import "../../../UIKit/index";

.tableWrapper {
  width: 100%;
  box-shadow: $modalShadow;
  min-height: 50px;
  background-color: $white;
  border-radius: 2px;
}

.tabelWrapperTitle {
  padding: 16px 17px 6px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.countTitle {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.count {
  margin-left: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 24px;
  min-width: 24px;
  background-color: $grey;
  padding: 0 4px;
  font-weight: normal;
  font-size: 11px;
}

//for table

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  thead tr th {
    height: 45px;
    padding: 13.5px 0;
    &:first-child {
      padding-left: 22px;
    }
  }

  tbody tr {
    height: 56px;
    transition: all 0.3s ease;
    &:hover {
      background-color: $white3;
    }

    td {
      font-size: 14px;
      line-height: 20px;
      padding: 18px 0;
      &:first-child {
        padding-left: 22px;
      }
    }
  }

  .tableRow {
    height: 56px;
    transition: all 0.3s ease;
    &:hover {
      background-color: $white3;
    }
    td {
      font-size: 14px;
      line-height: 20px;
      opacity: 0.87;
      padding: 18px 0;
      &.firstNameTable {
        padding-left: 22px;
      }
    }
  }
}
