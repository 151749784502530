.singleTask {
  background: #FFFFFF;
  padding: 17.5px 24px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgba(0, 0, 0, 0.9);

  &:hover {
    background: #F3F3F3;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #EAEAEA;
  }

  > div {
    &:nth-child(1) {
      width: 40%;
      color: #005A9E;
    }

    &:nth-child(2) {
      width: 19%;
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &:nth-child(3) {
      width: 19%;
    }

    &:nth-child(4) {
      width: 19%;
    }

    &:nth-child(5) {
      width: 3%;
      display: flex;
      justify-content: end;
    }
  }
}