@import "../../../UIKit/index";

$width: 200px;
.container {
  padding: 24px 20px 0;
  width: 100%;
  overflow-y: auto;
  .title {
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 20px;
  }
  .detail {
    padding-top: 20px;
    div {
      div:nth-child(1) {
        width: $width !important;
      }
      div:nth-child(2) {
        width: calc(100% - #{$width}) !important;
      }
    }
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .container {
    margin-bottom: 95px;
  }
}
