@import "../../../landingUi/landingColors.scss";

.articleCard {
  width: 411px;
  height: 461px;
  background-color: $white;
  box-shadow: -2px 0px 1px rgba(0, 0, 0, 0.05), 1px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  &:hover {
    box-shadow: $shadow;
  }
  @media (max-width: 1339px) {
    width: calc(50% - 12px);
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.cardImg {
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  @media (max-width: 1339px) {
    height: 264px;
  }
}

.content {
  padding-left: 16px;
  padding-right: 16px;
}

.title {
  color: $title;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
}
