.accordeonWrap {
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.opened {
    margin-bottom: 0;
  }

  .header {
    width: 100%;
    background: #e9e9e9;
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 24px;

    &.center {
      justify-content: center;
    }

    &.left {
      justify-content: start;
    }

    .title,
    .arrow {
      opacity: 0.87;
    }

    .title {
      font-size: 12px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
    }

    .arrow {
      margin-left: 4px;
      cursor: pointer;
      transition: 0.2s all ease;

      &.opened {
        transition: 0.2s all ease;
        transform: rotate(180deg);
      }

      path {
        fill: rgba(0, 0, 0, 0.9);
      }
    }
  }
}
