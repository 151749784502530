.taskHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .titleWrap {
    display: flex;
    align-items: center;

    .title {
      font-weight: 500;
      font-size: 21px;
      margin-right: 8px;
    }
  }
}