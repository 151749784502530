@import "../../../UIKit/index";

.container {
  min-width: 540px;
  width: 100%;
  height: 100%;
  padding-bottom: 27px;

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
  }
  .innerContainer {
    height: 100%;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ecf0f4;
    padding-left: 13px;
    margin-right: 48px;
    position: relative;
    margin-left: 55px;
    &::after{
      content: "";
      position: absolute;
      bottom: -1px;
      left: calc(100% + 1px);
      height: 1px;
      width: 48px;
      border-bottom: 1px dashed #ecf0f4;
    }
    .square {
      position: relative;
      width: 20%;
      border-left: 1px dashed #ecf0f4;
      &:after {
        position: absolute;
        bottom: -32px;
        left: -8px;
        content: attr(data-pct);
        font-size: 11px;
        line-height: 16px;
        color: #c0c7ce;
      }
      &:first-child {
        &:after {
          left: -4px;
        }
      }
      &:last-child {
        border-right: 1px dashed #ecf0f4;
        &:before {
          position: absolute;
          bottom: -32px;
          right: -16px;
          content: "100%";
          font-size: 11px;
          line-height: 16px;
          color: #c0c7ce;
        }
      }
    }
  }
  .valuesContainer {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 8px;
    padding-top: 32px;
    width: calc(100% - 48px);
    .lineWrap{
      display: block;
      width: calc(100% - 68px);
      position: absolute;
      left: 68px;
      height: 16px;
      right: 0;
    }
    .value {
      position: relative;
      display: flex;
      p {
        font-size: 12px;
        line-height: 150%;
        color: $textLight;
        text-align: right;
        width: 55px;
      }
      span:after {
        position: absolute;
        top: 2px;
        z-index: 10;
        left: 0;
        content: "";
        height: 12px;
        width: var(--percent);
        background-color: var(--color);
      }
    }
  }
}
