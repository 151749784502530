.form {
  background: #F8F8F8;
  padding: 23px 17px;

  .attachments {
    margin-top: 10px;
  }

  .btns {
    margin-top: 24px;
    display: flex;
    justify-content: end;

    button:first-child {
      margin-left: 0;
    }
  }
}
