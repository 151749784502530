@import "../../../../../UIKit/colorVars";

.wrapper {
  background-color: $white;
  padding: 20px;
  .title {
    font-weight: 500;
    margin-bottom: 32px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    .wrap {
      position: relative;
      width: 100%;
      align-self: flex-end;
      .lineWrap {
        height: 5px;
        background-color: #b3cee2;
        border-radius: 4px;

        .line {
          height: 5px;
          border-radius: 4px;
          background-color: #005a9e;
        }
      }
      .numbers {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: $textLight;
        font-weight: 400;
        span:last-child {
          .iconWrap {
            &::after {
              left: 2px;
            }
            .icon {
              left: -6px !important;
            }
          }
        }
        .iconWrap {
          position: relative;
          display: none;

          &.showIcon {
            display: block;
          }
          .icon {
            position: absolute;
            top: -58px;
            left: -12px;
          }
          &::after {
            position: absolute;
            top: -49px;
            content: attr(data-rate);
            font-weight: normal;
            font-size: 12px;
            color: $white;
          }
        }
        .number {
          cursor: pointer;
        }
        .notAllowed {
          cursor: not-allowed;
        }
      }
    }
    .text {
      &:first-child {
        margin-right: 45px;
      }
      &:last-child {
        margin-left: 45px;
      }
    }
  }
}
