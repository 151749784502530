.ActualSavingsIcons {
  cursor: pointer;

  path {
    display: none;
    position:absolute;
    width: 25px;
    height: 30px;
    top: 115px;
    right: 7px;

  }
}

.ActualSavingsIconsVector {
  cursor: pointer;
}

.yearStyle {
  td {
    background: rgba(224, 235, 247, 1) !important;
  }
}

.tableContainer{
     padding:0;
     box-shadow: none;
     thead tr th:last-child {
             div{
                 display: flex;
                 top: 8px;
             }
         }
         box-shadow: none !important;
}
.tableContainerEmpty {
    background: #eaeaea;
    padding:0;
    box-shadow: none;
    thead tr th:last-child {
            div{
                display: flex;
                top: 8px;
            }
        }
        box-shadow: none !important;
}
.actualSavingsMonthTableStyle{
        margin-bottom: 100px;
}
