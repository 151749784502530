@import "../../../UIKit/colorVars";
$itemHeight: 56px;
$tableHeads: 45px + 50px;

.wrap {
  &.scroll {
    max-height: calc(#{$itemHeight} * 10 + #{$tableHeads});
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.tableContainer {
  padding: 4px 0;
  position: relative;
  background-color: white;
  overflow-x: initial !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.01);
  .totalCounter {
    border-bottom: none;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
    line-height: 1;
    span {
      margin-left: 4px;
      color: rgba(0, 0, 0, 0.9);
      font-size: 11px;
      line-height: 1;
      border-radius: 50%;
      background: $grey;
      min-width: 24px;
      padding: 6px 8px;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
    }
  }
  .title {
    color: rgba(0, 0, 0, 0.47);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    border-bottom: 1px solid #eaeaea;
    &.col1 {
      padding-right: 10%;
      width: 33.333333%;
    }
    &.col2 {
      padding-right: 10%;
      width: 33.333333%;
    }
  }
  .tableRow {
    height: $itemHeight;
    .tableCell {
      word-break: break-all;
      border-bottom: 1px solid #eaeaea;
      font-size: 14px;
      color: $textDark;
    }

    &:last-child {
      .tableCell {
        border-bottom: none;
      }
    }

    &:hover {
      background-color: $white3;
    }
  }
}

.mainHead {
  th {
    height: 50px;
    padding: 20px;
    padding-bottom: 6px;
    padding-top: 19px;
    &:last-child {
      padding-bottom: 1px;
      padding-top: 16px;
    }
  }
}
.secondHead {
  th {
    height: 45px;
    padding: 0;
    padding-left: 22px;
  }
}
.tableBody {
  td {
    padding-left: 22px;
  }
}

.buttonWrap {
  justify-content: flex-end;
  display: none;
  height: 100%;
  align-items: flex-end;
  &.show {
    display: flex;
  }
  .button {
    height: 32px !important;
    .wrapIcon {
      display: flex;
      align-items: center;
      padding: 6px 13px;
      .deleteIcon {
        margin-right: 10px;
      }
    }

    &:first-child {
      margin-right: 16px;
      width: 92px !important;
    }
    &:last-child {
      margin-right: 4px;
    }
  }
}

.checkboxWrap {
  display: flex;
  align-items: center;
  .checkbox {
    margin-right: 8px;
  }
}

.selected {
  background-color: #deecf9;
}

.readed {
  .tableCell:first-child {
    font-weight: 500;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 19px 20px 0;
}

.message {
  color: inherit;
  text-decoration: none;
}
