.spendTMTChart{
  background: #FFFFFF;
  border-radius: 4px;
  &.onlyColumns{
    padding-top: 32px;
  }
  .title{
    margin-bottom: 67px;
  }
  .columns{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .column:not(:first-child) {
      margin-left: 16px;
    }
  }
  .indicators{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .indicator:not(:first-child) {
      margin-left: 20px;
    }
  }
}
