.hyperlinksWrap {
  .addBtn {
    display: flex;
    align-items: center;
    color: #757575;
    padding: 0;

    &:hover {
      opacity: .8;
    }
  }

  .hyperlinks {
    max-height: 250px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .hyperlink {
      display: flex;
      align-items: center;
      gap: 15px;
      width: 100%;
      margin-bottom: 10px;

      > svg {
        cursor: pointer;
        min-width: 14px;
        position: relative;
        top: 0;
      }

      > div {
        width: 100%;
      }
    }
  }
}