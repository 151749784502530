.wrap {
  padding: 25px 20px;
  width: 100%;
  overflow-y: auto;

  .title {
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 21px;
  }
}