.conditionGroup {
  padding: 12px;
  background: #F3F3F3;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .groupName {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .singleConditions {

  }

  .addSingleCondition {
    width: fit-content;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.55);
    font-size: 14px;
    background: none;
    border: none;
    transition: 0.2s all ease;
    padding: 5px;
    border-radius: 2px;

    &:hover {
      transition: 0.2s all ease;
      background: #dfdfdf;
      color: rgba(0, 0, 0, 0.9);
    }
  }
}