.scrollContainer {
  max-height: 150px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C4C4C4;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.teamTreeWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .teamItem {
    .wrap {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 4px;

      svg {
        transition: 0.2s all ease;
      }

      &.opened {
        svg {
          transition: 0.2s all ease;
          transform: rotate(-180deg);
        }
      }

      .icon {
        border-radius: 50%;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        margin-right: 10px;
        color: #FFFFFF;
      }


    }
  }
}