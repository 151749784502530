.selectedTaskSidebar {
  width: 470px;
  height: 100%;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  right: -100%;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02),
  0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  padding: 20px 0;

  &.opened {
    right: 0;
    transition: all 0.3s ease;
  }

  .closeBtn {
    background: #FFFFFF;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    border: none;
    width: 28px;
    height: 28px;
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
    top: 22px;
    left: -14px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}