@import '../../landingUi/landingColors.scss';

.social {
	display: flex;
	align-items: center;
  }
  
  .socialIcon {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	&:last-of-type {
	  margin-right: 0;
	}
  }