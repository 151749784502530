.status {
  font-size: 12px;
  color: #fff;
  padding: 3px 8px;
  border-radius: 6px;
  text-transform: capitalize;

  &.active {
    background: #83A755;
  }

  &.archived {
    background: #DE4935;
  }

  &.deleted {
    background: #DE4935;
  }

  &.in_progress {
    background: #F2CD7B;
  }

  &.pending {
    background: #F2CD7B;
  }

  &.rejected {
    background: #DE4935;
  }

  &.approved {
    background: #83A755;
  }

  &.not_requested {
    background: #F3F3F3;
    color: rgba(0, 0, 0, 0.55);
  }

  &.requires_clarification {
    background: #F29239;
  }

  &.in_progress_recent {
    background: #5B79B4;
  }

  &.pending_recent {
    background: #F2CD7B;
  }

  &.rejected_recent {
    background: #DE4935;
  }

  &.completed_recent {
    background: #83A755;
  }
}