.popPupActualSaving {
    width: 640px;
    height: 510px;
}

.popupBtnsWrap {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 120px;
}
.inputWrap{
    display: flex;
    justify-content: space-between;
}

.savingsTypeStyles{
     width: 48%;
}

.monthInputStyle{
    margin-bottom: 4px;
}
.currencyItem{
    margin-bottom: 16px;
}

.popupBtnsWrap{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 90px;
}