@import "../../landingUi/landingColors.scss";

.item {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  font-size: 18px;
  line-height: 150%;
  color: $title;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 3px;
    background-image: url("../../assets/icons/Check.svg");
    background-repeat: no-repeat;
    @media (max-width: 767px) {
      top: 4px;
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    align-items: flex-start;
  }
}

.itemText {
  display: flex;
  min-width: 276px;
  padding-left: 28px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .item {
    display: block;
  }
}
