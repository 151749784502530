.editBlockWrapper {
  .mainFormFields {
    display: flex;
    flex-direction: column;

    .fieldGroup {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      .checkbox {
        position: relative;
        top: 29px;
      }

      & > * {
        width: 49%;
      }
    }
  }
}

.information {
  margin-top: 24px;
  margin-bottom: 4px;
}

