.popPupActualSaving {
    width: 640px;
    height: 412px;
}
.currencyItem{
    margin-bottom: 15px;
}

.popupBtnsWrap {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 120px;
}