.wrap {
  margin: 0;
  list-style: none;
  li {
    display: flex;
    &:not(:first-child) {
      margin-top: 8px;
    }
    span {
      width: 250px;
    }
  }
}
