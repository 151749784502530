.title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.icon {
  margin-left: 8px;
}

.tabs {
  white-space: nowrap;
  li {
    width: auto !important;
  }
}
