.approvalForm {
  background: #FFFFFF;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05), 0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11), 0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px 20px;
}

.input {
  width: 46%;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.inputGroup {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;

  .input {
    margin-bottom: 0;
  }
}

.formGroupTitle {
  font-weight: 500;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 16px;
}