.approval {
  height: unset;
  padding: 8px;
  cursor: pointer !important;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .headerContentWrap {
      display: flex;
      align-items: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      svg {
        min-width: 11px;
        min-height: 12px;
      }

      p {
        font-weight: 500;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.9);
        margin-right: 7px;
      }
    }
  }

  .infoBlock {
    display: flex;
    align-items: center;

    svg {
      width: 15px;
      height: 15px;
      margin-right: 8px;
    }

    p {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.55);
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}