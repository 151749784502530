.header {
  display: flex;
  justify-content: space-between;

  .count {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.55);
  }
}

.value {
  padding: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
}