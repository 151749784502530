.wrap {
  width: 100%;
  overflow-y: auto;
  padding: 24px 20px 95px 20px;

  .title {
    font-weight: 500;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 20px;
  }

  .filter {
    margin-bottom: 20px;
  }
}

.preloader {
  margin-bottom: 40px;
}