.detailLoading {
  width: 49%;
}

.stakeholdersWrap {
  width: 49%;
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  .emptyList {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.55);
    }
  }

  .stakeholdersList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 24px 16px;

    .singleStakeholder {
      width: 48%;
      display: flex;
      gap: 10px;
      margin-bottom: 18px;

      .info {
        p {
          &:first-child {
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.9);
          }

          &:last-child {
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.55);
          }
        }
      }
    }
  }

  header {
    padding: 12.5px;
    border-bottom: 1px solid #EAEAEA;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-weight: 500;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.9);

      span {
        font-weight: 500;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.55);
      }
    }

    svg {
      cursor: pointer;
    }
  }
}