@import "../../../../landingUi/landingMain.scss";

.benefits {
  padding-top: 100px;
  padding-bottom: 134px;
  background-color: $biege;
  @media (max-width: 1199px) {
    padding-top: 80px;
    padding-bottom: 106px;
  }
  @media (max-width: 767px) {
    padding-bottom: 114px;
  }
}

.title {
  text-align: center;
  color: $title;
  font-size: 36px;
  line-height: 110%;
  font-weight: bold;
  margin-bottom: 64px;
}

.arrow {
  width: 64px;
  height: 64px;
  display: block;
  position: absolute;
  transition: all 0.3s ease;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  &:hover path {
    fill: #d9d9d9;
  }
}

.nextArrow {
  right: 0;
}

.prevArrow {
  left: 0;
  z-index: 50;
}

.slider {
  box-shadow: $modalShadow;
}

