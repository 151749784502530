@import "../../../UIKit/colorVars";
.wrapper {
  display: flex;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.55);
  & > .container {
    display: flex;
    & > .item {
      padding: 3px 8px;
      &.active{
        &:not(:last-child) {
          cursor: pointer;
          border-radius: 2px;
          line-height: 1.5;
          &:hover {
            background: $white3;
          }
        }
      }
    }
    & > span {
      margin: 0 8px;
      display: flex;
      align-items: center;
    }
  }
}
