.secWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .tempInfo {
    display: grid;
    grid-template-rows: 0.2fr 0.3fr 0.4fr;
    padding: 20px 30px;
    margin-right: 20px;

    .tempHead {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .checkLab {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.55);
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  section:last-of-type {
    margin-left: 20px;
  }

  > section {
    height: 250px;
    width: 50%;
    box-shadow: 0px 0.5px 1px RGB(0 0 0 / 10%), 0px 3px 5px RGB(0 0 0 / 11%),
      0px -1px 10px RGB(0 0 0 / 2%), 0px -0.5px 4px RGB(0 0 0 /5%);
    background-color: white;
  }
}

.scaleWrapper {
  display: grid;

  .secContainer {
    padding: 20px 10px;
    margin: 0px 20px 0px 10px;

    .secHead {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &:last-child {
        padding-right: 30px;
      }
    }
  }
  .percentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 30px;
    margin-bottom: 4px;
    padding-right: 20px;
  }

  .colorContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 0px 30px;

    .colorBlock {
      display: flex;
      justify-content: center;
      height: 25px;
      label {
        position: absolute;
        bottom: 10px;
      }
    }
  }

  .labelContainer {
    display: grid;
    grid-template-columns: 0.31fr 0.34fr 0.3fr 0.31fr;
    margin-left: 110px;
    margin-top: 10px;
  }
  .labelContainer1 {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin: 0px 30px;
  }
}
