@import "../../../UIKit/colorVars";

.selectionField {
  margin-bottom: 16px;
}
.inputWrap {
  margin-bottom: 16px;
  position: relative;
}
.dropdown {
  &:first-child {
    color: $textLight !important;
    font-size: 14px;
    line-height: 150%;
  }
}
