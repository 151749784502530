@import "../../../../../../../UIKit/colorVars";

.sidebarNavigation {
  margin: 0;
  list-style: none;
  height: 100%;
  overflow-y: auto;
  user-select: none;

  &.position {
    position: relative;
  }
}

.navigationItem {
  display: flex;
  align-items: center;
  padding-left: 28px;
  height: 32px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 400;

  .iconWrap {
    svg {
      path {
        fill: #3f3f3f;
      }
    }

    &.large {
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  p {
    margin-left: 8px;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.9);
    background-color: $menuItem;
    & .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}

.tooltip {
  user-select: none;
  visibility: hidden;
  width: 100%;
  max-width: 90px;
  background-color: #494949;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 5px 0;
  position: absolute;
  z-index: 1000000;
  left: 50px;
  margin-bottom: 35px;
  opacity: 0;
  transition: opacity 1s;
}

.subItem {
  padding-left: 32px;

  & > p {
    margin: 0;
  }
}

.active {
  color: rgba(0, 0, 0, 0.9);
  background-color: $menuItem;
}

.itemIcon {
  margin-right: 10px;
}

.disable {
  color: #aaa !important;
  cursor: not-allowed;

  svg {
    path {
      fill: #aaa !important;
    }
  }
}

.subNavigationWrap {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #f8f8f8;
  z-index: 1;
  left: -100%;
  transition: 0.2s all ease;

  &.active {
    left: 0;
    transition: 0.2s all ease;
  }

  .subNavigationHeader {
    padding: 0 17px;

    .backBtn {
      background: none;
      border: none;
      padding: 0;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 12px;

      > svg {
        margin-right: 16px;
      }
    }

    .parentItemName {
      margin-top: 12px;
      margin-left: 15px;
      margin-bottom: 7px;
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
    }
  }
}
