.mainContainer {
  width: 100%;
  padding: 0px 40px;
  overflow-y: auto;
}

.container {
  padding: 24px 0px;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: row;
    }
  }
  .title {
    font-weight: 600;
    font-size: 21px;
  }
}

.emptyMargins {
  margin-top: 30px;
  button {
    display: flex;
    align-items: center;
    padding-left: 46px !important;
    background: #2f78cd !important;
  }
}
