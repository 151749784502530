.conditionsWrap {
  background: #FFFFFF;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02),
  0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
  padding: 20px 16px;

  .title {
    color: rgba(0, 0, 0, 0.9);
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .conditionGroup {
    .groupTitle {
      font-weight: 500;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.9);
    }

    .operator {
      padding-left: 12px;
    }

    .header {
      display: flex;
      justify-content: space-between;

      .subHeader {
        padding: 12px 12px 8px 12px;
        background: #F3F3F3;
        border-radius: 2px 2px 0 0;

        &:first-child {
          width: 67%;
        }

        &:last-child {
          width: 31%;
        }
      }
    }

    .singleCondition {
      display: flex;
      justify-content: space-between;

      &.last .subCondition {
        padding: 0 12px 16px 12px;
      }


      .subCondition {
        background: #F3F3F3;
        padding: 0 12px;

        .valuesWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &.expectedValue {
          width: 67%;

          .input {
            width: 48%;
          }

          span {
            white-space: nowrap;
            width: fit-content;
            display: block;
            margin: 0 10px;
          }
        }

        &.currentValue {
          width: 31%;

          .input {
            width: 100%;
            margin-right: 10px;
          }
        }
      }
    }
  }
}