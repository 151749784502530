.tablesContainer {
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05), 0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11), 0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background: #fff;
  padding: 3px 20px 20px;

  .title {
    margin-bottom: 56px;
  }

  .content {
    display: flex;
    justify-content: space-between;
  }

  .item:not(:last-child) {
    margin-bottom: 24px;
  }
}