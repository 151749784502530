.informationWrap {
  padding-left: 24px;
  height: 40px;
  display: flex;
  align-items: center;
  background: rgba(47, 120, 205, 0.07);
  border-radius: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  .iconWrap {
    padding-right: 8px;
    display: flex;
  }

}
