.questCont {
  display: grid;
  grid-template-columns: 0.6fr 0.2fr 0.2fr 0.02fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  padding-top: 8px;
  &:nth-child(1) {
    margin-bottom: 10px;
  }

  div:first-of-type {
    margin-left: 15px;
  }
  div {
    display: flex;
    align-items: center;
    padding-left: 10px !important;
    border: 1px solid #dadada;
    height: 30px;
    font-size: 14px;
    line-height: 21px;
  }
}
