@import "UIKit/_colorVars.scss";

.incomplete {
  font-size: 14px;
  line-height: 20px;
  color: $textLight;
}
.container {
  display: flex;
  align-items: center;
  span {
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    margin-left: 8px;
    &.hight {
      background: #7cac3e;
    }
    &.medium {
      background: #f6c864;
    }
    &.low {
      background: #e2452f;
    }
  }
}
