@import "UIKit/_colorVars.scss";

.container {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  overflow-x: auto;
  width: 100%;
  &.loading{
    box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05), 0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11), 0px 0.5px 1px rgba(0, 0, 0, 0.1);
  }
}

.title {
  font-weight: 500;
  color: $textDark;
  margin-bottom: -20px;
  margin-left: 20px;
  position: relative;
  z-index: 10;
}
.chartWrapper {
  width: 100%;
  min-width: 560px;
}

.legendWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  flex-wrap: wrap;

  .legend {
    display: flex;
    align-items: center;
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
    span {
      margin-right: 8px;
      width: 12px;
      height: 12px;
      background-color: red;
    }
    p {
      font-size: 12px;
      color: $textDark;
    }
  }
}
