.wrap {
  width: 100%;
  overflow-y: auto;
  padding: 24px 20px 95px 20px;

  header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .title {
      font-weight: 500;
      font-size: 21px;
      color: rgba(0, 0, 0, 0.9);
      margin-right: 8px;
    }
  }

  .details {
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: unset;
    border: 1px solid #EAEAEA;
    margin-bottom: 40px;
  }
}