.projectTaskBreakdownWrap {
  h4 {
    font-weight: 500;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 20px;
  }
}

.projectTaskBreakdownList {
  height: 200px;
  overflow-y: scroll;
  border-bottom: 1px solid #C8C8C8;

  &::-webkit-scrollbar {
    display: none;
  }
}

.totalTasks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 21px;

  p {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
  }
}

.department {
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .titleWrap {
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      transition: 0.2s all ease;
      cursor: pointer;
    }

    &.opened {
      svg {
        transition: 0.2s all ease;
        transform: rotate(-180deg);
      }
    }

    .title {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .task {
    display: flex;
    justify-content: space-between;
    margin-left: 15px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    p {
      &:first-child {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
      }

      &:last-child {
        font-weight: 500;
        font-size: 12px;
        color: #63A5A1;
      }
    }
  }
}

.spent {
  background: #63A5A1;
  border-radius: 6px;
  font-size: 12px;
  color: #FFFFFF;
  padding: 2px 8px;
}