@import "../../../UIKit/colorVars";

.defaultLayout {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: $layoutBg;
  font-family: "Roboto", sans-serif;
}

.header {
  height: 48px;
  background-color: $white;
}

.bodyLayout {
  display: flex;
  background-color: $layoutBg;
  height: calc(100% - 48px);
  position: relative;
}

.wrap {
  width: calc(100% - 240px);
  display: flex;

  &.withoutMenu {
    width: 100%;
  }
}

.leftSidebar {
  width: 240px;
  height: calc(100vh - 48px);
}

.stretchDiv {
  height: 100%;
  width: 3px;
  background-color: none;
  position: absolute;
  cursor: col-resize;
  z-index: 10000;
}

.stretchDiv:hover, .stretchDiv.drag {
  background-color: #2f78cd;
  cursor: col-resize;
}

.expand-btn {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  position: absolute;
  background-color: #ffffff;
  border: 1px #0000001a solid;
  top: 10%;
  z-index: 10000;

  .sortArrow {
    transform: rotate(-90deg);
    padding: 4px 2px 0px 0px;

    &.throw {
      transform: rotate(90deg);
      padding: 0px 0px 2px 4px;
    }
  }

  .tooltip {
    user-select: none;
    visibility: hidden;
    width: 70px;
    background-color: #494949;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 5px 0;
    position: absolute;
    z-index: 1000000000;
    bottom: 100%;
    left: 50%;
    margin-left: -35px;
    margin-bottom: 5px;
    opacity: 0;
    transition: opacity 1s;
  }
}

.expand-btn:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.rightSidebarWrap {
  flex: 1;
  display: flex;
  position: relative;
}

.layoutWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.backToDashboardBtn {
  padding: 10px 0 0 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.55);
  width: fit-content;

  svg {
    margin-right: 7px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .leftSidebar {
    min-width: 240px;
  }
}
