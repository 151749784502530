@import "../../../UIKit/colorVars";

.wrap,
.dropdown {
  width: 100%;
}

.titleWrap{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
}

.label {
  font-weight: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  overflow: inherit;

  & > div {
    margin-left: 5px;
  }
}

.errorMessage {
  color: #a4262c;
  font-size: 12px;
  margin-top: 5px;
  margin-right: auto;
}

.dropdown {
  position: relative;

  .placeholder {
    color: rgba(0, 0, 0, 0.44);
    font-size: 14px;
  }

  .header {
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 11px;
    max-height: 32px;
    cursor: pointer;

    .arrow {
      transition: 0.2s all ease;
    }
  }

  .dropWrap {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 90;
    background: #FFFFFF;
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    max-height: 176px;
    height: fit-content;
    overflow-y: auto;
    padding: 8px 0;

    .list {
      list-style-type: none;
      margin: 0;
    }

    .item {
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      white-space: nowrap;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.9);

      &.selected {
        background: #F3F3F3;
      }
    }
  }

  &.opened {
    .header {
      .arrow {
        transform: rotate(180deg);
      }
    }
  }

  &.disabled {
    .dropWrap {
      display: none;
    }

    .header {
      background: #F4F4F4;
      border: 1px solid #C8C8C8;
      cursor: not-allowed;
    }

    .value {
      color: rgba(0, 0, 0, 0.38);
    }

    .arrow path {
      fill: rgba(0, 0, 0, 0.38)
    }
  }

  &.focused {
    .header {
      border: 1px solid #4F78E0;
    }
  }
}
