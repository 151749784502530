.statusWrap{
  display: flex;
  align-items: flex-start;
  padding: 2px 8px;
  margin: 0px 4px;
  border-radius: 6px;

  .status{
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 14px;
    line-height: 150%;
    color: rgba(255, 255, 255, 1);
  }
}
