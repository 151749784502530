@import "../../../UIKit/colorVars";

.form {
  margin-top: -8px;
}

.textarea {
  height: 95px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  &::placeholder {
    color: $textLight;
  }
  &:hover {
    &::placeholder {
      color: $textDark;
      transition: 0.8 linear;
    }
  }
  &:focus {
    border: 1px solid #4f78e0;
    box-shadow: 0 0 0 3px #d3e1f4;
    border-radius: 5px;
    &::placeholder {
      opacity: 0;
    }
  }
}
