.projectStatuses {
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #EAEAEA;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;

  &.openedDetails {
    height: auto;
  }

  h3 {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 12px;
  }

  .selectors {
    height: 100%;
    overflow-y: scroll;

    .selector {
      margin-bottom: 14px;
      position: relative;
      width: fit-content;

      .title {
        font-weight: 500;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
        margin-bottom: 2px;
      }

      .selectedOption {
        padding: 2px 4px;
        border-radius: 2px;
        width: 140px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        align-items: center;

        &.disabled {
          cursor: default;

          &:hover {
            background: none;
          }
        }

        &:hover {
          background: #F3F3F3;
        }
      }

      .color {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 5px;
      }

      .optionList {
        display: flex;
        flex-direction: column;
        position: absolute;
        background: #FFFFFF;
        box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        width: 170px;
        padding: 2px 0;
        z-index: 1;
        left: 10px;

        .option {
          display: flex;
          cursor: pointer;
          width: 100%;
          align-items: center;
          padding: 5px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          &:hover {
            background: #F3F3F3;
          }
        }
      }
    }
  }
}