.content {
  overflow-y: scroll;
  position: relative;
  height: 220px;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C4C4C4;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.teamLeaderboardWrapper {
  padding: 17px 0 0 0;

  .title {
    padding: 0 16px;
  }

}
