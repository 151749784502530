.wrap {
	width: 100%;
	overflow-y: auto;
	padding: 24px 20px 95px;
	&.isRequest {
		overflow-y: initial;
		padding: 0;
	}
	.title {
		margin-bottom: 20px;
	}
	.savingsGoal {
		margin-top: 20px;
		position: relative;
		& > section {
			height: auto;
			padding-bottom: 20px;
		}
		.editBtn {
			position: absolute;
			top: 20px;
			right: 20px;
			cursor: pointer;
			z-index: 0;
		}
	}
	.table {
		margin-top: 40px;
	}
}
