@import "../../../../landingUi/landingMain.scss";

.leadership {
  background-color: $biege;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 899px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.title {
  color: $title;
  font-weight: bold;
  font-size: 36px;
  line-height: 150%;
  margin-bottom: 16px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 32px;
  }
}

.text {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 450px;
  font-size: 18px;
  line-height: 150%;
  color: $textLigtht;
  margin-bottom: 64px;
  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 899px) {
    justify-content: center;
  }
}
