.wrap {
  padding: 24px 20px 95px;
  width: 100%;
  overflow-y: auto;

  .title {
    margin-bottom: 20px;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 500;
  }
}
