@import "../../../UIKit/colorVars";

.currencySelectionField {
  .label {
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
    margin-bottom: 4px;
  }

  .labelGrey {
    color: rgba(0, 0, 0, 0.55);
    font-style: italic;
    margin-left: 4px;
  }

  .wrap {
    display: flex;
    align-items: end;

    .fieldWrap {
      position: relative;
      width: 100%;
      display: flex;

      .currency {
        color: rgba(0, 0, 0, 0.9);
        font-size: 14px;
        height: 32px;
        border: 1px solid #dadada;
        border-right: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 11px;

        &.placeholder {
          color: rgba(0, 0, 0, 0.55);
        }
      }

      .currencyField {
        border: 1px solid #dadada;
        border-right: none;
        // border-left: none;
        height: 32px;
        padding: 0 6px;
        padding-left: 11px;
        width: 100%;
        outline: none;
        color: rgba(0, 0, 0, 0.9);
        font-size: 14px;

        &::placeholder {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.55);
        }
      }
    }

    .changeCurrency {
      width: 100%;
      max-width: 87px;

      &.disableCurrency header {
        background: #FFFFFF;
        border: 1px solid #DADADA;

        span {
          color: rgba(0, 0, 0, 0.9);
        }

        path {
          fill: rgba(0, 0, 0, 0.9);
        }
      }
    }
  }

  .errorMessage {
    color: #a4262c;
    font-size: 12px;
    margin-top: 5px;
    margin-right: auto;
  }

  &.disabled {
    .currencyField {
      cursor: not-allowed;
      background: #f4f4f4;
    }
  }
}