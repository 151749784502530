.surveysWrap {
  margin-bottom: 70px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 24px;

    p {

    }
  }

  .surveysListWrap {
    .listTitle {
      display: flex;
      justify-content: space-between;
      gap: 5px;
      border-bottom: 1px solid #EAEAEA;
      margin-bottom: 12px;
      padding: 0 24px 12px;

      .title {
        display: block;
        font-weight: 500;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
        width: 100%;
      }
    }

    .emptyList {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.55);
    }
  }
}