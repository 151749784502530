.wrap {
  padding: 24px 24px 95px 24px;
  overflow-y: auto;
  width: 100%;

  .title {
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 20px;
  }

  .details {
    margin-bottom: 20px;
  }

  .general,
  .suppliers {
    margin-bottom: 40px;
  }
}

.row {
  margin-bottom: 40px;

  .name {
    font-weight: 500;
    margin-bottom: 12px;
  }
}