.dropdownWrapper {
  width: 100%;
  height: 100%;
  max-width: 600px;
  position: relative;

  .dropdown {
    cursor: pointer;
    width: 100%;
    height: 100%;
    min-height: 32px;
    border-radius: 2px;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px;

    .placeholder {
      color: #8f8f8f;
      font-weight: 200;
    }

    .selectedOption {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .arrow {
      display: flex;
      transition: transform 0.2s ease-out;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  &.withError {
    .optionsList {
      margin-top: -17px;
    }
  }

  .optionsList {
    max-width: 600px;
    max-height: 244px;
    padding: 4px 0;
    overflow-y: auto;
    position: absolute;
    top: calc(100% + 2px);
    z-index: 100;
    width: 100%;
    background: #fff;
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
  }

  .label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
  }

  .errorMessage {
    color: #a4262c;
    font-size: 12px;
    margin-right: auto;
  }
}
