.subSupplier {
  display: flex;
  justify-content: start;

  a:first-child {
    color: #005a9e !important;
    text-decoration: none;
  }

  a:last-child {
    color: #858585 !important;
    margin-left: 8px;
    text-decoration: none;
  }
}
.inlineWrap{
     transform: rotateX(180deg);
     display: flex;
     flex-direction: column;
     padding-bottom: 4px;
     max-height: calc(100vh - 260px);
     overflow-y: scroll;
}
.link {
  color: #005a9e;
  text-decoration: none;
}

.logoSubSupplier {
  display: flex;
  align-items: center;
}

.logo {
  height: 24px;
  width: 24px;
  border-radius: 59%;
  margin-right: 10px;
}

.logoGrey {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  margin-right: 10px;
  border-radius: 50%;
  height: 26px;
  background: lightblue;
}

.contractName {
  display: flex;
  align-items: center;

  > * {
    margin-right: 3px;
  }
}