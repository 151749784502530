@import "../../../../../landingUi/landingColors.scss";

.managementCard {
  text-align: center;
  width: 411px;
  height: 208px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 56px;
  @media (max-width: 899px) {
    width: 348px;
    margin-bottom: 48px;
  }
}

.img {
  margin-bottom: 16px;
}

.title {
  color: $title;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 4px;
}

.text {
  font-size: 18px;
  line-height: 150%;
  color: $textLigtht;
}
