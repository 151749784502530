.popPupActualSaving {
    width: 640px;
    height: 412px;
}
.currencyItem{
    margin-bottom: 15px;
}

.popupBtnsWrap {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 120px;
}

.logo{
    margin-top: 15px;
    width: 56px;
    height: 56px
}
.logoGrey{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      margin: 20px 10px;
      border-radius: 50%;
      height: 56px;
      background: lightblue;
}
