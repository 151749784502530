.stepsWrapper {
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02),
  0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
  padding: 16px 0 1px 0;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .title {
      font-size: 14px;
      font-weight: 500;
      display: flex;

      .count {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 21px;
        height: 21px;
        background: #F0F0F0;
        font-size: 11px;
        font-weight: normal;
        border-radius: 50%;
        margin-left: 10px;
      }
    }
  }

  .columns {
    display: flex;
    padding: 14px 20px;
    border-bottom: 1px solid #E9E9E9;
    margin-bottom: 4px;

    & > div {
      width: calc(100% / 3);
      color: rgba(0, 0, 0, 0.55);
      opacity: 0.87;
    }
  }
}