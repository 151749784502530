.editBtn {
  cursor: pointer;
}

.detail {
  box-shadow: none;
  border: 1px solid #eaeaea;
  margin-top: -1px;
  z-index: 0;
  border-radius: 0;
}