.name {
  display: flex;
  align-items: center;

  span {
    display: block;
    margin-left: 8px;
  }
}

.colorStatus {
  width: 64px;
  height: 22px;
  border-radius: 6px;
}