$height: 22px;
$wrapWidth: 209px;

.tagWrap {
  display: flex;
  padding: 0 8px;
  border-radius: 6px;
  height: $height;
  margin-right: 4px;
  margin-bottom: 8px;
  width: fit-content;
  align-content: center;

  &.flag {
    background: transparent !important;

    .tag {
      height: 18px;
    }
  }

  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $height;

    .title {
      color: white;
      font-size: 12px;
      line-height: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: calc(#{$wrapWidth} - 34px);
    }

    .deleteBtn {
      cursor: pointer;
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-left: 4px;

      &:hover {
        background: rgba(#000, 0.07);
      }
    }

    &.dark {
      .title {
        color: rgba(0, 0, 0, 0.55);
      }

      .deleteIcon path {
        fill: rgba(0, 0, 0, 0.55);
      }
    }
  }
}
