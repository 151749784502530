.adminTable {
  table tbody tr:hover {
      background-color: white !important;
  }
  tbody tr td:nth-child(7) {
    width: 120px;
  }
  tbody tr td:nth-child(1) {
    width: 150px;
  }
  tbody tr td:nth-child(4) {
    width: 140px;
  }
  td {
    padding: 0px 16px 5px 0px;
  }
}
.table {
  tbody tr td:nth-child(6) {
    width: 120px;
  }
}
.userTitle{
       color: #000000;
       opacity: 0.5;
    }
.nameStyle{
   font-family: Roboto;
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 150%;
   color: #005A9E;
}
.logoUser{
     display: flex;
     align-items: center;
}
.logo{
    width: 28px;
    height: 28px;
    font-size: 12px;
    color: white;
}
.nameBlock{
    display: flex;
}
.status{
   border-radius: 6px;
   font-size: 12px;
   color: #FFFFFF;
   position: relative;
   td:not(:first-child):not(:last-child) {
        opacity: 0.4;

   }

   td .logoUser {
     opacity: 0.5;
   }

     .tooltip {
       position: absolute;
       top: -17px;
       left: 90px;
       padding: 7px;
       background: #333333;
       border-radius: 2px;
       z-index: 1;
     }
     .conversation{
        display: flex;
        margin-left: 10px;
        width: 20px;
        svg{
            width: 16px;
            height: 21px;
        }
     }
}
