@import "../../../../landingUi/landingMain.scss";

.procurementTogether {
  background-color: $biege;
  margin-top: 64px;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 1440px) {
    padding-top: 125px;
    padding-bottom: 125px;
  }
  @media (max-width: 899px) {
    padding-top: 88px;
  }
  @media (max-width: 767px) {
    padding-top: 64px;
    padding-bottom: 80px;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 899px) {
    flex-direction: column;
    align-items: center;
  }
}

.textBlock {
  @media (max-width: 899px) {
    margin-bottom: 64px;
  }
}

.title {
  font-weight: bold;
  font-size: 48px;
  line-height: 110%;
  color: $title;
  max-width: 492px;
  margin-bottom: 24px;
  @media (max-width: 899px) {
    display: none;
  }
}

.titleMobile {
  display: none;
  @media (max-width: 899px) {
    display: block;
    text-align: center;
    color: $title;
    font-weight: bold;
    font-size: 48px;
    line-height: 110%;
    margin-bottom: 36px;
  }
  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
}

.text {
  max-width: 519px;
  font-size: 24px;
  line-height: 150%;
  color: #736372;
  margin-bottom: 36px;
  @media (max-width: 899px) {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    margin-bottom: 32px;
  }
}

.rockButton {
  width: 150px;
  @media (max-width: 899px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}

.videoBlock {
  width: 700px;
  height: 394px;
  box-shadow: $shadow;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../assets/img/Screenshot.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  @media (max-width: 899px) {
    width: 720px;
    background-image: url("../../../../assets/img/ScreenshotTablet.jpg");
    height: 406px;
  }
  @media (max-width: 767px) {
    background-image: url("../../../../assets/img/ScreenshotMobile.jpg");
    width: 328px;
    height: 192px;
  }
}


