.itemWrap {
  padding: 5px 12px;
  cursor: pointer;

  &:hover {
    background: #F3F3F3;
  }
}

.status {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  display: flex;
  align-items: center;

  > span {
    min-width: 8px;
    min-height: 8px;
    margin-right: 5px;
    display: block;
    border-radius: 50%;
  }
}