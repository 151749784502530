@import "UIKit/_colorVars.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: auto;
  padding-right: 80px;
}
.title {
  font-weight: 500;
  color: $textDark;
  margin-bottom: auto;
}

.svg circle {
  stroke: $menuItem;
  stroke-width: 1em;
}
.svg .bar {
  stroke: #63a5a1;
}
.container {
  display: block;
  height: 200px;
  width: 200px;
  margin: auto;
  border-radius: 100%;
  position: relative;
}

.container:after {
  position: absolute;
  display: block;
  height: 160px;
  width: 160px;
  left: 50%;
  top: 50%;
  content: attr(data-pct);
  margin-top: -80px;
  margin-left: -80px;
  border-radius: 100%;
  font-weight: 500;
  font-size: 21px;
  line-height: 160px;
  text-align: center;
  color: $white;
}

.textCircle {
  stroke: none !important;
}
