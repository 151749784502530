.inline{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  padding-right: 20px;
  .text{
    &:not(:first-child){
      margin-top: 24px;
    }
  }
}
