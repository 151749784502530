.files{
  list-style-type: none;
  margin: 8px 0 0;
  .uploadedFile {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 8px;
    }
    .fileName {
      color: rgba(0, 0, 0, 0.9);
      font-size: 14px;
      margin-left: 10px;
      margin-right: 5px;
      display: block;
      text-decoration: none;
    }
    .deleteFile {
      cursor: pointer;
    }
  }
}
