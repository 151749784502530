.viewBlockWrapper {
  display: flex;
  justify-content: space-between;

  .content {
    display: flex;
    flex-wrap: wrap;
    max-width: 370px;

    .infoGroup {
      margin-right: 80px;

      &:nth-child(-n+2) {
        margin-bottom: 14px;
      }

      p {
        &:first-child {
          font-weight: 500;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.55);
        }

        &:last-child {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }
  }
}
