@import "../../../../../UIKit/colorVars";

.wrapper {
  overflow-x: auto;
  .title {
    margin-bottom: 12px;
  }
  .container {
    display: flex;
    width: 100%;
    height: fit-content;
    .column {
      min-width: 350px;
      width: 50%;
      height: inherit;
      h4 {
        margin-bottom: 20px;
      }
      &:first-child {
        padding: 24px;
        padding-bottom: 46px;
        padding-right: 0;
        background-color: $white;
        box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
          0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11),
          0px 0.5px 1px rgba(0, 0, 0, 0.1);
        margin-right: 20px;
      }
      &:last-child {
        white-space: nowrap;
        min-width: fit-content;
      }
    }
  }
  .spendContainer {
    display: flex;
    .statusWrapper {
      margin-top: 15px;
      width: 50%;
      align-self: flex-start;
    }
    .pieChartWrapper {
      margin-right: 30px;
      width: 180px;
      margin-left: auto;
      .pieChart {
        min-height: 160px !important;
      }
    }
  }
}
