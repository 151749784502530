@import "../../../../../landingUi/landingColors.scss";

.incCard {
  box-shadow: $modalShadow;
  padding-top: 28px;
  padding-bottom: 35px;
  padding-left: 24px;
  padding-right: 24px;
  width: 628px;
  @media (max-width: 1439px) {
    width: 100%;
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 24px;
  color: $title;
}

.list {
  list-style: none;
}




