.wrap {
  width: 100%;
  overflow-y: auto;
  padding: 24px 20px 95px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .title {
    font-weight: 500;
    font-size: 21px;
  }

  .tasksBoard {

  }

  .detail {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
