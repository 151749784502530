.content {
  overflow-y: scroll;
  position: relative;
  height: 220px;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C4C4C4;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.individualLeaderboardWrapper {
  padding: 17px 0 0 0;

  .title {
    padding: 0 16px;
  }

  .owner {
    display: flex;
    align-items: center;
    margin-right: 8px;

    .avatarWrapper {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-right: 8px;
      font-weight: bold;
    }

    & > {
      color: #005A9E;
      opacity: 0.87;
      font-size: 14px;
    }
  }
}
