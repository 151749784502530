.tab {
  &.primary,
  &.secondary {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    display: inline-block;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.9);
    text-transform: capitalize;
  }
  &.primary {
    margin-right: 8px;
    padding: 0 4px 9px;
    &.current {
      font-weight: 500;
      border-bottom: 2px solid #2f78cd;
    }
  }
  &.secondary {
    padding: 4px 8px;
    background: #f3f3f3;
    border: 1px solid #eaeaea;
    border-radius: 2px 2px 0px 0px;
    width: 72px;
    text-align: center;
    &:hover {
      background: #f8f8f8;
    }
    &.current {
      background: white;
      border-bottom: 1px solid white;
    }
  }
  &.disabled {
    color: #aaa;
    cursor: not-allowed;
  }
}
