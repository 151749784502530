.header {
  margin-bottom: 25px;
}

.infoWrap {
  display: flex;
  flex-direction: column;

  .infoBlock {
    .title {
      font-weight: 500;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.55);
      margin-bottom: 4px;
    }

    .value {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);

      &.green {
        color: #63A5A1;
      }

      &.status {
        font-size: 12px;
        color: #FFFFFF;
        padding: 2px 6px;
        background: #83A755;
        border-radius: 6px;
      }
    }

    &.single {
      margin-bottom: 16px;
    }
  }

  .infoBlockGroup {
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;

      &:first-child {
        margin-bottom: 16px;
      }

      .infoBlock {
        width: 50%;
      }
    }
  }
}