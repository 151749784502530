.questCont {
  display: grid;
  grid-template-columns: 0.6fr 0.2fr 0.2fr 0.02fr;
  grid-column-gap: 20px;
  &:nth-child(1) {
    margin-bottom: 10px;
  }
  .firstDiv {
    margin-left: 10px;
  }
  .dropDown {
    padding-top: 4px !important;
  }
}

.lineCont {
  margin: 45px 10px 10px 10px;
  width: 97%;
  background-color: #c6c6c6;
  height: 1px;

  &.noAnsw {
    margin: 150px 10px 10px 10px;
  }
}

.checkCont {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.lineCont2 {
  margin: 0px 10px;
  height: 100%;
  background-color: #c6c6c6;
  width: 1px;
}

.bottomCont {
  display: flex;
  justify-content: space-between;
  padding: 0px 40px 0px 10px;
}
