.notificationWrap {
  position: relative;

  .iconWrap {
    display: flex;
    cursor: pointer;
  }

  .message {
    position: absolute;
    top: -55px;
    left: 100%;
    max-width: 350px;
    background: #333333;
    border-radius: 2px;
    padding: 7px 12px;
    color: #FFFFFF;
    font-size: 14px;
    z-index: 999;
    word-break: break-all;
    white-space: pre;
  }
}