@import "../../../../../UIKit/index";

.container {
  width: 100%;
  min-height: 233px;
  display: flex;
  background-color: $white;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
    0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11),
    0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow-x: auto;
  overflow-y: hidden;
  .column {
    margin: 20px 40px;
    width: 100%;
    &:first-child {
      width: 250px;
    }
  }
  .divider {
    width: 100%;
    width: 1px;
    border: 1px dashed rgba(0, 0, 0, 0.55);
    margin-top: 29px;
    margin-bottom: 27px;
  }
}
