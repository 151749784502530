.questionNotification {
  width: fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;

  svg {
    cursor: pointer;
  }

  .notification {
    position: absolute;
    width: fit-content;
    white-space: pre;
    z-index: 9999;
    background: #333333;
    border-radius: 2px;
    padding: 7px;
    font-size: 12px;
    color: #FFFFFF;
    top: -32px;
    left: 10px;
  }
}