.wrap {
  width: 100%;
  overflow-y: auto;
  padding: 24px 20px 95px;
  .title {
    font-weight: 500;
    margin-bottom: 20px;
  }
  .tableWrap {
    width: 100%;
    margin-top: 40px;
  }
}
