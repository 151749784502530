.wrap {
  width: 100%;
  overflow-y: auto;
  padding: 24px 20px 95px;

  .titleWrap {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    font-weight: 500;
    display: flex;
    align-items: center;

    .status {
      font-size: 12px;
      color: #FFFFFF;
      display: flex;
      padding: 0 4px;
      justify-content: center;
      align-items: center;
      height: 20px;
      border-radius: 6px;
      margin-left: 5px;
      font-weight: normal;

      &.completed {
        background: #83A755;
      }

      &.in_progress {
        background: #5B79B4;
      }

      &.rejected {
        background: #DE4935;
      }

      &.pending {
        background: #F2CD7B;
      }
    }
  }
}

.wrapTabs {
  margin-top: 40px;
  margin-bottom: 20px;
}

.requestInfoWrap {
  display: flex;
  justify-content: space-between;

  & > div {
    &:first-child {
      width: 74%;
    }

    &:last-child {
      width: 24%;
    }
  }
}