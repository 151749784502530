.option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px 8px;
  margin: 5px 0;

  &:hover {
    background: #F3F3F3;
  }

  .avatar {
    width: 32px;
    height: 32px;
    min-width: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    color: #FFFFFF;
    text-transform: uppercase;
  }

  .values p {
    &:first-child {
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 300px;
    }

    &:last-child {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.55);
    }
  }
}