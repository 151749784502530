.wrap {
  width: 100%;
  overflow-x: auto;
  position: relative;
  background: white;
  .innerWrap {
    background: white;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
  .label {
    position: absolute;
    top: 24px;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 500;
    font-size: 15px;
  }
  .emptyWrap {
    padding: 24px 0;
  }
}
