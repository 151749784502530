@import "../../../../landingUi/landingMain.scss";

.ourCompany {
  margin-top: 64px;
  padding-top: 100px;
  padding-bottom: 80px;
  @media (max-width: 899px) {
    padding-top: 80px;
  }
}

.title {
  text-align: center;
  color: $title;
  font-weight: bold;
  font-size: 36px;
  line-height: 110%;
  margin-bottom: 64px;
  @media (max-width: 899px) {
    margin-bottom: 48px;
  }
  @media (max-width: 767px) {
    margin-bottom: 40px;
    font-size: 32px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 899px) {
    flex-direction: column-reverse;
  }
}

.subtitle {
  text-align: left;
  margin-bottom: 24px;
  @media (max-width: 899px) {
    text-align: center;
    margin-bottom: 16px;
  }
}

.text {
  font-size: 18px;
  line-height: 150%;
  color: $textDark;
  margin-bottom: 16px;
  max-width: 519px;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: 899px) {
    text-align: center;
    margin-bottom: 0;
    max-width: 100%;
  }
}

.videoBlock {
  width: 700px;
  height: 394px;
  box-shadow: $shadow;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../assets/img/Screenshot.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  @media (max-width: 899px) {
    margin-bottom: 40px;
    width: 720px;
    background-image: url("../../../../assets/img/ScreenshotTablet.jpg");
    height: 406px;
  }
  @media (max-width: 767px) {
    background-image: url("../../../../assets/img/ScreenshotMobile.jpg");
    width: 328px;
    height: 192px;
  }
}

.description {
  @media (max-width: 899px) {
    text-align: center;
  }
}


