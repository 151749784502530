.note{
  .header{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.55);
    margin-bottom: 11px;
    display: flex;
    align-items: center;
  }
  .date{
    margin-left: 4px;
  }
  .text{
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    word-break: break-word;
  }
}
