@import "../../../UIKit/colorVars";

.container {
	margin-top: 40px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.01);
	border-radius: 4px;
	background: $white;
	padding: 20px;
	position: relative;
	.title {
		font-weight: 500;
		font-size: 14px;
		line-height: 150%;
		margin-bottom: 4px;
		color: $textDark;
	}
}
