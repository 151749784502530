.openedList {
  min-height: 200px;
  max-height: 271px;
  height: auto !important;
}

.expiresInWrap {
  min-height: 185px;
  max-height: 214px;
  height: auto;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;

  .item {
    height: 40px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: rgba(#f3f3f3, 0.5);
    }

    &.active {
      background: #f3f3f3;
    }
  }
}