@import "../../../UIKit/colorVars";

.inputWrap {
  margin-bottom: 16px;
  position: relative;
}

.helpIcon {
  position: absolute;
  top: 3px;
  left: 148px;
  cursor: help;
}
.dropdown {
  &::before {
    position: absolute;
    left: 11px;
    top: 12px;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #df0505;
  }
  &.completed {
    &::before {
      background-color: #0d9d4f;
    }
  }
  &.progress {
    &::before {
      background-color: yellow;
    }
  }
  padding-left: 27px;
  display: block;
  text-transform: none;
  &::first-letter {
    text-transform: uppercase;
  }
}

.options {
  & > * {
    position: relative;
    padding-left: 27px;
    display: block;
    text-transform: none;
    line-height: 14px;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  & > ::before {
    position: absolute;
    left: 12px;
    top: 12px;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  & > :nth-child(1)::before {
    background-color: #df0505;
  }
  & > :nth-child(2)::before {
    background-color: yellow;
  }
  & > :nth-child(3)::before {
    background-color: #0d9d4f;
  }
}
.countField {
  margin-bottom: -2px;
  &.countWrap {
    margin-bottom: 16px;
  }
  textarea{
    height: 56px;
  }
}
