@import "../../../UIKit/index";

.container {
  padding: 24px 20px 0;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  .filterBar {
    margin-bottom: 16px;
  }
}
.title {
  font-weight: 600;
  font-size: 21px;
  margin-bottom: 13px;
}
