.container {
  padding: 24px 20px;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 95px;

  .logoNameBlock{
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
       svg {
          display: none;
          position: absolute;
          width: 59px;
          height: 22px;
          text-align: center;
        }
  }
  .title {
    font-weight: 600;
    font-size: 21px;
    margin-left: 20px;
  }
  .content {
    margin-top: 40px;
    .row {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
      .name {
        font-weight: 500;
        margin-bottom: 12px;
      }
    }
  }
}

.imgLogo{
    &:hover {
        cursor: pointer;
        svg {
             display: flex;
             align-items:center;
             position: absolute;
             width: 59px;
             height: 22px;
             text-align: center;
             margin-top: 17px;
             }
       }
}
 .logo{
        height: 56px;
        width: 56px;
        border-radius: 59%;
  }
