.financialBenefitsForm {
  background: #FFFFFF;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02),
  0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;

  .headerForm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    h2 {
      font-weight: 500;
      font-size: 21px;
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .datesWrap {
    width: 100%;

    & > div {
      width: calc(50% - 12px);

      > div {
        width: 100%;

        input {
          width: 100%;
        }
      }
    }

    .datePickerWrap {
      width: 100%;

      & > div {
        justify-content: space-between;

        & > div.wrapperdatePicker {
          width: calc(100% - 32px);
          flex: 1 0 auto;
          max-width: calc(100% - 32px);
        }
      }
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    &.largeMargin {
      margin-bottom: 40px;
    }

    &.additional {
      margin: 30px 0;
    }

    & > div {
      width: calc(50% - 12px);
    }
  }

  .splitSavingsForm {
    margin-top: 30px;

    h3 {
      font-weight: 500;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.9);
      margin-bottom: 12px;
    }

    .splitSavingBox {
      background: #F3F3F3;
      padding: 12px;
      margin-bottom: 12px;

      &.opened header svg {
        transition: all .2s ease;
        transform: rotate(180deg);
      }

      header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        svg {
          transition: all .2s ease;
        }

        h4 {
          font-weight: 500;
          font-size: 15px;
          color: rgba(0, 0, 0, 0.9);
        }
      }

      .values {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-top: 8px;

        .listValues {
          width: 100%;

          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;

            > svg {
              cursor: pointer;
              width: 20px;
              height: 20px;

              path {
                fill: rgba(0, 0, 0, 0.55);
              }
            }

            > span {
              font-size: 14px;
              color: rgba(0, 0, 0, 0.9);
            }

            .field {
              display: flex;

              .label {
                padding: 0 14px;
                display: flex;
                align-items: center;
                background: #fff;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.9);
                border: 1px solid #DADADA;
                border-radius: 2px;
                cursor: default;
              }

              > div:first-child {
                width: 100%
              }

              &:nth-child(1) {
                width: 46.3%;
              }

              &:nth-child(3) {
                width: 31.5%;
              }

              &:nth-child(5) {
                width: 10.3%;
              }
            }
          }
        }

        .addNewBtn {
          font-size: 14px;
          color: rgba(0, 0, 0, .55);
          cursor: pointer;
          border: none;
          background: transparent;
          padding: 0;

          &:hover {
            color: rgba(0, 0, 0, .9);
          }
        }
      }

      .error {
        color: #a4262c;
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }
}