@import "../../../../../UIKit/colorVars";

.headerNotifications {
  position: relative;
  // padding-top: 8px;
}

.bell {
  background-image: url("../../../../../assets/icons/notifications.svg");
  background-repeat: no-repeat;
  background-position: center center;
  width: 32px;
  height: 32px;
  position: relative;
  cursor: pointer;
  &.empty {
    cursor: not-allowed;
  }
}
.business {
  background-image: url("../../../../../assets/icons/business.svg");
  background-repeat: no-repeat;
  background-position: center center;
  width: 32px;
  height: 32px;
  position: relative;
  cursor: pointer;
  &.empty {
    cursor: not-allowed;
  }
}

.count {
  height: 16px;
  min-width: 16px;
  border-radius: 50%;
  background-color: red;
  color: $white;
  position: absolute;
  left: calc(50% - 16px / 2 + 8px);
  top: calc(50% - 16px / 2 - 8px);
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
}

.wrapDropList {
  position: absolute;
  bottom: -186px;
  right: 0;
  width: 350px;
  height: 184px;
  overflow-y: scroll;
  background-color: $white;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  z-index: 9999;
}

.wrapNotifications {
  display: flex;
  & > div:first-child {
    margin-right: 14px;
  }
}
