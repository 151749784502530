.milestonesWrap {
	margin-bottom: 16px;
	.title {
		color: rgba(0, 0, 0, 0.9);
		font-size: 14px;
		margin-bottom: 12px;
		font-weight: 500;
	}
	.milestones {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: flex;
		.milestone {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #ffffff;
			font-size: 12px;
			height: 40px;
			&:not(:last-child) {
				margin-right: 2px;
			}
			&:first-child {
				border-radius: 4px 0px 0px 4px;
			}
			&:last-child {
				border-radius: 0px 4px 4px 0px;
			}
		}
	}
}
