@import "../../../../UIKit/colorVars";

.customCard {
  padding: 8px !important;
  margin-bottom: 12px !important;

  &.white{
    &:hover {
      background: #f3f3f3 !important;
    }
    .projectName{
      color: rgba(0, 0, 0, 0.9);
    }
  }

  &.pink{
    &:hover {
      background: #fff5e1 !important;
    }
    .projectName{
      color: #B27E18;
    }
  }

  &.green{
    background: #F1FFEF !important;
    border: 1px solid #D3EDCA !important;

    &:hover {
      background: #F1FFEF !important;
    }

    .projectName{
      color: rgba(79, 138, 3, 0.9);
    }
  }

  .projectName {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
  .subTitleContainer {
    font-size: 12px;
    margin-bottom: 20px;

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: rgba(0, 0, 0, 0.55);;
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-top: 4px;
      }
      span {
        color: rgba(0, 0, 0, 0.55);
        margin-left: 8px;
        display: block;
        line-height: 18px;
        white-space: nowrap;
        &.currencyVal{
          margin-left: 0;
          span{
            display: inline-block;
          }
        }
      }
      .icon{
        cursor: pointer;
        height: 14px;
      }
    }
  }
  .footer{
    font-size: 12px;
    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: rgba(0, 0, 0, 0.55);
      &:not(:first-child) {
        margin-top: 4px;
      }
    }
    .text{
      margin-top: 10px;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
    }
    .tags{
      border-top: 1px solid #eee;
      padding-top: 6px;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
.hint{
  position: fixed;
  z-index: 20;
  padding: 7px 12px;
  background: #333333;
  border-radius: 2px;
  font-size: 14px;
  color: white;
  height: 35px;
}
