.wrap {
  .tab {
    min-width: 72px;
    width: auto;
  }
}

.emptyContent {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.01);

  .emptyNotification {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 30px;
  }
}