.dropdownMenu {
  position: absolute;
  top: calc(100% + 1px);
  background: white;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 12px 8px;
  list-style-type: none;
  width: 100%;
  z-index: 1;
  overflow-y: auto;
  max-height: 200px;
  overflow-x: auto;
}
