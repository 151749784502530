.errorMessage {
  color: #a4262c;
  font-size: 12px;
  margin-right: auto;
}

.radioField {
  margin-bottom: 30px;

  .radioGroupWrapper {
    display: flex;
    flex-direction: row;

    & > * {
      width: 49%;
      margin-right: 10px;
    }
  }
}