@import "../../../../../UIKit/colorVars";

.dropList {
  top: 38px;
  z-index: 10000;
}

.wrapDropListTrigger {
  position: relative;
}

.triggerWithLetter {
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.55);
  font-size: 16px;
  color: $white;
}
