.wrapper {
  height: 500px;
  width: 640px;
  padding: 30px 20px;
  display: flex;
  justify-content: space-around;

  p {
    margin-bottom: 0px !important;
  }
  header {
    margin-bottom: 20px;
  }
}

.checkboxMargin {
  margin-bottom: -30px;
}

.buttonMargin {
  margin-top: 45px !important;
}
