.wrap {
  padding: 24px 20px 95px;
  width: 100%;
  overflow-y: auto;
  height: 100%;

  .title {
    margin-bottom: 20px;
  }
}
