@import "../../../UIKit/colorVars";

.wrap,
.dropdown {
  width: 100%;
}

.label {
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 4px;
}

.errorMessage {
  color: #a4262c;
  font-size: 12px;
  margin-top: 5px;
  margin-right: auto;
}

.dropdown {
  position: relative;

  .placeholder {
    color: rgba(0, 0, 0, 0.44);
    font-size: 14px;
  }

  .header {
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 11px;
    max-height: 32px;
    cursor: pointer;

    .arrow {
      transition: 0.2s all ease;
    }
  }

  .dropWrap {
    position: absolute;
    display: flex;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 90;
    background: #FFFFFF;
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    max-height: 176px;
    height: fit-content;
    overflow-y: auto;
    padding: 12px 24px;
  }

  .colorItem {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  &.opened {
    .header {
      .arrow {
        transform: rotate(180deg);
      }
    }
  }

  &.disabled {
    .dropWrap {
      display: none;
    }

    .header {
      background: #F4F4F4;
      border: 1px solid #C8C8C8;
      cursor: not-allowed;
    }

    .arrow path {
      fill: rgba(0, 0, 0, 0.38)
    }
  }

  &.focused {
    .header {
      border: 1px solid #4F78E0;
    }
  }

  .selectedValue {

  }
}
