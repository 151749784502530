.title {
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 8px;
  position: relative;
  width: fit-content;

  & > sup {
    color: #2F78CD;
    font-size: 17px;
    position: absolute;
    top: -3px;
  }
}