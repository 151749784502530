@import "../../../../../UIKit/colorVars";
.chartWrapper {
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 25px;
  background: $white;
  border-radius: 4px;
  min-height: 229px;
  li {
    list-style: none;
  }
  span {
    font-weight: 500;
  }
}

.ownerName {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: $textDark;
  padding: 20px 20px 22.5px;
}

.chartValues {
  padding-bottom: 12.5px;
  position: relative;
  display: flex;
  border-bottom: 1px solid $menuItem;
}
.chartBars {
  padding: 6px 0 4px 0;
}
.chartValues li {
  font-weight: 500;
  font-size: 12px;
  color: $textLight;
  flex: 1;
  text-align: center;
}

.chartValues li:not(:last-child) {
  position: relative;
}

.chartValues span {
  position: absolute;
  border-right: 1px dashed #ecf0f4;
  top: 30.5px;
}

.chartBars li {
  position: relative;
  color: $textLight;
  margin-bottom: 1px;
  font-size: 12px;
  border-radius: 2px;
  padding: 3px 8px;
  width: 0;
  // transition: all 0.65s linear 0.2s;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
