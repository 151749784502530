.wrap {
  width: 100%;
  overflow-y: auto;
  padding: 24px 24px 95px;

  .title {
    margin-bottom: 13px;
    font-weight: 500;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.9);
  }

  .details {
    margin-bottom: 40px;
  }

  .statistic {
    margin-bottom: 40px;

    .subtitle {
      color: rgba(0, 0, 0, 0.9);
      font-size: 18px;
      font-weight: 500;
    }

    .wrap {
      display: flex;
      justify-content: space-between;
      margin: 12px 0 20px;
      padding: 0;
      list-style: none;
    }
  }

  .projects,
  .suppliers {
    margin-bottom: 40px;
  }
}
