.field {
  margin-bottom: 10px;
}

.groupField {
  display: flex;
  gap: 20px;

  > div {
    width: 100%;
  }
}