.rightSidebar {
  margin: 0;
  padding: 0;
  list-style: none;
  background: white;
  flex: 1;
  border-left: 1px solid #e0e0e0;
  position: relative;
  height: 100%;
  max-width: 32px;
}
