@import '../../../landingUi/landingColors.scss';

.thank {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  }
  
  .tnankTitle {
	color: #333d66;
	font-weight: bold;
	font-size: 36px;
	line-height: 110%;
	margin-bottom: 80px;
	padding-top: 170px;
	text-align: center;
	@media (max-width: 1199px) {
	  padding-top: 0;
	  margin-bottom: 48px;
	}
  }
  
  .thankText {
	color: rgba(0, 0, 0, 0.9);
	font-size: 20px;
	line-height: 150%;
	margin-bottom: 24px;
	text-align: center;
	max-width: 467px;
	&:last-of-type {
	  margin-bottom: 0;
	}
  }