@import "../../../UIKit/index";

*,
*::before,
*::after {
  font-family: "Roboto", sans-serif;
}

.title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 6px;
}

.labelInputResetPassword {
  font-size: 12px;
  margin-bottom: 16px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inputWrap {
  margin-bottom: 16px;
  input {
    padding-right: 42px !important;
  }
}

.inputReenterWrap {
  margin-bottom: 40px;
}

.buttonWrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 48px;
}

.cancelButton {
  margin-right: 4px;
}

.cancelLink {
  color: rgba(0, 0, 0, 0.9);
  background-color: $buttonSecondary;
  &:hover {
    background-color: $buttonSecondaryHover;
    color: rgba(0, 0, 0, 0.9) !important;
  }
  &:active {
    background-color: $buttonSecondaryActive;
    color: rgba(0, 0, 0, 0.9) !important;
  }
}
