.popup{
  min-height: auto;
  h1{
    margin-bottom: 0;
  }
}
.notes{
  margin: 0;
  list-style-type: none;
  padding-bottom: 22px;
  .note:nth-child(2) {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid #C6C6C6;
  }
}
