.inputBlock{
    display: flex;
    align-items: end;
    padding-top: 10px;
    padding-bottom: 95px;

.blockAdminContracts{

    & > .toggle {
    transition: all 0.25s ease;
    margin-left: -30px;
    transform: rotate(0deg);
    }

     & > .toggleRotate {
       transform: rotate(180deg);
     }

}
 .customInput {
    width: 250px;
    height: 32px;
    border: 1px solid #dadada;
    border-radius: 2px;
    padding-left: 11px;
    &:focus {
      border: 1px solid #4f78e0;
      outline: none;
    }
    &.fullInput {
      width: calc(100% - 7px);
    }
    .customInputDays{
        max-width: 87px;
    }
  }
.inputReceiveDays{
    max-width: 87px;
    height: 32px;
    border: 1px solid #dadada;
    border-radius: 2px;
    padding-left: 11px;
   &:focus {
        border: 1px solid #4f78e0;
            outline: none;
    }
    &.fullInput {
        width: calc(100% - 7px);
   }
}
.changeCurrency {
	width: 100%;
	max-width: 87px;
}
}

