.content {
  position: relative;
  height: 443px;
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C4C4C4;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.announcementsWrapper {
  padding: 17px 0 0 0;

  .header {
    padding: 0 17px;
  }

  & > div:first-child {
    display: flex;
    justify-content: space-between;
  }

  .content {
    margin-top: 15px;

    .announcement {
      display: flex;
      padding: 12px 17px;
      justify-content: space-between;

      &:hover {
        background: #F3F3F3;
      }

      .message {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        padding-right: 20px;
        margin-bottom: 5px;
        word-break: break-word;
      }

      .date {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
      }
    }
  }
}
