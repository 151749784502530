@import "../../../../../../../UIKit/colorVars";
.item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  height: 27px;
  & > .icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    path {
      fill: $textDark;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
  }
}
