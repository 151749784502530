@import "UIKit/_colorVars.scss";

.sidebar {
  width: 27%;
  margin-left: 20px;
}

.wrapper {
  background: $white;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 17px;
  margin-bottom: 20px;

  .title {
    margin-bottom: 14px;
  }
}

.messages{
  margin-bottom: 20px;
}