@import "../../../UIKit/colorVars";

.wrapInput {
  display: flex;
  flex-direction: column;

  .field,
  .field[type="search"] {
    background-color: $white;
    border: 1px solid #dadada;
    border-radius: 2px;
    outline: none;
    height: 32px;
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    cursor: text;
    padding: 0 12px;
    font-family: "Roboto", sans-serif;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      font-weight: normal;
      font-size: 14px;
      color: rgba(#000000, 0.4);
    }

    &:hover {
      border: 1px solid #a6a6a6;
    }

    &:focus {
      border: 1px solid #4f78e0;
    }
  }

  &.disabled input {
    pointer-events: none;
    background-color: #f4f4f4;
    border: 1px solid $darkGrey;
    color: rgba(0, 0, 0, 0.38);

    &::placeholder {
      color: rgba(0, 0, 0, 0.38);
    }
  }

  &.authField input {
    border: none;
    border-bottom: 1px solid rgba(#000000, 0.6);

    &:focus {
      border: none;
      border-bottom: 1px solid #0067b8;
    }
  }

  &.withIcon::placeholder input {
    background-image: url("../../../assets/icons/search.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 center;
    padding-left: 24px;
  }

  .bottomWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;

    .errorMessage {
      color: #a4262c;
      font-size: 12px;
      margin-right: auto;
    }

    .counterWrap {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.55);
      font-weight: normal;
      font-size: 12px;

      .counter.error {
        color: #a4262c;
      }
    }
  }
}

.inputLabelContainer {
  display: flex;
  flex-direction: row;
}

.inputLabel {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 4px;
}

.inputLabelGrey {
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  margin-bottom: 4px;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.55);
}
