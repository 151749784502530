@import "../../../UIKit/index";

.projectsListContainer {
  padding: 24px 20px 0 10px;
  width: 100%;
  overflow-y: auto;
  height:100%;

  .pieChartWidget,
  .savingsChart {
    margin-bottom: 24px;
    margin-left: 10px;
    width: 100%;
  }

  .pieChartWidget,
    .EmptyChart {
      margin-bottom: 24px;
      margin-left: 10px;
      width: 100%;
      height:290px;
    }

  .pieChartLoader {
    margin-bottom: 24px;
  }

  .utilizationForecastWidget,
  .tabs,
  .filterBar {
    margin-left: 10px;
  }
}

.addProjectBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  margin-left: 10px;
}

.title {
  font-weight: 500;
  font-size: 21px;
}

.wrapTabs {
  margin-bottom: 20px;
}

.emptyBlock {
  margin-top: 20px;
}

.dropdownWrapper {
  // margin-top: -15px;
}

.editPopup {
  margin-top: 159px;
  display: flex;
  justify-content: flex-end;
}

.errorNotification {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  svg {
    margin-bottom: 20px;
  }

  p {
    white-space: break-spaces;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.9);
  }
}