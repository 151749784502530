.outerDivStyle {
  overflow-x: auto;
  overflow-y: hidden;
}
.noOverflow {
  overflow: hidden;
}
.mainWrapper {
  margin-top: -21px;
}
.scrollbar {
  overflow-x: scroll;
  &::-webkit-scrollbar-track {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
}
