@import "../../../UIKit/colorVars";

.labelArea {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 4px;
}

.description {
  width: 100%;
  resize: none;
  background: $white;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 2px;
  height: 95px;
  padding: 5px 11px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-weight: normal;
    font-size: 14px;
    color: rgba(#000000, 0.4);
  }
}

.bottomWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .counterWrap {
    color: rgba(0, 0, 0, 0.55);
    font-size: 12px;

    .count.error {
      color: #a4262c;
    }
  }
}

.errorMessage {
  color: #a4262c;
  font-size: 12px;
  margin-right: auto;
  display: block;
}

.disabled {
  pointer-events: none;
  background-color: #f4f4f4;
  border: 1px solid $darkGrey;

  &::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}