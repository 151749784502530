.wrap {
  margin-top: 40px;

  .nonFinancialBenefits {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.01);
    border-radius: 4px;
    background: white;
    position: relative;
    padding-bottom: 20px;

    .nonFinancialBenefitsContainer {
      padding: 20px 20px 0;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
      }
    }

    .additionsContainer {
      padding: 16px 20px 0;
      margin-bottom: 8px;

      .content {
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.9);
        opacity: 0.87;
        max-width: 870px;
      }
    }

    .inlineWrap {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 20px;

      .col {
        width: 24%;

        h4 {
          font-weight: 500;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
          margin-bottom: 5px;
        }

        .typeList {
          display: flex;
          flex-direction: column;

          .checkbox {
            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}
