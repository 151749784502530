.dropDown {
  width: 100%;
  background: #F8F8F8;
  border-radius: 2px;
  padding: 8.5px 12px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  .header {
    display: flex;
    justify-content: space-between;

    & > div:first-child {
      display: flex;
    }

    .title {
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
    }

    .status {
      padding: 2px 8px;
      font-size: 12px;
      color: #fff;
      text-transform: capitalize;
      border-radius: 4px;
      margin-left: 15px;

      &.new {
        background: #5B79B4;
      }

      &.completed {
        background: #83A755;
      }
    }

    & > button {
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}