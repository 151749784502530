.widgetWrap {
  display: flex;
  gap: 10px;

  .infoWrap {
    width: 100%;
    max-height: 170px;
    overflow-y: scroll;

    .singleInfo {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;

        p {
          font-weight: 500;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.9);
        }
      }

      .progress {
        width: 100%;
        height: 4px;
        border-radius: 2px;
        background: #F3F4F6;
        overflow: hidden;

        > div {
          height: 100%;
        }
      }
    }

    .dotsList {
      width: 100%;

      .dotsWrapper {
        display: flex;
        justify-content: space-between;
        overflow-wrap: normal;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-top: 5px;
        }
      }
    }
  }
}