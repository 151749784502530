.maxCoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 36px;
  > div {
    border: 1px solid #dadada;
    padding: 5px 12px;
  }
  div:nth-child(1),
  div:nth-child(3) {
    background-color: #eaeaea;
  }
}

.newColorConrainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 270px;
  height: 27px;
  box-shadow: 0px 0.5px 1px RGB(0 0 0 / 10%), 0px 3px 5px RGB(0 0 0 / 11%),
    0px -1px 10px RGB(0 0 0 / 2%), 0px -0.5px 4px RGB(0 0 0 /5%);
}

.statusName {
  cursor: pointer;
  &:focus {
    outline: none !important;
  }
  &:hover {
    text-decoration: underline;
  }
  &[contenteditable]:focus {
    text-decoration: underline;
  }
}

.error {
  width: 100%;
  height: 40px;
  background-color: #fdcbcb;
  display: flex;
  align-items: center;
  margin: -20px 0px 20px 0px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;

  svg {
    margin: 0px 10px 0px 20px;
  }
}
