.wrap {
  width: 100%;
  overflow-y: auto;
  padding: 24px 24px 95px;
  .title {
    margin-bottom: 13px;
    font-weight: 500;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.9);
  }
  .details {
    margin-bottom: 20px;
  }
  .projects,
  .suppliers {
    margin-bottom: 40px;
  }
}
