.scoreWrap {
  background: white;
  display: flex;
  padding: 20px 7px 25px 25px;
  width: calc(50% - 10px);
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
    0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11),
    0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .gaugeChartWrap {
    width: 200px;
    .title,
    .percent {
      color: rgba(0, 0, 0, 0.9);
      font-weight: 500;
      display: block;
    }
    .title {
      font-size: 15px;
      margin-bottom: 20px;
      display: block;
    }
    .percent {
      text-align: center;
      font-size: 21px;
    }
  }
  .gaugeChart {
    svg {
      height: 108px;
    }
  }
  .columnWidgetWrap {
    margin-left: 24px;
    padding-left: 24px;
    border-left: 1px dashed rgba(0, 0, 0, 0.55);
  }
}
