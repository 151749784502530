.emptyNotice {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noticeItem {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 8px;
  text-decoration: none;

  .iconWrap {
    min-width: 12px;
  }

  & > p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    margin-left: 8px;
  }
}
