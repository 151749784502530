.emptyState {
  margin-top: 4px;
  text-align: center;
  .title {
    color: rgba(0, 0, 0, 0.55);
    font-size: 14px;
    display: block;
    margin-bottom: 24px;
  }
}
