@import "../../../UIKit/colorVars";

.title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 16px;
}

.description {
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 18px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inputWrap {
  margin-bottom: 16px;
  input {
    padding-right: 42px !important;
  }
}

.passwordState {
  font-weight: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
  margin-top: 7px;
}

.buttonsWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 48px;
}

.saveButton {
  margin-left: 4px;
}
