.container {
  display: flex;
  align-items: center;
  p::first-letter {
    text-transform: capitalize;
  }
  .status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #df0505;
    margin-right: 8px;
    &.completed {
      background-color: #0d9d4f;
    }
    &.progress {
      background-color: yellow;
    }
  }
}
.nameColumn {
  td:nth-child(1) {
    width: 509px;
  }
}
.note{
  cursor: pointer;
  &.disabled{
    cursor: default;
    path{
      fill: #C8C8C8
    }
  }
}
