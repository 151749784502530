@import "../../../UIKit/colorVars";

.addProjectForm {
  .field {
    margin-bottom: 16px;
  }
  .titleField {
    margin-bottom: -2px;
    &.projectTitleWrap {
      margin-bottom: 16px;
    }
  }
}
