@import "../../../../../UIKit/colorVars";

.activityItem {
  width: 148px;
  height: 105px;
  background: white;
  padding-left: 8px;
  pointer-events: all;
  cursor: default;
  position: absolute;
  z-index: 0;
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.success {
      background: #005a9e;
    }
    &.reject {
      background: #cd1717;
    }
    &.pending {
      background: #c8c8c8;
    }
  }
  .name,
  .assignedTo,
  .status {
    display: block;
    line-height: 1.5;
    margin-top: 2px;
  }
  .name {
    color: rgba(0, 0, 0, 0.9);
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
  }
  .assignedTo,
  .status {
    font-size: 12px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.55);
  }
  .hintWrap {
    position: absolute;
    background: $textHeaders;
    border-radius: 2px;
    padding: 7px 12px;
    font-weight: 500;
    font-size: 14px;
    color: white;
    width: auto;
    width: 200px;
    z-index: 999999 !important;
    span {
      display: block;
      width: 100%;
      word-break: break-all;
      text-transform: capitalize;
    }
  }
}
