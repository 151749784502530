.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 20px 20px;
  overflow-y: auto;
  height:100%;

.table {
    box-shadow: none;
    * {
      white-space: nowrap;
    }
    thead:first-child {
      display: table-caption !important;
      span {
        display: none;
      }
    }
    thead tr th:last-child {
        div{
            display: flex;
            top: 8px;
        }
    }
    tbody tr  {
        &:hover td{
            background: #F3F3F3;
            path {
                display: block !important;
            }
        }
    }
  }
}