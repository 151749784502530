.header {
  margin-bottom: 25px;
}

.infoWrap {
  display: flex;
  flex-direction: column;

  .infoBlock {
    margin-bottom: 16px;

    .title {
      font-weight: 500;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.55);
      margin-bottom: 4px;
    }

    .value {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .infoBlockGroup {
    display: flex;

    & > div {
      width: 50%;
      margin-bottom: 0;
    }
  }
}