.container {
  padding: 24px 20px 95px;
  width: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  overscroll-behavior: none;
  overflow-anchor: none;

  .title {
    font-weight: 500;
    font-size: 21px;
    margin-bottom: 20px;
  }
  .filter {
    margin-bottom: 24px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 95px;
  }
}
