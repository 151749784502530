.player {
	width: 100%;
	// height: 100%;
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.reactPlayer {
	position: absolute;
	top: 0;
	left: 0;
}

.playButton {
  border: none;
  outline: none;
  padding: 0;
  width: 64px;
  height: 64px;
  border-radius: 50%;
	background-color: #EAF4FF;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 48px;
    height: 48px;
  }
}
