.generalTitle {
  margin-bottom: 20px;
}

.labelCheckbox {
  display: flex;
}

.inputSpan {
  margin-left: 7px;
}

.categoryLevels {
  margin-bottom: 20px;

}
.categoryLevelsLabel{
  margin-bottom: 7px;
}

.categoryLevelsItem {
  width: 50%;
  margin-left: -4px;
  display: flex;
  flex-direction: column;
}

.categoryLevelsAfterButtonText {
  margin-left: 16px;
  opacity: 0.5;
}