.projectLine {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  white-space: nowrap;
  background: #FFF6E1;
  border: 1px solid #FAEACC;
  border-radius: 2px;
  cursor: pointer;
  min-height: 24px;

  .name, .threeDots {
    color: rgba(0, 0, 0, 0.9);
    display: block;
    margin-right: 7px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .threeDots {
    margin-right: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: -1;
  }

  .dates {
    display: block;
    margin-left: 5px;
    color: rgba(0, 0, 0, 0.55);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .iconWrap {
    cursor: pointer;
    flex: 1 0 auto;
    position: relative;
    max-width: 14px;
    // z-index: 101;
    svg {
      display: block;
      width: 14px;
      height: auto;
      max-width: 14px;

      path {
        fill: rgba(0, 0, 0, 0.55);
      }
    }

    .dates {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      right: 100%;
      bottom: -50%;
      z-index: 110;
      background: #333333;
      border-radius: 2px;
      width: 123px;
      padding: 7px 12px;
      transition: all 0.5s ease;

      span {
        display: block;
        font-size: 14px;
        line-height: 150%;
        color: white;

        &:nth-child(3) {
          margin-top: 18px;
        }
      }
    }

    &:hover {
      .dates {
        visibility: visible;
        opacity: 1;
        transition-delay: 0.5s;
      }
    }

  }

  &.first {
    .iconWrap .dates {
      bottom: auto;
      top: 50%;
    }
  }

  &.last {
    .iconWrap .dates {
      bottom: -30%;
      top: auto;
    }
  }

  &.small {
    .threeDots {
      opacity: 1;
      visibility: visible;
      position: static;
      z-index: 1;
    }

    .name {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      z-index: -1;
    }

    & > .dates, .iconWrap {
      display: none;
    }
  }

  &.empty {
    .threeDots {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      z-index: -1;
    }
  }

  &.small,
  &.empty {
    .iconWrap {
      position: static;
    }

    &:hover {
      .dates {
        visibility: visible;
        opacity: 1;
        transition-delay: 0.5s;
      }
    }
  }
}
