@import "../../../UIKit/colorVars";

.categoriesContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;

  .filterbar {
    margin-bottom: 20px;
  }

  .container {
    width: 100%;
    overflow: hidden;
    padding: 20px 20px 95px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    overscroll-behavior: none;
    overflow-anchor: none;

    .title {
      margin-bottom: 20px;
    }

    .row {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
