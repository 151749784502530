.table {
  margin-bottom: 20px;
  .title {
    margin-top: 40px;
    margin-bottom: 12px;
    font-weight: 500;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
