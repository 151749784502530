@import "../../../../../landingUi/landingColors.scss";

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  width: 411px;
  height: 402px;
  @media (max-width: 899px) {
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 32px;
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    min-height: 402px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.img {
  margin-bottom: 16px;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

.title {
  color: $title;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 16px;
  max-width: 244px;
}

.text {
  font-size: 18px;
  line-height: 150%;
  color: $textLigtht;
  white-space: break-spaces;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .text {
    width: 100%;
  }
}
