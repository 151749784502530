.wrap {
  width: 100%;
  margin-bottom: 25px;
  overflow-x: hidden;
  overflow-y: hidden;
  background: #fff;
  padding: 18px 16px;

  .inlineWrap {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 12px;
  }

  .dates {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .date {
      color: rgba(0, 0, 0, 0.55);
      font-size: 12px;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;

  .title {
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
    font-weight: 500;
  }

  .btns {
    display: flex;

    .btn {
      min-width: 80px;

      &:first-child {
        margin-right: 8px;
      }
    }
  }

  .editBtn {
    cursor: pointer;
  }
}

.emptyBlock {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EAEAEA;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;

  span {
    display: block;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    font-size: 12px;
  }
}