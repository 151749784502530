.field {
  margin-bottom: 25px;

  > p {
    margin-bottom: 5px;
  }
}

.dndContainer {
  display: flex;
  flex-direction: column;

  .dndList {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hierarchyItemWrap {
    display: flex;
    align-items: center;

    > svg {
      transform: rotate(90deg);
      margin-right: 5px;
    }

    .hierarchyItem {
      width: 160px;
      justify-content: space-between;
      padding: 10px;
      border: 1px solid #DADADA;
      border-radius: 2px;
      display: flex;
      align-items: center;

      > svg {
        cursor: pointer;
      }
    }
  }
}

.ignoreContainer {
  .ignoreList {
    border: 1px dashed #C8C8C8;
    border-radius: 2px;
    padding: 16px 12px;
    box-sizing: content-box;
    height: 46px;
  }
}

.labelOptional {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 5px;

  span {
    color: rgba(0, 0, 0, 0.55);
  }
}