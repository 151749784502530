@import "../../../../../UIKit/colorVars";

.sidebar {
  max-width: 240px;
  width: 240px;
  border-right: 1px solid #c6c6c6;
  height: 100%;
  display: flex;
  flex-direction: column;
}
