.secWrapper {
  width: 100%;
  background-color: #607d8b;
  height: 82px;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;

  section {
    width: 100%;
    height: 76px;
    background-color: #ffffff;
    border-radius: 0px 0px 7px 7px;
    border: 1px solid #eaeaea;

    .stepName {
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
      margin: 10px 0px 0px 30px;
    }

    .stepDescription {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      margin: 10px 0px 0px 30px;
    }
  }
}

.detailsWrapper {
  width: 100%;
  background-color: #2f78cd;
  height: 330px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  &.opened {
    width: 100%;
    background-color: #2f78cd;
    height: 50px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    section {
      width: 100%;
      height: 50px;
      background-color: #ffffff;
      border-radius: 0px 7px 7px 0px;
      border: 1px solid #eaeaea;
    }
  }

  section {
    width: 99.6%;
    height: 330px;
    background-color: #ffffff;
    border-radius: 0px 7px 7px 0px;
    border: 1px solid #eaeaea;
  }

  .tabs {
    width: 80%;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;

    .tab {
      margin: 0px 20px;
      cursor: pointer;

      &.active {
        font-weight: 500;
        border-bottom: 2px solid #2f78cd;
      }
    }
  }
}

.addNewSec {
  cursor: pointer;
  padding-left: 20px;
  width: 100%;
  height: 36px;
  margin: 20px 0px;
  background-color: #ffffff;

  &.dropdown {
    header,
    div {
      width: 140px;
    }
  }
}

.error {
  width: 100%;
  height: 40px;
  background-color: #fdcbcb;
  display: flex;
  align-items: center;
  margin: -20px 0px 20px 0px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;

  svg {
    margin: 0px 10px 0px 20px;
  }
}
