.historyItem {
  padding-bottom: 23px;

  &:not(:first-child) {
    margin-top: 23px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #C6C6C6;
  }

  .itemHeader {
    display: flex;
    margin-bottom: 5px;

    p {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.55);

      &:first-child {
        margin-right: 5px;
      }
    }
  }

  .attachments {
    display: flex;
    cursor: pointer;

    > li {
      margin-top: 0 !important;
    }
  }

  .note {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    opacity: 0.87;
    margin-bottom: 5px;
    white-space: normal;
    word-wrap: break-word;
  }
}