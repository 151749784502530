.categoriesWrapper {
  padding: 17px 0 0 0;

  .title {
    padding: 0 20px;
  }

}

.content {
  height: 250px;
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C4C4C4;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
