@import "../../../../../UIKit/colorVars";

.dropzone {
  border: 1px dashed $darkGrey;
  min-height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  margin-bottom: 7px;

  &.disabled {
    background: #f4f4f4;
    cursor: default;
  }

  .dropFied {
    display: flex;
    align-items: center;
  }

  .title {
    display: block;
    color: rgba(0, 0, 0, 0.55);
    font-size: 14px;
    text-align: center;
    margin-left: 8px;

    .link {
      color: #2F78CD
    }
  }

  &.error {
    background: #fff4f5;
    border: 1px dashed #a4262c;
    padding: 12px 0;

    .message {
      color: rgba(0, 0, 0, 0.55);
      font-size: 14px;
      margin-top: 8px;
      text-align: center;
      margin-bottom: 16px;

      span {
        display: block;
      }
    }

    .link {
      color: rgba(0, 0, 0, 0.9);
      font-size: 14px;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}