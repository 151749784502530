.questionsContent {
  .addNewQuestion {
    width: 100%;
    box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05), 0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11), 0px 0.5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    color: rgba(0, 0, 0, 0.55);
    font-size: 14px;
    display: flex;
    justify-content: end;
    align-items: center;
    background: #FFF;
    border: none;
    text-transform: none;
    margin-top: 24px;

    .iconPlus {
      margin-right: 7px;
    }
  }

  .question {
    padding: 20px;
    box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05), 0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11), 0px 0.5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: #FFFFFF;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    .dragIcon {
      display: block;
      margin: 0 auto;
      width: 15px;
    }

    .questionWrap {
      display: flex;
      flex-direction: column;
    }

    .titleQuestion {
      font-weight: 500;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.9);
      margin-bottom: 8px;
      position: relative;

      sup {
        color: #2F78CD;
        font-size: 17px;
        position: absolute;
        top: -3px;
      }
    }

    .radioGroup {
      width: fit-content;
    }

    .newRadio {
      font-size: 14px;
      background: #fff;
      color: rgba(0, 0, 0, 0.55);
      cursor: pointer;
      border: none;
      padding: 0;

      img {
        margin-right: 2px;
      }
    }

    .input {
      border-radius: 3px;
      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &.customMargin {
        margin-bottom: 40px;
      }

      &.padding {
        padding-top: 22px;
      }

      &.withTitle {
        padding-top: 0;
      }
    }

    .contentWrapper {
      display: flex;
      justify-content: space-between;

      .controlPanel {
        width: 49%;
        padding-top: 30px;
      }

      .questionContent {
        width: 49%;
      }
    }

    .controlPanelFooter {
      display: flex;
      width: 100%;
      margin-top: 32px;
      border-top: 1px solid #C6C6C6;
      padding-top: 12px;
      justify-content: flex-end;
      align-items: center;
      height: 40px;

      .delete {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
      }

      .verticalDivide {
        width: 1px;
        height: 100%;
        background: #C6C6C6;
        margin: 0 15px;
      }
    }

    .answerWrapper {
      position: relative;

      .deleteAnswer {
        position: absolute;
        right: -32px;
        top: 4px;
        border: none;
        background: none;
        cursor: pointer;

        & > svg {
          width: 20px;
          height: 20px;

          & > g path {
            fill: rgba(0, 0, 0, 0.55);
          }
        }
      }
    }
  }
}