@import "../../../landingUi/landingColors.scss";

.card {
  display: flex;
  width: 1124px;
  height: 178px;
  padding-left: 110px;
  margin-bottom: 64px;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: 1399px) {
    padding-left: 0;
    width: 100%;
    height: auto;
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.img {
  margin-right: 53px;
  @media (max-width: 1399px) {
    margin-right: 24px;
  }
  @media (max-width: 767px) {
    margin-right: 0;
  }
}

.content {
  padding-top: 10px;
  @media (max-width: 767px) {
    padding-top: 8px;
  }
}

.title {
  color: $title;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 8px;
}

.text {
  font-size: 18px;
  line-height: 150%;
  color: $textDark;
  white-space: break-spaces;
}
