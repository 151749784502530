@import "../../../../../UIKit/colorVars";

.item {
	display: flex;
	align-items: center;
	margin-bottom: 11px;

	.arrowButton {
		cursor: pointer;
		height: 21px;
		&.isHiddenArrow {
			visibility: hidden;
		}
		&.isClosed {
			transform: rotate(-90deg);
		}
	}
	svg {
		transform: scale(0.7);
	}
	p {
		font-size: 14px;
		margin-left: 13px;
		max-width: 80%;
	}
	.label {
		margin-left: 4px;
		color: $button;
	}
}
