@import "../../../UIKit/colorVars";

.link {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  color: $link;
  transition: all 0.3s ease;
  font-family: 'Roboto', sans-serif;
  &:hover {
    color: $linkHover;
  }
}
