@import "../../landingUi/landingMain.scss";

.blogPage {
  margin-top: 64px;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 1260px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
