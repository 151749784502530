.tooltip {
  padding: 3px 8px;
  border: 1px solid #e5e5e5;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 1000000000000;
  background: white;
  transform: translate(-50%, calc(-100% - 8px));

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: calc(50% - 5px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #ffffff transparent transparent transparent;
  }

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: calc(50% - 6px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #e5e5e5 transparent transparent transparent;
  }
}

.title {
  margin-top: 20px;
  color: #0000008c;
  font-size: 11px;
  line-height: 16px;
}

.item {
  display: flex;
  align-items: flex-end;
  height: 100px;
  width: 40px;
}

.quater {
  display: flex;
  align-items: flex-end;
  height: 100px;
  width: 20px;
  margin: 0px 1px;

  .quaterChild {
    width: 19px;
    background-color: #5b79b4;
    z-index: 100000;
    cursor: pointer;
    max-height: 100%;
  }
}

.container {
  width: 140px;
  display: flex;
  flex-direction: row;
}
