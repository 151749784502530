@import '../../../UIKit/colorVars';

.date {
  width: 100%;
}

.inputWrap {
	width: 288px;
	margin-bottom: 16px;
}

.errorMessage {
	color: #a4262c;
	font-size: 12px;
	margin-top: 5px;
	margin-right: auto;
}

.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	[class='react-datepicker__year-read-view--down-arrow'] {
		margin-left: 42px !important;
	}
}
