
.informationFirst {
  margin-top: 40px;
  margin-bottom: 8px;
}

.informationSecond {
  margin-top: 4px;
  margin-bottom: 8px;
}
