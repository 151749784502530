.form {
  .tabs {
    display: flex;
    margin-bottom: 24px;
    .tab {
      height: 36px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      cursor: pointer;
      &.active {
        border-bottom: 2px solid #2f78cd;
        font-weight: 500;
      }
    }
  }
}
