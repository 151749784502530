@import "../../landingUi/landingColors.scss";

.contactUs {
  display: flex;
  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
  }
}

.wrapInfoBlock {
  width: 50%;
  background-color: $lightBlue;
  padding-top: 100px;
  padding-bottom: 100px;
  
  @media (max-width: 1199px) {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media (max-width: 767px) {
    padding-bottom: 82px;
  }
}



.wrapFormBlock {
  width: 50%;
  max-width: 640px;
  margin-right: auto;
  @media (max-width: 1400px) {
    padding-right: 24px;
  }
  @media (max-width: 1199px) {
    width: 100%;
    margin-right: 0;
    max-width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.formBlock {
  max-width: 640px;
  margin-right: auto;
  padding-left: 40px;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 1199px) {
    padding-left: 0;
    padding-top: 80px;
    padding-bottom: 80px;
    max-width: 100%;
    margin-right: 0;
  }
  @media (max-width: 767px) {
    padding-top: 72px;
  }
}
