@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.slick-dots {
	position: absolute;
	bottom: -35px;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	li {
	  position: relative;
	  display: inline-block;
	  width: 18px;
	  height: 18px;
	  margin: 0;
	  padding: 0;
	  cursor: pointer;
	  button {
		font-size: 0;
		line-height: 0;
		display: block;
		width: 100%;
		height: 100%;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		background: transparent;
		&::before {
		  font-family: "slick";
		  font-size: 6px;
		  line-height: 20px;
		  position: absolute;
		  top: 0;
		  left: 0;
		  width: 10px;
		  height: 10px;
		  background-color: #c8c8c8;
		  border-radius: 50%;
		  content: "";
		  text-align: center;
		}
	  }
	}
	li.slick-active button:before {
	  background-color: #333D66;
	  color: black;
	}
  }
