.detailItem {
  display: flex;
  align-items: center;
  .label {
    width: 150px;
    display: block;
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.55);
  }
  .emptyValue,
  .value {
    font-weight: normal;
    font-size: 14px;
  }
  .emptyValue {
    color: rgba(0, 0, 0, 0.55);
  }
  .value {
    color: rgba(0, 0, 0, 0.9);
    width: calc(100% - 150px);
    display: block;
    span {
      color: rgba(0, 0, 0, 0.55);
    }
  }
}
