.totalTimeWrap {
  background: white;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
    0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11),
    0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px 40px 40px 24px;
  width: calc(50% - 10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    color: rgba(0, 0, 0, 0.9);
    font-size: 15px;
    display: block;
    margin-bottom: 40px;
    font-weight: 500;
  }
  .totalChartWrap {
    position: relative;
    display: inline-block;
    .totalWidgetLabel {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: rgba(0, 0, 0, 0.9);
      font-size: 21px;
      font-weight: 500;
    }
  }
  .itemsStatus {
    .item {
      width: 240px;
      &:not(:first-child) {
        margin-top: 24px;
      }
      .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        color: rgba(0, 0, 0, 0.9);
        height: 21px;
        margin-bottom: 4px;
        .label {
          font-size: 14px;
        }
        .value {
          font-size: 12px;
          font-weight: 500;
        }
      }
      .line {
        width: 100%;
        background: #f3f4f6;
        border-radius: 2px;
        height: 4px;
        position: relative;
        .percent {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          display: block;
          z-index: 2;
        }
      }
    }
  }
}
