.addContractForm {
  background: #FFFFFF;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02),
  0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;

  .half {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > * {
      width: 49%;
      margin-bottom: 15px;
    }

    &.fixHeight {
      & > div {
        textarea {
          height: 56px;
        }

        > div {
          height: 56px;
        }
      }
    }
  }

  .formContent {
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;

      h3 {
        font-weight: 500;
        font-size: 21px;
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
}