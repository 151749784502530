@import 'UIKit/_colorVars.scss';

.main {
  width: 77%;

  &.full {
    width: 100%;
  }
}

.wrapper {
  background: $white;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 17px;

  .title {
    margin-bottom: 14px;
  }
}

.group {
  display: flex;
  height: 276px;
  margin-bottom: 20px;

  &.high {
    height: 400px;
  }

  & > section {
    width: 50%;
    height: 100%;

    &:first-child {
      margin-right: 20px;
    }
  }
}

.filter {
  margin: 40px 0 20px 0;
}

.ganttChart {
  margin-top: 20px;
}

.savingsGoal {
  height: 320px;
  margin-bottom: 20px;
}

.footerInfo {
  display: flex;
  margin-top: 20px;

  & > div:first-child {
    width: 30%;
    margin-right: 20px;
  }

  & > section {
    width: 70%;
  }
}

.staticWidgets {
  display: flex;

  & > section {
    width: 33.33%;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.categories {
  margin-top: 20px;
}

.suppliers {
  margin-top: 20px;
}

.table {
  .tableHead .tableCell {
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.55);
    background: #fff;
  }

  .tableBody .tableRow .tableCell {
    &:first-child a {
      font-size: 14px;
      color: #005a9e;
      opacity: 0.87;
      text-decoration: none;
    }

    &:not(:first-child) {
      font-size: 14px;
      opacity: 0.87;
    }

    & > .cellWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .tableCell {
    padding: 10.5px 22px;
    border-bottom: 1px solid #eaeaea;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;

    .currency {
      color: rgba(0, 0, 0, 0.55);
    }

    .nps {
      color: #83A755;
    }
  }
}

.notificationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 25px;

  & > a {
    position: relative;

    .notificationCount {
      width: 16px;
      height: 16px;
      font-size: 11px;
      line-height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background: #005a9e;
      position: absolute;
      border-radius: 50%;
      outline: 2px solid #fff;
      top: -7px;
      right: -7px;
    }
  }
}
