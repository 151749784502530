.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 20px 0 20px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 60px;
}

.wrapNote {
  width: 100%;
  background-color: white;
}

.groupDetails {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}