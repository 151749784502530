.wrap {
  width: 100%;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: end;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

    .btns {
      display: flex;
      gap: 10px;
    }
  }

  .editorWrapper {
    width: 100%;
    height: 100%;

    > div {
      width: 100%;
      height: 100%;
      border: 1px solid #e5e5e5;
    }
  }
}