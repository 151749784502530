@import "../../landingUi/landingMain.scss";

.articlePage {
  margin-top: 64px;
  padding-top: 100px;
  padding-bottom: 80px;
  @media (max-width: 1339px) {
    padding-top: 80px;
  }
}

.articleContainer {
  max-width: 680px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: 1339px) {
    max-width: 100%;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.authorBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 64px;
  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
}

.cutaway {
  display: flex;
  align-items: center;
}

.pic {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 16px;
}

.name {
  font-size: 14px;
  line-height: 150%;
  color: $textDark;
}

.date {
  font-size: 14px;
  line-height: 150%;
  color: $textLigtht;
  font-weight: 500;
}

.title {
  font-weight: bold;
  font-size: 36px;
  line-height: 110%;
  color: $title;
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 120%;
  }
}

.topSocialMobile {
  @media (max-width: 767px) {
    display: none;
  }
}

.imgWrap {
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
  @media (max-width: 1339px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
}

.titleImg {
  width: 100%;
  height: auto;
  max-width: 1062px;
  @media (max-width: 1339px) {
    max-width: 100%;
  }
}

.text {
  font-size: 18px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.7);
}

.downSocial {
  justify-content: flex-end;
  margin-bottom: 80px;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

.cardsTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  color: $title;
  margin-bottom: 24px;
}

.cardsBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    // margin-bottom: 0;
  }
}

.tabletView {
  @media (max-width: 1339px) {
    display: none;
  }
}

.mobileVew {
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

.viewButton {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    width: 100%;
  }
}
