.wrapper {
  margin-top: 40px;
  .table {
    box-shadow: none;
    padding-top: 0px !important;
    overflow-x: auto !important;
    * {
      white-space: nowrap;
    }
    thead:first-child {
      display: table-caption !important;
      span {
        display: none;
      }
    }
    thead tr th:last-child {
        div{
            display: flex;
            top: 8px;
        }
    }
    tbody tr  {
        &:hover td{
            background: #F3F3F3;
            path {
                display: block !important;
            }
        }
    }
  }
}
