.projectsWrap {
  padding: 12px;
  height: 214px;
  overflow-y: auto;
  .searchField {
    margin-bottom: 16px;
  }
  .projects {
    margin: 0;
    padding: 0;
    list-style-type: none;
    .project {
      &:not(:first-child) {
        margin-top: 11px;
      }
    }
  }
  .message {
    display: block;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.55);
  }
}

.clearBlock {
  padding: 10px 12px;

  & > button {
    padding: 0;
  }
}