.container {
  padding: 24px 20px 0;
  width: 100%;
  overflow-y: auto;
}
.title {
  font-weight: 500;
  margin-bottom: 20px;
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .wrap {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    .comment {
      margin-top: 20px;
    }
    .btn {
      margin-top: 15px;
      align-self: flex-end;
      width: 80px;
      &:disabled{
        background: #E5E5E5;
        color: rgba(0, 0, 0, 0.55);
      }
    }
  }
  .summary {
    width: 30%;
    height: fit-content;
    div {
      div:first-child {
        width: 100%;
      }
    }
  }
}
