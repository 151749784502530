.supplierGeneralTable {
  margin-top: 20px;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;

  & > h3 {
    margin-bottom: 0 !important;
    height: fit-content;
  }
}