.form {
  .field {
    &:not(:first-child) {
      margin-top: 16px;
    }
    &.budget {
      margin-top: -2px;
      &.withMargin {
        margin-top: 16px;
      }
    }
  }
  .description {
    height: 56px;
  }
}
