@import "../../../../landingUi/landingMain.scss";

.managementOverview {
  padding-top: 100px;
  padding-bottom: 80px;
  @media (max-width: 899px) {
    padding-top: 80px;
  }
}

.title {
  color: $title;
  font-weight: bold;
  font-size: 36px;
  line-height: 110%;
  margin-bottom: 64px;
  text-align: center;
  @media (max-width: 899px) {
    margin-bottom: 40px;
  }
}

.cardBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 22px;
  @media (max-width: 767px) {
    display: none;
  }
}

.mobileBlock {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}

.slider {
  margin-bottom: 24px;
  ul {
    bottom: 0;
  }
}

.buttonLink {
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 767px) {
    width: 100%;
  }
}
