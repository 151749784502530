@import "../../../../../UIKit/colorVars";

.container {
  color: $textDark;
  line-height: 150%;
  font-style: normal;
  min-height: 301px !important;

  .title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 6px;
  }
  .text {
    font-weight: normal;
    font-size: 15px;
    margin-bottom: 8px;
  }
  .boldText {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 40px;
  }
  .wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 48px;
    .link {
      color: $white !important;
    }
  }
}
