.form{
    .field{
        position: relative;
        &::before{
            content: '$';
            position: absolute;
            left: 11px;
            bottom: 6px;
            color: rgba(0, 0, 0, 0.55);
            font-size: 14px;
            line-height: 21px;
        }
        input{
            padding-left: 25px;
        }
        &:not(.last) {
            margin-bottom: 16px;
        }
        &.withError{
            &::before{
                bottom: 27px;
            }
        }
    }
    .message{
        display: block;
        margin-bottom: 4px;
        color: rgba(0, 0, 0, 0.55);
        font-size: 12px;
        line-height: 20px;
    }
}