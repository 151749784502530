@import "../../landingUi/landingMain.scss";

.form {
	@media (max-width: 1199px) {
		// max-width: 720px;
		margin-left: auto;
		margin-right: auto;
	}
}

.title {
	color: $title;
	font-weight: bold;
	font-size: 24px;
	line-height: 120%;
	margin-bottom: 16px;
	@media (max-width: 1199px) {
		text-align: center;
	}
}

.text {
	font-size: 18px;
	line-height: 150%;
	color: $textDark;
	margin-bottom: 64px;
	@media (max-width: 1199px) {
		text-align: center;
	}
	@media (max-width: 767px) {
		margin-bottom: 30px;
	}
}

.inputBlock {
	width: 100%;
}

.row {
	display: flex;
	justify-content: space-between;
	@media (max-width: 767px) {
		flex-direction: column;
		div {
			width: 100% !important;
		}
	}
}

.buttonLink {
	margin-left: auto;
	@media (max-width: 767px) {
		margin: 0;
		width: 100%;
	}
}

.formItem {
	background-color: $input !important;
	border: 1px solid #dadada !important;
	border-radius: 2px !important;

	&:hover {
		background-color: $inputHover !important;
	}
	&:focus {
		background-color: $inputFocus !important;
	}
}

.area {
	margin-bottom: 42px;
}

.input {
	height: 42px !important;
}

.widthClass {
	width: calc(50% - 12px) !important;
	margin-bottom: 17px !important;
}

.controlClasses {
	height: 100% !important;
	padding-top: 10px !important;
	& > div {
		color: rgba(0, 0, 0, 0.9) !important;
	}
}

.arrowClass {
	top: 22%;
}

.label {
	color: $textDark;
	font-weight: 500;
}

.dropDown {
	// height: 42px !important;
	width: calc(50% - 12px) !important;
	@media (max-width: 767px) {
		margin-bottom: 44px;
	}
	.headerClass {
		height: 42px;
		max-height: 42px;
	}
}

.dropClass {
	height: 42px;
}

.menuClasses {
	max-height: 200px;
	overflow: hidden;
	top: 100% !important;
	padding-top: 4px;
	padding-bottom: 4px;
	div {
		color: rgba(0, 0, 0, 0.9) !important;
		font-size: 14px;
		line-height: 150%;
		width: 100%;
		padding: 6px 10px !important;
		&:hover {
			background-color: #f5f8fa !important;
		}
	}
}
