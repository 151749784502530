.wrap {
  width: 100%;
  overflow-y: auto;
  height: 100%;
  padding: 10px 20px 0;

  .title {
    font-weight: 500;
    padding-bottom: 10px;
  }
}

.historyPopUp {
  min-height: unset;
}