@import "../../../UIKit/colorVars";

.userEmail {
  margin-top: 0;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.9);
  word-break: break-word;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 16px;
  margin-top: 0;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.hiddenField {
  visibility: hidden;
  height: 0px;
  width: 0px;
}
.field {
  margin-bottom: 16px;
}

.btn {
  margin: 40px 0 40px auto;
}
