.conditions {
  padding-top: 32px;
  margin-top: 32px;
  border-top: 1px solid #C6C6C6;

  .conditionsHeader {
    display: flex;
    justify-content: space-between;

    .conditionTitle {
      font-weight: 500;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.9);
      margin-bottom: 12px;
    }

    .addConditionBtn {
      background: none;
      border: none;
      cursor: pointer;

      > svg path {
        fill: #757575;
      }
    }
  }

  .conditionsContent {
    display: flex;
    flex-direction: column;

    & > div:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.error {
  color: #a4262c;
  font-size: 12px;
  margin-right: auto;
}