@import "../../../UIKit/colorVars";

.loginContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh;
  height: auto;
  padding: 25vh 0 40px 0;
  background: $layoutBg;
  @media (max-height: 750px) {
    padding: 10vh 0 30px 0;
  }
}
