@import "../../../UIKit/colorVars";

.navItem {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  min-height: 32px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: $white2;
  }

  &.active {
    background-color: $white2;
  }

  & > .name {
    font-weight: 400;
    font-size: 16px;
    color: $dark2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & > .nameCategoryLevel {
    font-weight: 400;
    font-size: 16px;
    color: $dark2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 6px;
  }

  .label1 {
    color: $link;
  }

  .label2 {
    color: #2b88d8;
  }

  .label3 {
    color: #7ca9cd;
  }

  .label4 {
    color: #99bad3;
  }

  & > .toggle {
    transition: all 0.25s ease;
    margin-left: -6px;
    transform: rotate(-90deg);
  }

  & > .toggleRotate {
    transform: rotate(0deg);
  }
}

.disableCategoryLevel {
   cursor: default;
   pointer-events: none;
   span{
    color: grey !important;
   }
}

svg{
    pointer-events: visible;
 }