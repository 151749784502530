@import "UIKit/_colorVars.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.title {
  font-weight: 500;
  color: $textDark;
  margin-bottom: 53px;
}
.dashedGridLines {
  line {
    stroke: #ecf0f4;
    stroke-width: 1;
    stroke-dasharray: 10 5;
    fill: none;
  }
}
