@import "../../../../landingUi/landingMain.scss";

.topCard {
  background-color: #fff;
  box-shadow: -2px 0px 1px rgba(0, 0, 0, 0.05), 1px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 1062px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1023px) {
    max-width: 100%;
  }
  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  color: $title;
  text-align: center;
  margin-bottom: 40px;
}

.linesBlock {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 40px;
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 62px;
  }
  @media (max-width: 767px) {
    padding-left: 0;
  }
}

.lines {
  @media (max-width: 1023px) {
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.buttonLink {
  margin-left: auto;
  margin-right: auto;
}
