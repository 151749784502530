@import "./colorVars";

#root, html, body {
  color: $textDark;
  font-size: 14px;
  line-height: 1.5;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4 {
  line-height: 1.5;
  color: $textDark;
  margin: 0;
}

p {
  margin: 0;
}

h1 {
  font-weight: bold;
  font-size: 28px;
}

h2 {
  font-size: 21px;
  font-weight: 600;
}

h3 {
  font-size: 18px;
  font-weight: normal;
}

h4 {
  font-size: 15px;
  font-weight: 600;
}
