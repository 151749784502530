.step {
  display: flex;
  flex-direction: column;

  .stepHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 30px;
    background: #E9E9E9;
    margin-bottom: 4px;

    .title {
      font-weight: 500;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .fields {
      display: flex;
      flex-direction: column;
    }

    .addFieldBtn {
      width: fit-content;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.55);
      font-size: 14px;
      background: none;
      border: none;
      transition: 0.2s all ease;
      padding: 5px;
      border-radius: 2px;
      margin: 4px 0 4px 20px;
      &:hover {
        transition: 0.2s all ease;
        background: #dfdfdf;
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
}

.information{
  margin-bottom: 23px;
}