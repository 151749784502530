.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  padding: 24px 20px 0 10px;
  margin-left: 10px;
}

.title {
  font-weight: 500;
  font-size: 21px;
  margin-bottom: 29px;
}

.wrapNote {
  width: 100%;
  background-color: white;
}