.singleCondition {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    margin-bottom: 12px;
  }

  & > * {
    &:nth-child(1), &:nth-child(3) {
      width: 38.44%;
    }

    &:nth-child(2) {
      width: 16.25%;
    }

    &:nth-child(4) {
      width: 2.57%;
    }
  }

  .dateField {
    width: auto;
    margin-bottom: 0;

    input {
      width: 100%;
    }
  }

  .deleteSingleConditionBtnWrap {
    display: flex;
    justify-content: center;
    align-items: center;

    .deleteSingleConditionBtn {
      cursor: pointer;
      border: none;
      background: none;
      padding: 0;
    }
  }

}