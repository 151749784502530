@import "../../landingUi/landingMain.scss";

.productPage {
  margin-top: 64px;
  padding-top: 100px;
  padding-bottom: 80px;
  @media (max-width: 1399px) {
    padding-top: 80px;
  }
}

.title {
  color: $title;
  font-weight: bold;
  font-size: 36px;
  line-height: 110%;
  text-align: center;
  margin-bottom: 64px;
  @media (max-width: 1399px) {
    margin-bottom: 48px;
  }
  @media (max-width: 767px) {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 130%;
  }
}

.content {
  display: flex;
  @media (max-width: 1399px) {
    width: 100%;
  }
}

.steps {
  padding-top: 55px;
  @media (max-width: 1399px) {
    display: none;
  }
}



.noLine {
  display: none;
}


