.calendarWrap{
    position: relative;
    background-color: white;
    border-radius: 6px;
}
.calendarWrapPosition{
    position: absolute;
    bottom: 100px;
    left: 300px;
}
.wrapHeaderCalendar{
   font-size: 18px;
   padding: 0;
   background-color: rgba(47, 120, 205, 1);
   width: 274px;
   height: 48px;
   display: flex;
   justify-content: center;
   align-items: center;
}
.calendarClassNameStyle {
   display: inline-block;
   height: 200px;
   font-size: 14px;
   border: 0;
}
.monthClassName{
    margin: 20px
}