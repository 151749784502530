@import "../../../../UIKit/colorVars";

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-top: 99px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 24px;
  font-family: Roboto;
  font-style: normal;
  text-align: center;

  .header {
    font-size: 40px;
    font-weight: 700;
    line-height: 150%;
  }
  .img {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .text {
    margin-bottom: 24px;
    font-size: 21px;
    line-height: 150%;
    .link {
      color: $button;
      cursor: pointer;
    }
  }
}
