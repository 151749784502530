.systemSettings{
  max-width: 400px;
  .dropdown {
    margin-top: 16px;
    * {
      text-transform: none !important;
    }
  }

  .btnWrap {
    margin-top: 80px;
    .btn {
      &:disabled {
        background: #f3f3f3;
      }
    }
  }
}
