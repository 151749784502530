.card {
  cursor: pointer;
  display: flex;
  .childrenWrapper {
    height: 1px;
    position: relative;
    .innerWrapper {
      position: absolute;
    }
  }
}
