@import "../../../UIKit/colorVars";

$itemHeight: 56px;
$tableHeads: 45px + 50px;

.wrap {
  &.scroll {
    &.withMaxHeight {
      max-height: calc(100vh - 235px);
    }
    &.withMaxHeightLarge {
      max-height: calc(96vh - 275px);
    }
    .tableContainer,
    .inlineWrap {
      transform: rotateX(180deg);
      display: flex;
      flex-direction: column;
    }
    .inlineWrap {
      padding-bottom: 4px;
      max-height: calc(100vh - 300px);
      overflow-y: scroll;
    }
    .dropListTrigger {
      right: 16px !important;
    }
  }
}

.table {
  position: relative;
  background-color: white;
}

th:last-child{
    background-color: white;
}
.tableHead {
  position: relative;
}

.icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 13px;
}

.tableContainer {
  padding: 4px 0;
  position: relative;
  background-color: white;
  overflow-x: initial !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.01);

  &.xScroll {
    white-space: nowrap;
    overflow-x: auto !important;
  }

  .totalCounter {
    background-color: white;
    border-bottom: none;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
    line-height: 1;

    span {
      margin-left: 8px;
      color: rgba(0, 0, 0, 0.9);
      font-size: 11px;
      line-height: 1;
      border-radius: 50%;
      background: $grey;
      min-width: 24px;
      padding: 6px 8px;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
    }
  }
.headCellTitel{
    display: flex;
    justify-content: flex-end;
}
  .title {
    color: rgba(0, 0, 0, 0.47);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    border-bottom: 1px solid #eaeaea;
    background-color: white;

    &.col1 {
      padding-right: 10%;
      width: 33.333333%;
    }

    &.col2 {
      padding-right: 10%;
      width: 33.333333%;
    }

    &.tagsComponent {
      padding: 0;
    }

    &.end {
      text-align: end;
      padding-right: 16px;
    }

    &.center {
      text-align: center;
    }
  }

  .subTableRow {
    > td:first-child {
      padding-left: 40px;
    }
  }

  .tableRow,
  .subTableRow {
    height: $itemHeight;
    background: white;
    z-index: 99999;
    position: static;
    border-bottom: 1px solid #eaeaea;

    .tableCell {
      word-break: break-all;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.75);
      background: white;
      height: 56px;

      .tableCellContent {
        display: flex;

        &.end {
          justify-content: end;
        }

        &.center {
          justify-content: center;
        }
      }

      &.col1 {
        padding-right: 10%;
        width: 33.333333%;
      }

      &.col2 {
        padding-right: 10%;
        width: 33.333333%;
      }

      &.tagsComponent {
        width: 210px;
        padding: 0;
      }

      &:first-child {
        color: rgba(0, 0, 0, 0.85);
      }

      &.link {
        color: $link;
        cursor: pointer;
      }
    }

    .dropListTrigger {
      position: absolute;
      right: 8px;
      margin-top: -12px;
      display: none;

      &.overflow {
        ul {
          top: auto;
          bottom: 100%;
        }
      }
    }

    &:last-child {
      .tableCell {
        border-bottom: none;
      }
    }

    &:hover {
      background-color: $white3;

      .dropListTrigger {
        display: block;
      }
    }
  }

  .hint {
    position: fixed;
    background: $textHeaders;
    border-radius: 2px;
    padding: 7px 12px;
    font-weight: 500;
    font-size: 14px;
    color: white;
    display: block;
    min-width: 250px;
    max-width: 50%;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 19px 20px 0;
}

.button {
  position: absolute !important;
  right: 0;
  top: 12px;
  margin-right: 17px;
}

.subTypes {
  display: flex;
  flex-direction: column;
  line-height: 1.7;
  text-align: end;
}

.titleFiltered {
  font-weight: normal;
}

.filteredSpend {
  color: rgba(0, 0, 0, 0.55);
  opacity: 0.87;
  font-Size: 12px;
  text-Align: end;
}

.hideContent {
  display: none;
}

.calendarStyle {
  display: flex;
  align-items: center;
}

.monthSavingMargin {
  width: 110px;
  text-align: center;
}

.bottomBtn {
  width: 100%;
  height: 100%;
  padding: 14px 27px;
  font-size: 14px;
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.55);
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  justify-content: start;

  &:hover {
    background: #f8f8f8;
  }
}