@import "../../../../../../../UIKit/colorVars";

.info {
  height: 62px;
  position: relative;
  width: 250px;
  background: $white;
  border: 1px solid $darkGrey;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  &.root {
    &::before {
      content: "";
      position: absolute;
      top: 30px;
      right: -21px;
      border-top: 1px solid #c8c8c8;
      width: 20px;
      height: 2px;
    }
  }

  &.last {
    margin-bottom: 0;
  }

  &.filtered {
    background: #d7d7d7;
  }

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover {
    border: 1px solid $button;
    box-shadow: 0 0 0 3px #d3e1f4;
  }

  .headerInfo {
    display: flex;
    justify-content: space-between;

    & > div {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      justify-content: center;
    }
  }

  .usersCount {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.55);
    margin-left: 5px;
    min-width: fit-content;
    text-overflow: unset;
  }

  .name {
    font-weight: 500;
    color: $link;
    margin-bottom: 4px;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .leaderName {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .role {
    color: $textLight;
  }

  .extendedInfo {
    margin-top: 12px;
  }

  .wrap {
    display: flex;
    align-items: center;
    height: 21px;
    position: relative;

    p {
      width: calc(100% - 24px);
      color: $textDark;
      text-align: start;
    }

    .icon {
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      margin-right: 8px;

      path {
        fill: $textDark;
      }
    }
  }
}
