@import "../../../UIKit/colorVars";

.userFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 440px;
  min-height: 325px;
  background: $white;
  box-shadow: $tableShadow;
  padding: 44px 44px 0 44px;
}

.logo {
  margin-bottom: 16px;
}
