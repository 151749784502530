.tabs {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .tab {
    width: 50%;
    height: 34px;
    display: flex;
    justify-content: center;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    cursor: pointer;
    &.active {
      border-bottom: 2px solid #2f78cd;
      font-weight: 500;
    }
  }
}

.field {
  margin-bottom: -2px;
  &.withError {
    margin-bottom: 16px;
  }
}
.fieldPas {
  margin-bottom: 20px;
  &.withError {
    margin-bottom: 16px;
  }
}

.dropdown {
  margin-bottom: 16px;
}
.disabled{
   display: none;
}

.checkbox{
    margin-top: 24px;
}