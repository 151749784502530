.details {
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  gap: 150px;
  display: flex;
  margin-bottom: 20px;

  .main {
    width: 100%;
  }

  .infoGroup {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .attachments {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .hyperlinks {
    min-width: 300px;


    .hyperlinksWrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      overflow-y: scroll;
      max-height: 150px;

      &::-webkit-scrollbar {
        display: none;
      }

      > a {
        width: 47%;
        margin-bottom: 10px;
      }
    }
  }

  .edit {
    min-width: 20px;

    > svg {
      cursor: pointer;
    }
  }
}

.link {
  font-weight: 400;
  font-size: 14px;
  color: #005A9E;
  text-decoration: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 5px;
}