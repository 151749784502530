.emptyWidget {
  text-align: center;
  margin-top: 8px;
  margin-bottom: 31px;
  width: 100%;
  .icon {
    display: block;
    margin: 0 auto;
  }
  .title {
    color: rgba(0, 0, 0, 0.9);
    margin: 16px 0 8px 0;
    font-size: 15px;
    font-weight: 500;
    display: block;
  }
  .description {
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
  }
}
