@import "../../../../../UIKit/colorVars";

.emailSentWrapper {
  min-height: 232px;
}

.emailSent {
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  word-wrap: break-word;
  span {
    color: $link;
  }
  margin-bottom: 24px;
  white-space: normal;
}

.followSent {
  font-weight: normal;
  font-size: 15px;
}
