.openedList {
  max-height: 271px;
  background: #FFFFFF;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  top: calc(100% + 2px);
  position: absolute;
  width: fit-content;
  z-index: 11;
}

.expiresInWrap {
  height: auto;
  overflow-y: auto;
  padding: 4px 0;
  list-style-type: none;
  width: fit-content;

  .item {
    height: 40px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    width: 100%;

    &:hover {
      background: rgba(#f3f3f3, 0.5);
    }

    &.active {
      background: #f3f3f3;
    }
  }
}

.dropDownWrapper {
  position: relative;
  height: fit-content;
  cursor: pointer;

  .activePanel {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    justify-content: space-between;

    &:hover {
      background: #E5E5E5;
    }

    &.active {
      background: #E5E5E5;
    }

    & > .arrow {
      display: flex;

      & > svg {
        transition: 0.2s all ease;
      }

      &.active svg {
        transition: 0.2s all ease;
        transform: rotate(180deg);
      }
    }
  }
}
