.categoriesInfo {
  width: 100%;
  flex: 1;
  padding: 10px 20px 0;
  overflow-y: auto;
  .title {
    font-weight: 500;
    font-size: 21px;
    margin-bottom: 13px;
  }
}
