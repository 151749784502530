.emailFieldsWrap {
  position: relative;
  width: 150px;

  .dropDownWrap {
    display: flex;
    gap: 5px;
    align-items: end;
  }

  .emailFieldsDropDown {
    background: #F3F3F3;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px;

    &.opened {
      > svg {
        transition: 0.2s all ease;
        transform: rotate(-180deg);
      }
    }

    &:hover {
      background: #EAEAEA;
    }
  }

  .fields {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    padding: 2px 0;
    z-index: 9;
    overflow-y: scroll;
    max-height: 200px;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      padding: 5px 12px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      width: 100%;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        background: #F3F3F3;
      }
    }
  }
}