.wrapper {
  display: flex;
  width: 100%;
  min-height: 250px;
}

.tree {
  width: 100%;
  margin-right: 20px;
  margin-bottom: 95px;

  ul {
    position: relative;
    display: flex;
    margin-bottom: 95px;
  }

  ul ul {
    flex-direction: column;
    padding-left: 40px;
  }

  ul:last-child {
    margin-right: 20px;
  }

  ul ul::before {
    content: "";
    position: absolute;
    top: 31px;
    left: 20px;
    border-left: 1px solid #c8c8c8;
    width: 0;
    height: calc(100% - 62px);
  }

  li {
    white-space: nowrap;
    text-align: center;
    list-style-type: none;
    position: relative;
  }

  li::before {
    content: "";
    position: absolute;
    top: 31px;
    left: -20px;
    border-top: 1px solid #c8c8c8;
    width: 20px;
    height: 20px;
  }

  li:last-child::before {
    border-top: none;
  }

  li:last-child::after {
    content: "";
    position: absolute;
    bottom: 30px;
    left: -20px;
    border-bottom: 1px solid #c8c8c8;
    width: 20px;
    height: 20px;
  }
}

.emptyOrgChart {
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
  0px -1px 10px rgba(0, 0, 0, 0.02),
  0px 3px 5px rgba(0, 0, 0, 0.11),
  0px 0.5px 1px rgba(0, 0, 0, 0.1);
  padding: 14px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  div {
    max-width: 408px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.55);
  }
}