.dropdown {
  padding-left: 0;
  margin-top: 16px;
  &:first-child {
    margin-top: 0;
  }
  &::before {
    display: none;
  }
}
