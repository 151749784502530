.wrap {
  width: 100%;
  overflow-y: auto;
  padding: 24px 20px 95px 20px;

  .title {
    font-weight: 500;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 20px;
  }

  .historyWrapper {
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px -0.5px 4px rgba(0, 0, 0, 0.05),
    0px -1px 10px rgba(0, 0, 0, 0.02),
    0px 3px 5px rgba(0, 0, 0, 0.11),
    0px 0.5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .subTitle {
      font-weight: 500;
      font-size: 21px;
      color: rgba(0, 0, 0, 0.9);
      margin-bottom: 20px;
    }
  }
}